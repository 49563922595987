import React, { useEffect, useState } from 'react'
import '../../../student/Student.css'
import SideBar from '../../component/sidebar/SideBar'
import { apilink, path, removeSpecialCharacters } from '../../../../data/fdata'
import '../../Student.css'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'
import { CircularProgress } from "@mui/material";
import { useAlert } from 'react-alert';
import { saveAs } from 'file-saver';

const Dashboard = () => {
  const [loading,setLoading] =useState(false)


  const [popupfees, setPopupfees] = useState(false);
  const [popupotherfees, setPopupotherfees] = useState(false);

  
  const alert= useAlert()

  const [userData, setUserData] = useState({});
  const [feesObj,setFeesObj]=useState({})
  const [pageloading, setPageloading] = useState(false);

  const [mydata, setMydata] = useState({});
  const [paymentDet, setPaymentDet] = useState([]);
  const [myPayment, setMyPayment] = useState([]);
  const [recBooks,setRecBooks]=useState([]);
  


  const [feestype, setFeestype] = useState("");
  const [feesamount, setFeesamount] = useState("");
  const [clgname, setClgname] = useState("");
  const [pay_type, setPay_type] = useState("");
  // const [pay_type_desc, setPay_type_desc] = useState("");






  const token = Cookies.get('_edushark_user_access_token');
  const his = useHistory();
  const [showCount,setShowCount]=useState(0);

  useEffect(()=>{
    let val=localStorage.getItem('showCount') ;
    if(val==null)
    {
      localStorage.setItem('showCount',1)
      setShowCount(1)
    }else if(val==1)
    {
      setShowCount(2)
      localStorage.setItem('showCount',2)
    }
    else{

      setShowCount(3)
      localStorage.setItem('showCount',3)
       

    }
  

  },[])

  useEffect(async() => {
    if(token) {
      setPageloading(true)
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_user_access_token');
           localStorage.removeItem('_edushark_user_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
          
              setUserData(res.data.userInfo)
              
            
  
          }
          setTimeout(() => {
            setPageloading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);

  const getFeestructs=async(cou)=>{
    const res=await axios.get(`${apilink}/api/feestruct/getFeestructs`)
    // console.log(res.data)
    if(res.data.success){
      let ar=  res.data.result.filter((v)=>v.feesTypeAction =="Registration" && v.feesCourse==cou      )
setFeesObj(...ar)
    }else{
        alert.error(res.data.msg)
    }
  }

  const getMyApplication=async()=>{
    const res=await axios.get(`${apilink}/api/application/getMyApplication`,{
      headers: {
        Authorization: token,
      },
    })
    // console.log(res.data.result)
    if(res.data.success){
      setMydata(res.data.result)
      getRandomBooks(res.data.result?.course_name)
      getFeestructs(res.data.result?.course_name)
      setPaymentDet(res.data.result?.feeStructure?.collegefees)
      let ar=[]
      
       
      if(res.data.result?.course_name=="DPHARM")
      {
        setClgname(res.data.result.dpharm_form.choose_college  )
      // ar= res.data.result.courseID.cou_colleges.filter((v)=>v.collageName ==  res.data.result.dpharm_form?.choose_college     ) 
      // setPaymentDet(ar[0].collegefees)
      }
      if(res.data.result?.course_name=="BED")
      {
        setClgname(res.data.result.bed_form.choose_college  )
        // ar=res.data.result.courseID.cou_colleges.filter((v)=>v.collageName ==  res.data.result.bed_form?.choose_college     )
        // setPaymentDet(ar[0].collegefees)

      }
      if(res.data.result?.course_name=="DELED")
      {
        setClgname(res.data.result.dled_form.choose_college  )
        // ar=res.data.result.courseID.cou_colleges.filter((v)=>v.collageName ==  res.data.result.dled_form?.choose_college     )
        // setPaymentDet(ar[0].collegefees)
      }
      
     

    }else{
      alert.error(res.data.msg)
    }
  }

const onPayFees=async(e)=>{
  e.preventDefault()
  setLoading(true)
const data={
  applicationID:mydata._id,
  courseID:mydata.courseID,
  collegeID:mydata.collegeID,
  pay_type,
  pay_type_desc:"",
  feestype,
  feesamount,
  MUID: "MUID" + Date.now(),
  transactionId: 'T' + Date.now()
  
}

const res=await axios.post(`${apilink}/api/paymentfees/addPaymentFees`,data,{
  headers: {
    Authorization: token,
  },
})

if(res.data.success)
{
  // console.log(res.data.url)
  setTimeout(() => {
    setLoading(false)
    
  }, 2000);

  setTimeout(() => {
    window.location.href=res.data.url
   }, 3000);

}else{
  
  
  setTimeout(() => {
    setPopupfees(false)
    setPopupotherfees(false)
    setFeesamount("")
    setFeestype("")
    setLoading(false)
    alert.error(res.data.msg)
  }, 2000);
  

}

}


const getMyPaymentFees=async()=>{
  const res=await axios.get(`${apilink}/api/paymentfees/getMyPaymentFees`,{
    headers: {
      Authorization: token,
    },
  })
  

  if(res.data.success)
  {
    setMyPayment(res.data.result)
  }else{
    alert.error(res.data.msg)
  }
}
const getRandomBooks=async(book)=>{
  if(book)
  {
    const res=await axios.get(`${apilink}/api/purchesbook/getRandomBooks/${book}`)
    // console.log(res.data)
    if(res.data.success) 
    {
      setRecBooks(res.data.result)
  
    }else{
      alert.error(res.data.msg)
    }
  }
 
}

useEffect(()=>{
  getMyApplication()
  getMyPaymentFees()
  
},[])


const generatepdf=async(pid,table)=>{
  setPageloading(true)

  const res=await axios.post(`${apilink}/api/pdf/generate-pdf`,{
    pid,
    table
  })
  // console.log(res.data)
  if(res.data.success)
  {
   alert.success(res.data.msg)
   const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${pid}`,{ responseType: 'blob' })
       if(ress)
       {
           const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });

             saveAs(pdfBlob, `invoice_pdf_${pid}.pdf`);
             const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${pid}`)
             if(!re.data.success)
             {
               his.push("something-wrong")
             }
       }

  }else{
   alert.error(res.data.msg)
  }
  setPageloading(false)
}

const applicationpdf=async(aid)=>{
  setPageloading(true)
  const res=await axios.post(`${apilink}/api/pdf/application-pdf`,{
    aid
  })
 
  if(res.data.success)
  {
   alert.success(res.data.msg)
   const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${aid}`,{ responseType: 'blob' })
       if(ress)
       {
           const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });

             saveAs(pdfBlob, `application_pdf_${aid}.pdf`);
             const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${aid}`)
             if(!re.data.success)
             {
               his.push("something-wrong")
             }
       }

  }else{
   alert.error(res.data.msg)
  }
  setPageloading(false)
}


const completeFeesPayment=async(feeid , feesamount)=>{
  setPageloading(true)
  const da={
    MUID: "MUID" + Date.now(),
  transactionId: 'T' + Date.now(),
  feeid,
  feesamount
  }
  const res=await axios.post(`${apilink}/api/paymentfees/completeFeesPayment`,da,{
    headers: {
      Authorization: token,
    },
  })

  if(res.data.success)
{
  //  console.log(res.data.url)
 setTimeout(() => {
  setPageloading(false)
 }, 1500);

  setTimeout(() => {
    window.location.href=res.data.url
   }, 3000);

}else{
  setTimeout(() => {
    setPageloading(false)
   }, 1500);
  
  
  alert.error(res.data.msg)

}

}

const completeApplicationPayment=async (feeid , feesamount) => {

  

  setPageloading(true)
  const da={
    MUID: "MUID" + Date.now(),
  transactionId: 'T' + Date.now(),
  feeid,
  feesamount
  }
  const res=await axios.post(`${apilink}/api/application/completeApplicationPayment`,da,{
    headers: {
      Authorization: token,
    },
  })

  if(res.data.success)
{
  //  console.log(res.data.url)
 setTimeout(() => {
  setPageloading(false)
 }, 1500);

  setTimeout(() => {
    window.location.href=res.data.url
   }, 3000);

}else{
  setTimeout(() => {
    setPageloading(false)
   }, 1500);
  
  
  alert.error(res.data.msg)

}

}

  return (
    <>

{
    popupfees &&  <div className="model_box">
    <div className="inner_model_big">
      <div className="cross bg-danger" onClick={()=>{
        setPopupfees(false)
        setFeesamount("")
        setFeestype("")
        setPay_type("")

      }}>&times;</div>
      <div>
       <div className="text-center">
        <h5>Fees Payment</h5>
       
       </div>
       <form className="my-4" onSubmit={onPayFees}>
       <div className="form-group ">
                   <label htmlFor="">Fees Type</label>

                   <select
                      class="form-control form_h"
                     name="feestype"
                     value={feestype}
                     onChange={(e)=>{
                      setFeestype(e.target.value)
                      let ar=paymentDet.filter((v)=>v.semtitle == e.target.value)
                      setFeesamount(ar[0].semfees)
                     }}
                      required
                    >
                      <option selected hidden value="">
                        --Choose Fees Type--
                      </option>
                      
                      {
                        paymentDet?.map((va,ind)=>{
                          return (
                            <>
                            <option value={va.semtitle}>{va.semtitle}</option>
                            </>
                          )
                        })
                      }
                      


                   
                    </select>
                   
                 </div>
                 <div className="form-group ">
                       <label for="address">Fees Amount:</label>
                       <input
                         type="text"
                         placeholder="Enter Fees Amount"
                         className="form-control form_h"
                         name="feesamount"
                         value={feesamount}
                         onChange={(e) => setFeesamount(e.target.value)}
                         required
                         readOnly
                       />
                     </div>
     
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Pay Now
                      </button>
                      {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                    </div>
        </form>
      </div>
    </div>
  </div>
   }

{
    popupotherfees &&  <div className="model_box">
    <div className="inner_model_big">
      <div className="cross bg-danger" onClick={()=>{
        setPopupotherfees(false)
        setFeesamount("")
        setFeestype("")
        setPay_type("")

      }}>&times;</div>
      <div>
       <div className="text-center">
        <h5>{pay_type.charAt(0).toUpperCase() + pay_type.slice(1).toLowerCase()} Fees Payment</h5>
       
       </div>
       <form className="my-4" onSubmit={onPayFees}>
          {
            pay_type =="EXAM" &&  <div className="form-group ">
            <label htmlFor="">Fees Type</label>

            <select
               class="form-control form_h"
              name="feestype"
              value={feestype}
              onChange={(e)=>{
               setFeestype(e.target.value)                      
               setFeesamount("1200")
              }}
               required
             >
               <option selected hidden value="">
                 --Choose Fees Type--
               </option>
               
               <option value="1st semester exam fee">1st semester exam fee</option>
               <option value="2nd semester exam fee"> 2nd Semester Exam Fee</option>
               <option value="3rd semester exam fee">3rd Semester Exam Fee</option>
               <option value="4th semester exam fee">4th Semester Exam Fee</option>
               


            
             </select>
            
          </div>
          }


          {
            pay_type =="DRESS" &&  <div className="form-group ">
            <label htmlFor="">Fees Type</label>
            <input
                         type="text"
                         placeholder="Enter Fees Type"
                         className="form-control form_h"
                         name="feestype"
                         value={feestype}
                         
                         required
                         readOnly
                       />
       
            
          </div>
          }

      {
           ( pay_type =="TOUR" || pay_type == "LIBRARY" || pay_type == "REGISTRATION") && 
           (
            <>
             <div className="form-group ">
            <label htmlFor="">Fees Type</label>
            <input
                         type="text"
                         placeholder="Enter Fees Type"
                         className="form-control form_h"
                         name="feestype"
                         value={feestype}
                         
                         required
                         readOnly
                       />
       
            
          </div>

     

            </>
           )
          }

          {
            ( pay_type =="TOUR" || pay_type == "LIBRARY" ) ?(
              <>
              <div className="form-group ">
                       <label for="address">Fees Amount:</label>
                       <input
                         type="text"
                         placeholder="Enter Fees Amount"
                         className="form-control form_h"
                         name="feesamount"
                         value={feesamount}
                         onChange={(e) => setFeesamount(e.target.value)}
                         required
                        
                       />
                     </div> 
              
              </>
            ):(
              <>

<div className="form-group ">
                       <label for="address">Fees Amount:</label>
                       <input
                         type="text"
                         placeholder="Enter Fees Amount"
                         className="form-control form_h"
                         name="feesamount"
                         value={feesamount}
                         onChange={(e) => setFeesamount(e.target.value)}
                         required
                         readOnly
                        
                       />
                     </div> 

              </>
            )
          }

           

                 
     
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Pay Now
                      </button>
                      {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                    </div>
        </form>
      </div>
    </div>
  </div>
   }
    


    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="welcome_dash">
                <div className="p-3  left_stu_dash">
                <h3>Hi , {userData?.name?.split(" ")[0]}</h3>
                <h5>Welcome to Edushark</h5>
                {userData?.isApproved && <h5><u>{clgname}</u></h5> }
                


                
                </div>
                <div className="right_stu_dash">
                  <img src={`${path}/image/student_study.svg`} alt="" />
                </div>
              </div>
          {
            userData?.isApproved ? (
              <>

                <div className="container-fluid mt-4">
             <div className="">
            {
              showCount < 3 &&  <div class="alert alert-success alert-dismissible my-3">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              Ready to dive into your course? Just click <b>"Pay Fees"</b> and unlock the learning journey ahead!
            </div>
            }
             <h4 className='mb-3'>Tution Fees</h4>
             
             {
              mydata?.ApplicationStatus == "APPROVED" ?  (
                <>
                <div className='dd_flex'>
                <button className='sml-btn px-3 py-2 ml-1' onClick={()=>{
        setPopupfees(true)
        setPay_type("TUTION")

      }}>Pay Fees</button>
                <button onClick={()=>applicationpdf(mydata._id)} className='sml-btn px-3 py-2 mr-1'> <span className='d-lg-block d-md-block d-none'> <i className='fa fa-download'></i> Download Application</span> <span className=' d-md-none d-bock'><i className='fa fa-download'></i> Application</span> </button>
      
                </div>
                
                </>
              ):(
                <>
                <button className='sml-btn px-3 py-2' onClick={()=>{
        alert.error("Your Application Is Not Approved yet")

      }}>Pay Fees</button>
                </>
              )
             }
             
             </div>
             
              <div className="row mt-4">

        {
          myPayment.filter((v)=>v.pay_type == "TUTION").length > 0 ?(
            <>
            {
                myPayment.filter((v)=>v.pay_type == "TUTION")?.map((val,ind)=>{
                  return(
                    <>
                    
                    <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12 mb-3">
                  <div className="card p-2 pb-3">
                    <h5>{val.feestype}</h5>
                    {
                      val.payment_status ? (
                        <>
                        <div className="text-left mt-2">
                    {val.paymentfee_status=="PENDING" &&  <span className='badge badge-warning py-1 px-2'>Pending</span>}
                  {val.paymentfee_status=="APPROVED" &&  <span className='badge badge-success py-1 px-2'>Approved</span>}
                  {val.paymentfee_status=="REJECTED" &&  <span className='badge badge-danger py-1 px-2'>Rejected</span>}
                    &nbsp;  &nbsp;
                    <span onClick={()=>generatepdf(val._id,val.pay_type)} className='badge badge-light py-1 px-2 cur'> <i className='fa fa-download mr-1'></i> Receipt</span> 
                    </div>
                        
                        </>
                      ):(
                        <>
                        <div className="text-left mt-2 text-danger">
                    Payment Failed
                    &nbsp;  &nbsp;
                    <span onClick={()=>completeFeesPayment(val._id,val.feesamount)} className='badge badge-light py-1 px-2 cur'> Pay Now</span> 
                    </div>
                        
                        </>
                      )
                    }
                    
                  </div>
                </div>
                    
                    </>
                  )
                })
              }
            </>
          ):(
            <>
            <div className="py-5 ">
              <p className='ml-5'>You have not pay any tution fees.</p>
            </div>
            
            </>
          )
        }
                
              
               



              </div>
             
             
            </div>
            <div className="container-fluid mt-4">
              <h4>Other Fees</h4>
             
              <div className="row mt-4 mb-4">
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12 mb-3">
                  <div className="card p-2 pb-3">
                    <h5>Registration Fees</h5>
                    <div className="text-left mt-2">
                    {
                        mydata?.ApplicationStatus == "APPROVED" ? (
                          <>
                          <button className='sml-btn px-3 py-2' onClick={()=>{
        setPopupotherfees(true)
        setPay_type("REGISTRATION")
        setFeestype("Registration Fee Payment")                      
        setFeesamount(feesObj.feesAmount)

      }} >Pay Now</button>
                          </>
                        ):(
                          <button className='sml-btn px-3 py-2' onClick={()=>{
                            alert.error("Your Application Is Not Approved yet")
                    
                          }}>Pay Now</button>
                        )
                      }
                    
                    </div>
                  </div>
                </div>

              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12 mb-3">
                  <div className="card p-2 pb-3">
                    <h5>Exam Fees</h5>
                    <div className="text-left mt-2">
                      {
                        mydata?.ApplicationStatus == "APPROVED" ? (
                          <>
                          <button className='sml-btn px-3 py-2' onClick={()=>{
        setPopupotherfees(true)
        setPay_type("EXAM")

      }} >Pay Now</button>
                          </>
                        ):(
<button className='sml-btn px-3 py-2' onClick={()=>{
        alert.error("Your Application Is Not Approved yet")

      }}>Pay Now</button>
                        )
                      }
                    
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12 mb-3">
                  <div className="card p-2 pb-3">
                    <h5>Dress Fees</h5>
                    <div className="text-left mt-2">
                    {
                        mydata?.ApplicationStatus == "APPROVED" ? (
                          <>
                           <button className='sml-btn px-3 py-2' onClick={()=>{
        setPopupotherfees(true)
        setPay_type("DRESS")
        setFeestype("Dress Payment")                      
                          setFeesamount("3500")

      }} >Pay Now</button>
                          
                          </>
                        ):(
                          <button className='sml-btn px-3 py-2' onClick={()=>{
                            alert.error("Your Application Is Not Approved yet")
                    
                          }}>Pay Now</button>
                        )
                      }
                   
                    </div>
                  </div>
                </div>
               
                
                
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12 mb-3">
                  <div className="card p-2 pb-3">
                    <h5>Tour Fees</h5>
                    <div className="text-left mt-2">
                    {
                        mydata?.ApplicationStatus == "APPROVED" ? (
                          <>
                          <button className='sml-btn px-3 py-2' onClick={()=>{
        setPopupotherfees(true)
        setPay_type("TOUR")
        setFeestype("Tour Payment")                      
                        

      }} >Pay Now</button>
                          </>
                        ):(
                          <button className='sml-btn px-3 py-2' onClick={()=>{
                            alert.error("Your Application Is Not Approved yet")
                    
                          }}>Pay Now</button>
                        )
                      }
                    
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12 mb-3">
                  <div className="card p-2 pb-3">
                    <h5>Library Fees</h5>
                    <div className="text-left mt-2">
                    {
                        mydata?.ApplicationStatus == "APPROVED" ? (
                          <>
                          <button className='sml-btn px-3 py-2' onClick={()=>{
        setPopupotherfees(true)
        setPay_type("LIBRARY")
        setFeestype("Library Fee Payment")                      
                        

      }} >Pay Now</button>
                          </>
                        ):(
                          <button className='sml-btn px-3 py-2' onClick={()=>{
                            alert.error("Your Application Is Not Approved yet")
                    
                          }}>Pay Now</button>
                        )
                      }
                    
                    </div>
                  </div>
                </div>


                

              </div>
             <h4>Recommended Books</h4>
             {recBooks?.length > 0 ? (
            <>
              <div className="row my-3">
                {recBooks?.map((val, ind) => {
                  return (
                    <>
                      <div className="col-lg-4 col-md-6 col-12 mb-3" key={ind}>
                        <div className="card book_card p-2">
                         
                          {val.bookimage?.length && (
                            <img
                              src={val.bookimage[0]}
                              alt=""
                              className="book_img cur"
                              onClick={()=>his.push(`/purchase-books/${val._id}/${removeSpecialCharacters(val.bookname)?.trim().toLowerCase().replace(/\s/g, "-")}`)}
                            />
                          )}
                          <div className="p-2">
                            <p className="m-0">
                              <b>
                                {val.bookcategory} / {val.booksem}
                              </b>
                            </p>
                            
                            <h5 className="m-0 text-custom word_r">
                              {val.bookname}
                            </h5>
                            <p className="m-0 mt-2">
                              <span className="">Language:</span> {val.booklang}{" "}
                            </p>
                            <p className="m-0 mt-2">
                              <span className="">Publisher:</span> {val.bookpublisher}{" "}
                            </p>
                            <div className="dd_flex mt-2 ">
                              <p className="m-0">
                                <span className="">Price:</span> {val.bookprice}{" "}
                              </p>
                              <div className="">
                                <button
                                  className="sml_btn py-2 px-4 mr-1"
                                  data-toggle="modal"
                                  data-target={`#myModal${val._id}`}
                                >
                                  view
                                </button>

                                <div
                                  class="modal fade"
                                  id={`myModal${val._id}`}
                                >
                                  <div class="modal-dialog modal-lg modal-dialog-centered">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h4 class="modal-title">
                                          Book Details
                                        </h4>
                                        <button
                                          type="button"
                                          class="close"
                                          data-dismiss="modal"
                                        >
                                          &times;
                                        </button>
                                      </div>

                                      <div class="modal-body">
                                        <h5>
                                          <span className="text-custom">
                                            Name: &nbsp;
                                          </span>
                                          {val.bookname}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Author: &nbsp;
                                          </span>
                                          {val.bookauthor}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Category: &nbsp;
                                          </span>
                                          {val.bookcategory}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Semester or Year: &nbsp;
                                          </span>
                                          {val.booksem}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Publisher: &nbsp;
                                          </span>
                                          {val.bookpublisher}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Price: &nbsp;
                                          </span>
                                          {val.bookprice}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Number of Books: &nbsp;
                                          </span>
                                          {val.booknumber}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Number of Pages: &nbsp;
                                          </span>
                                          {val.bookpages}{" "}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <button
                                  className="sml-btn py-2 px-4 ml-1"
                                  onClick={() =>
                                    his.push(`/purchase-books/${val._id}/buynow`)
                                  }
                                >
                                  Buy Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="py-5 text-center">
                <h2>Coming Soon...</h2>
              </div>
            </>
          )}
             
            </div>
              </>
            ) : (
              <>
             {
              mydata?.ApplicationStatus ? (
                <>
                {
                  mydata?.paymentStatus ?(
                    <>

<div class="alert alert-warning alert-dismissible my-3">
  <button type="button" class="close" data-dismiss="alert">&times;</button>
Your {mydata?.course_name} Application is not Approved Yet . Need help ?<span className='cur' onClick={()=>his.push("/contact")}> <b>Contact with us</b> </span>
</div>

                    </>
                  ):(
                    <>
                    <div class="alert alert-danger mt-3">
                    You have not paid for {mydata?.course_name} application.
</div>
  
                 <div className="text-center">
                 <button className="sml_btn bg-warning" onClick={()=>completeApplicationPayment(mydata._id,mydata.paymentAmount)}>Pay Now</button>
                 </div>
                    </>
                  )
                }
               

 <div className="my-3 text-center">
                <div className="card p-3 rounded">
                <NavLink to="/courses"><h3>Find Our Degree Courses</h3></NavLink>
                </div>
              </div>

                </>
              ):(
                <>

<div className="my-3 text-center">
                <div className="card p-3 rounded">
                <NavLink to="/courses"><h3>Find Our Degree Courses</h3></NavLink>
                </div>
              </div>
                </>
              )
             }

              </>
            )
          }
          </div>
        </div>
      </div>

      {
  pageloading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
    </>
  )
}

export default Dashboard