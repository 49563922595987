import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import { path } from '../../data/fdata';
const HeroCompo = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <>
     <div class="header">
      <div class="inner-header"  >
      <div class="bubble d_big">
         
         <span style={{ "--i": 11 }}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":20}}></span>
         <span style={{"--i":15}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":12}}></span>
       
         <span style={{"--i":21}}></span>
         <span style={{"--i":11}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":20}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":9}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":11}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":20}}></span>
         <span style={{"--i":15}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":12}}></span>
         <span style={{"--i":21}}></span>
         <span style={{"--i":11}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":20}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":9}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":9}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":22}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":22}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":21}}></span>
         <span style={{"--i":11}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":20}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":9}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":22}}></span>
         <span style={{"--i":10}}></span>
         
       </div>
        <div class="bubble dd_big d-lg-flex d-none">
         
          <span style={{ "--i": 11 }}></span>
          <span style={{"--i":25}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":25}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":20}}></span>
          <span style={{"--i":15}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":12}}></span>
        
          <span style={{"--i":21}}></span>
          <span style={{"--i":11}}></span>
          <span style={{"--i":25}}></span>
          <span style={{"--i":20}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":9}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":22}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":25}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":11}}></span>
          <span style={{"--i":25}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":25}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":20}}></span>
          <span style={{"--i":15}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":12}}></span>
          <span style={{"--i":21}}></span>
          <span style={{"--i":11}}></span>
          <span style={{"--i":25}}></span>
          <span style={{"--i":20}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":9}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":22}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":25}}></span>
          <span style={{"--i":10}}></span>
          
        </div>

        <div class="bubble d-lg-none d-md-flex d-none">
         
         <span style={{"--i":11}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":20}}></span>
         <span style={{"--i":15}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":12}}></span>       
         <span style={{"--i":21}}></span>
         <span style={{"--i":11}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":20}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":9}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":22}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":15}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":12}}></span>       
         <span style={{"--i":21}}></span>
         <span style={{"--i":11}}></span>
         
       </div>
       <div class="bubble d-lg-none d-md-none d-sm-flex d-none">
         
         <span style={{"--i":11}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":20}}></span>
         <span style={{"--i":15}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":12}}></span>       
         <span style={{"--i":21}}></span>
         <span style={{"--i":11}}></span>
         <span style={{"--i":25}}></span>
          <span style={{"--i":20}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":9}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":22}}></span>
          <span style={{"--i":10}}></span>
          <span style={{"--i":14}}></span>
          <span style={{"--i":25}}></span>
          <span style={{"--i":10}}></span>
         
       </div>
       <div class="bubble d-lg-none d-md-none d-sm-none d-flex">
         
         <span style={{"--i":11}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":25}}></span>
         <span style={{"--i":10}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":20}}></span>
         <span style={{"--i":15}}></span>
         <span style={{"--i":14}}></span>
         <span style={{"--i":12}}></span>       
         <span style={{"--i":21}}></span>
         <span style={{"--i":11}}></span>
         <span style={{"--i":25}}></span>
          
         
       </div>

        <div class="container">
            <div class="row">
                <div class="col-md-6 col-12 mb-3 dflex ">
                        <h1 class="mt-3" data-aos="fade-up"
                        data-aos-duration="2000"> Learn today to lead tomorrow</h1>
                        <p data-aos="fade-up"
                        data-aos-duration="2500">               
                        
                        Your go-to platform for learning and skill development. At Edushark, we're dedicated to helping you achieve your educational and career goals.


                        </p>
                        <div data-aos="fade-up"
                        data-aos-duration="3000">
                            <NavLink to="/login" class="btn btn-outline-light m-1 ">GET STARTED</NavLink>
                        <NavLink to="/courses" class="btn btn-light m-1 text-dark" >OUR COURSES</NavLink>
                        </div>
                </div>
                <div class="col-md-6 col-12 hero_img_box" data-aos="fade-up"
                data-aos-duration="2000">
                    <img src={`${path}/image/${Math.floor((Math.random() * 4) + 1)}.png`} alt="" class="heroimg" />
                    <div className='hero_img_box_inner'>
                      <img src={`${path}/image/review.png`} alt="" className='avtar' />
                      <div className='text-dark'>
                        <h5 className='fn_14'><b>Raghav Bansal  <i className='fa fa-star text-warning'></i> </b></h5>
                        <p className='m-0 fn_12'>Best Study Material with best teachers. </p>
                      </div>
                    </div>
                    <div className='hero_img_box_inner_right'>
                      
                      <div className='text-dark'>
                        <h5 className='fn_14 d-flex justify-content-center align-items-center '><i className='fa fa-star text-warning fa-2x'></i> 4.5 (3.4k Reviews)</h5>
                        <p className='m-0 ml-3 fn_12'>Congratulations</p>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <svg
        class="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g class="parallax">
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(255,255,255,0.7"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(255,255,255,0.5)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(255,255,255,0.3)"
          />
          <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>
    
    </>
  )
}

export default HeroCompo