import React, { useState } from 'react'
import './Auth.css'
import { apilink, path } from '../../data/fdata'
import { CircularProgress } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useAlert } from "react-alert";
import axios from "axios";
import Cookies from 'js-cookie'
import parse from 'html-react-parser';

const ForgetPassword = () => {
  const alert = useAlert();
  const [email, setEmail] = useState('');
  const [semail, setSEmail] = useState('');

  
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [col, setCol] = useState('');

  const his = useHistory();

  const onSub = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data={email}

    const res=await axios.post(`${apilink}/api/user/forgetPassword`,data)
    // console.log(res.data)
    if(res.data.success)
    {
        setTimeout(() => {
            setStatus(true);
          setMsg(` Please check your Inbox or Spam , if did not received mail <span> <b>Click on Send Now</b> </span>`);
          // setEmail('');
          setLoading(false);
          setCol("alert-success")
           }, 1500);


    }else{
        setTimeout(() => {
            setStatus(true);
          setMsg(res.data.msg);
          // setEmail('');
          setLoading(false);
          setCol("alert-warning")
           }, 1500);
    }

   
  };





  return (
    <>
    
    <div className="auth">

      <div className="container">
        <div className="row"> 
          <div className="col-xl-5 col-lg-5 col-md-7 col-sm-10 col-12 mx-auto">
            <div className="card p-2">
            {status ? (
                  <>
                    <div class={`alert ${col} alert-dismissible`}>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        onClick={() => setStatus(false)}
                      >
                        &times;
                      </button>
                      {parse(msg)}
                     
                    </div>
                  </>
                ) : null}

            <h3 className="text-center pb-3">Forget Password</h3>
                <p className="text-center">
                  Enter Your email to get your Reset Password link
                </p>
                <br />
                <form onSubmit={onSub} className="p-3">
                  <div class="form-group">
                    <input
                      type="email"
                      placeholder="Enter Email"
                      class="form-control formh"
                      
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value.toLowerCase())
                        setSEmail(e.target.value.toLowerCase())
                      }}
                      required
                    />
                  </div>

                  <div className="text-box">
                    <p
                      onClick={() => his.push('/login')}
                      style={{ cursor: 'pointer' }}
                      className="re_p fn_12 mt-4"
                    >
                      Back to Login
                    </p>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading}
                    >
                      
                      Send Now
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress size={45} />
                    </div>
                  )}
                </form>
            </div>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}

export default ForgetPassword