import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import BedEnroll from './BedEnroll'
import './Enroll.css'
import axios from 'axios'
import { apilink } from '../../data/fdata'
import Cookies from 'js-cookie'
import DledEnroll from './DledEnroll'
import DpharmEnroll from './DpharmEnroll'
const EntrollHone = () => {
  const token = Cookies.get('_edushark_user_access_token');

  const history= useHistory()
   const {coursid}= useParams()
 const [courseDetails,setCourseDetails]=useState({})
 const [pageLoading,setPageLoading]=useState(false)
 const [errorFound, setErrorFound] = useState(false);
 const [userDetails,setUserDetails]=useState({})

 const [feesList,setFeesList] = useState([])
 const [sessionData,setSessionData] = useState("")

 useEffect(() => {
  // 👇️ scroll to top on page load
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}, []);


 useEffect(async() => {
  setPageLoading(true)

  if(token) {
    
      const res=await axios.get(`${apilink}/api/user/authVerify`,{
          headers: {
            Authorization: token,
          },
        })
        // console.log(res.data)
        if(!res.data.success)
        {
         
        setTimeout(() => {
          Cookies.remove('_edushark_user_access_token');
          localStorage.removeItem('_edushark_user_access_login');
         console.clear();
         
          setPageLoading(false)
          window.location.href = '/login';
        }, 1500);




        }else{
         
          setTimeout(() => {
            setUserDetails(res.data.userInfo)
            setPageLoading(false)
          }, 1500);
        

        }
       
  }else{

   
    setTimeout(() => {
      setPageLoading(false)
      history.push("/login")
    }, 1500);
   
  }

}, []);


   const getCourseById=async(cid)=>{
    const res=await axios.get(`${apilink}/api/course/getCourseById/${cid}`)
    // console.log(res.data.result   )
    if(res.data.success)
    {
     
      setCourseDetails(res.data.result)
      
    }
    else{
      setTimeout(() => {
        setErrorFound(true)
      
      }, 4000);
      
    }
    
  
   }


   const getFeestructs=async()=>{
    const res=await axios.get(`${apilink}/api/feestruct/getFeestructs`)
    // console.log(res.data)
    if(res.data.success){
        setFeesList(res.data.result)

    }else{
        alert.error(res.data.msg)
    }
  }

  const getAllSessions=async()=>{
    

    const res=await axios.get(`${apilink}/api/feestruct/getSessions`)
    // console.log(res.data)
    if(res.data.success){
        setSessionData(res.data.result[0].session_app)
          
    }else{
        alert.error(res.data.msg)
    }
  }

   useEffect(()=>{

    if(coursid)
    {
      
      getCourseById(coursid)
      getFeestructs()
      getAllSessions()
    }

   },[coursid])



   if(errorFound)
  {
    history.push("/something-wrong")
  }

  if(!pageLoading)
  {
    if(coursid=="650a810715eccf003d98f51e" && courseDetails?.cou_isActive)
    {
     return (
         <>
         
         <BedEnroll sessionData={sessionData} datalist={feesList.filter((v)=>v.feesTypeAction=="Admission" && v.feesCourse=="BED")}  coursid={coursid} cou_name="BED" name={userDetails.name} email={userDetails.email} phone={userDetails.phone}  />
         </>
     )
    }else if(coursid=="650a83ab15eccf003d98f52d" && courseDetails?.cou_isActive )
    {
      return (
        <>
        
        <DledEnroll sessionData={sessionData} datalist={feesList.filter((v)=>v.feesTypeAction=="Admission" && v.feesCourse=="DELED")} coursid={coursid} cou_name="DELED" name={userDetails.name} email={userDetails.email} phone={userDetails.phone} cou_colleges={courseDetails?.cou_colleges  } />
        </>
    )
    }else if(coursid=="650a866c15eccf003d98f538" && courseDetails?.cou_isActive )
    {
      return (
        <>
        
        <DpharmEnroll sessionData={sessionData} datalist={feesList.filter((v)=>v.feesTypeAction=="Admission" && v.feesCourse=="DPHARM")} coursid={coursid} cou_name="DPHARM" name={userDetails.name} email={userDetails.email} phone={userDetails.phone} cou_colleges={courseDetails?.cou_colleges  } />
        </>
    )
    }
  }



  return (
    <>
     <div className="enrollbox">
     <div className="loadingio-spinner-blocks-n2or5axwwk">
      <div className="ldio-3rgqhfk5x79">
        <div style={{ left: '38px', top: '38px', animationDelay: '0s' }}></div>
        <div style={{ left: '80px', top: '38px', animationDelay: '0.125s' }}></div>
        <div style={{ left: '122px', top: '38px', animationDelay: '0.25s' }}></div>
        <div style={{ left: '38px', top: '80px', animationDelay: '0.875s' }}></div>
        <div style={{ left: '122px', top: '80px', animationDelay: '0.375s' }}></div>
        <div style={{ left: '38px', top: '122px', animationDelay: '0.75s' }}></div>
        <div style={{ left: '80px', top: '122px', animationDelay: '0.625s' }}></div>
        <div style={{ left: '122px', top: '122px', animationDelay: '0.5s' }}></div>
      </div>
    </div>
    <h5>Loading...</h5>
        </div>
        
    </>
  )
}

export default EntrollHone