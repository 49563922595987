import React from 'react'
import './Student.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const Student = () => {
    const his = useHistory();
  return (
    <>
    
    <div className="stu_home">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-8 col-12 mx-auto">
                    <div className="p-2">
                        <img src="https://studyfirsteurope.com/wp-content/uploads/2022/09/Step-1-1.gif" alt="" className='stu_gif' />
                        <div className="text-center pt-3">
                        <button onClick={()=>his.push("/student/dashboard")} className='btn btn-primary'>Go to Dashboard</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Student