import React,{  useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Accordion.css";
import { useSpring, animated } from "react-spring";
import { CircularProgress } from '@mui/material'
import { useAlert } from "react-alert";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { apilink } from "../../data/fdata";
import Cookies from "js-cookie";
import axios from "axios"

function Accordion({ index, title, data  }) {

  const his = useHistory();
const alert=useAlert()

  const [open, setOpen] = useState(false);

  // console.log(data)

 

  //toggle accordion function
  let toggleHandler = (e) => {
    //switch state
    setOpen(!open);
  };
  useEffect(() => {
    if (index == 1) setOpen(!open);
  }, []);
  //conditional styling
  const styles = {
    //if open is true, change color of title
    accordionTitle: {
      color: open ? "#0082e6" : "#000000",
    },
  };
  //open animation with react spring

  const openAnimation = useSpring({
    from: { opacity: "0", maxHeight: "40px" },
    to: { opacity: "1", maxHeight: open ? "100%" : "40px" },
    config: { duration: "120" },
  });

  //rotate animation
  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
      color: "#000000",
    },
    to: {
      transform: open ? "rotate(180deg)" : "rotate(0deg)",
      color: open ? "#0082e6" : "#000000",
    },
    config: { duration: "120" },
  });


// console.log(educationCor)
//   console.log(educationObj)

  return (
    <>
   
      <animated.div className="accordion__item" style={openAnimation}>
        <div className="accordion__header" onClick={toggleHandler}>
          <h4 style={styles.accordionTitle}>{title}</h4>
          <animated.i style={iconAnimation}>
            <ExpandMoreIcon />
          </animated.i>
        </div>
        <div className="accordion__content">
          {index == 1 && (
            <>
            <div className="row mb-4">
              <div className="col-12">
              <h4>
                    <span>Application ID:</span>
                    {data?.AppID}
                  </h4>
              </div>
            </div>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Session:</span>
                    {data?.session_year}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Course:</span>
                    {data?.course_name}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>College:</span>
                    {data?.bed_form?.choose_college.length > 0 &&
                      data?.bed_form?.choose_college}

                    {data?.dled_form?.choose_college.length > 0 &&
                      data?.dled_form?.choose_college}

                    {data?.dpharm_form?.choose_college.length > 0 &&
                      data?.dpharm_form?.choose_college}
                  </h4>
                </div>
                {
                  data?.bed_form?.choose_subject.length > 0  &&  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>College Subject:</span>
                    {
                      data?.bed_form?.choose_subject}

                   
                  </h4>
                </div>
                }
                
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Course_Fees:</span>
                    {data?.course_fees}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span> ApplicationStatus:</span>
                    {data?.ApplicationStatus == "PENDING" && (
                      <small className="badge badge-warning">Pending</small>
                    )}
                    {data?.ApplicationStatus == "APPROVED" && (
                      <small className="badge badge-success">Approved</small>
                    )}
                    {data?.ApplicationStatus == "REJECTED" && (
                      <small className="badge badge-danger">Rejected</small>
                    )}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Reffered_by:</span>
                    {data?.reffered_by}
                  </h4>
                </div>
               
              </div>
            </>
          )}
          {index == 2 && (
            <>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Name:</span>
                    {data?.personalinfo?.applicant_name}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Have Student Id:</span>
                    {data?.have_bss_id}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
                  <h4>
                    <span>Student Id:</span>
                    {data?.bss_id}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Father_name:</span>
                    {data?.personalinfo?.father_name}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Mother_name:</span>
                    {data?.personalinfo?.mother_name}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Guardian_name:</span>
                    {data?.personalinfo?.guardian_name}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Relation_with_guardian:</span>
                    {data?.personalinfo?.relation_with_guardian}
                  </h4>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Gender:</span>
                    {data?.personalinfo?.gender}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Category:</span>
                    {data?.personalinfo?.category}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>BloodGp:</span>
                    {data?.personalinfo?.bloodGp}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Aadhar_no:</span>
                    {data?.personalinfo?.aadhar_no}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Applicant_dob:</span>
                    {new Date(
                      data?.personalinfo?.applicant_dob
                    ).toLocaleDateString('en-GB')}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>languages:</span>
                    {data?.personalinfo?.languages}
                  </h4>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Nationality:</span>
                    {data?.personalinfo?.nationality}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Religion:</span>
                    {data?.personalinfo?.religion}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Marital_status:</span>
                    {data?.personalinfo?.marital_status}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Bpl:</span>
                    {data?.personalinfo?.bpl}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Specially_abled:</span>
                    {data?.personalinfo?.specially_abled}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Percentage_disable:</span>
                    {data?.personalinfo?.percentage_disable}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Type_disable:</span>
                    {data?.personalinfo?.type_diable}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Income:</span>
                    {data?.personalinfo?.income}
                  </h4>
                </div>
              </div>
            </>
          )}

          {index == 3 && (
            <>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Applicant_email:</span>
                    {data?.contactinfo?.applicant_email}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Applicant_phone:</span>
                    {data?.contactinfo?.applicant_phone}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Applicant_whatsappno:</span>
                    {data?.contactinfo?.applicant_whatsappno}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Guardian_phone:</span>
                    {data?.contactinfo?.guardian_phone}
                  </h4>
                </div>
              </div>
              <p>
                <b>Current Address</b>
              </p>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Address:</span>
                    {data?.addressinfo?.caddress}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Block:</span>
                    {data?.addressinfo?.cblock}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Block_name:</span>
                    {data?.addressinfo?.cblockname}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>State:</span>
                    {data?.addressinfo?.cstate}
                  </h4>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>district:</span>
                    {data?.addressinfo?.cdistrict}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>PoliceStation:</span>
                    {data?.addressinfo?.cps}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>P.O:</span>
                    {data?.addressinfo?.cpo}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Pin:</span>
                    {data?.addressinfo?.cpin}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Country:</span>
                    {data?.addressinfo?.ccountry}
                  </h4>
                </div>
              </div>
              <p>
                <b>Parmanent Address</b>
              </p>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Address:</span>
                    {data?.addressinfo?.paddress}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Block:</span>
                    {data?.addressinfo?.pblock}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Block_name:</span>
                    {data?.addressinfo?.pblockname}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>State:</span>
                    {data?.addressinfo?.pstate}
                  </h4>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>district:</span>
                    {data?.addressinfo?.pdistrict}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>PoliceStation:</span>
                    {data?.addressinfo?.pps}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>P.O:</span>
                    {data?.addressinfo?.ppo}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Pin:</span>
                    {data?.addressinfo?.ppin}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Country:</span>
                    {data?.addressinfo?.pcountry}
                  </h4>
                </div>
              </div>
            </>
          )}

          {index == 4 && (
            <>
              {data?.bed_form?.choose_college?.length > 0 && (
                <>
                  <p>
                    <b>School Details</b>
                  </p>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>LastUniversity:</span>
                        {data?.bed_form?.schoolinfo?.lastUniversity}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>RegNo_last_University:</span>
                        {data?.bed_form?.schoolinfo?.regNo_lastu}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_board:</span>
                        {data?.bed_form?.schoolinfo?.mboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_year:</span>
                        {data?.bed_form?.schoolinfo?.myear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_full_marks:</span>
                        {data?.bed_form?.schoolinfo?.mfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_marks:</span>
                        {data?.bed_form?.schoolinfo?.mmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_percentage_marks:</span>
                        {data?.bed_form?.schoolinfo?.mpercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_board:</span>
                        {data?.bed_form?.schoolinfo?.hboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_year:</span>
                        {data?.bed_form?.schoolinfo?.hyear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_full_marks:</span>
                        {data?.bed_form?.schoolinfo?.hfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_marks:</span>
                        {data?.bed_form?.schoolinfo?.hmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_percentage_marks:</span>
                        {data?.bed_form?.schoolinfo?.hpercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <p>
                    <b>College Details</b>
                  </p>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Status_ug:</span>
                        {data?.bed_form?.collegeinfo?.status_ug}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Type_ug:</span>
                        {data?.bed_form?.collegeinfo?.type_ug}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>University:</span>
                        {data?.bed_form?.collegeinfo?.university}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>U_year:</span>
                        {data?.bed_form?.collegeinfo?.uyear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>U_full_marks:</span>
                        {data?.bed_form?.collegeinfo?.ufull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>U_marks:</span>
                        {data?.bed_form?.collegeinfo?.umarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>U_percentage_marks:</span>
                        {data?.bed_form?.collegeinfo?.upercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Have_master:</span>
                        {data?.bed_form?.collegeinfo?.have_master}
                      </h4>
                    </div>
                    {data?.bed_form?.collegeinfo?.have_master == "YES" && (
                      <>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>Status_pg:</span>
                            {data?.bed_form?.collegeinfo?.status_pg}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>Type_pg:</span>
                            {data?.bed_form?.collegeinfo?.type_pg}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>P_university:</span>
                            {data?.bed_form?.collegeinfo?.puniversity}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>P_year:</span>
                            {data?.bed_form?.collegeinfo?.pyear}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>P_full_marks:</span>
                            {data?.bed_form?.collegeinfo?.pfull_marks}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>P_marks:</span>
                            {data?.bed_form?.collegeinfo?.pmarks}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>P_percentage_marks:</span>
                            {data?.bed_form?.collegeinfo?.ppercentage_marks}
                          </h4>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {data?.dled_form?.choose_college?.length > 0 && (
                <>
                  <p>
                    <b>School Details</b>
                  </p>
                  <div className="row">
                    

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_board:</span>
                        {data?.dled_form?.schoolinfo?.mboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_year:</span>
                        {data?.dled_form?.schoolinfo?.myear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_full_marks:</span>
                        {data?.dled_form?.schoolinfo?.mfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_marks:</span>
                        {data?.dled_form?.schoolinfo?.mmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_percentage_marks:</span>
                        {data?.dled_form?.schoolinfo?.mpercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_institute:</span>
                        {data?.dled_form?.schoolinfo?.hinstitute}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_board:</span>
                        {data?.dled_form?.schoolinfo?.hboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_year:</span>
                        {data?.dled_form?.schoolinfo?.hyear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_full_marks:</span>
                        {data?.dled_form?.schoolinfo?.hfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_marks:</span>
                        {data?.dled_form?.schoolinfo?.hmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_percentage_marks:</span>
                        {data?.dled_form?.schoolinfo?.hpercentage_marks}
                      </h4>
                    </div>
                  

                                   


                  </div>
                  
                  <hr />
                  <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>First_lang:</span>
                        {data?.dled_form?.schoolinfo?.flang}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>First_lang_full_marks:</span>
                        {data?.dled_form?.schoolinfo?.flang_full}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>First_lang_marks:</span>
                        {data?.dled_form?.schoolinfo?.flang_marks}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Second_lang:</span>
                        {data?.dled_form?.schoolinfo?.slang}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Second_lang_full_marks:</span>
                        {data?.dled_form?.schoolinfo?.slang_full}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Second_lang_marks:</span>
                        {data?.dled_form?.schoolinfo?.slang_marks}
                      </h4>
                    </div>



                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Sub1_marks:</span>
                        {data?.dled_form?.schoolinfo?.sub1_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Sub2_marks:</span>
                        {data?.dled_form?.schoolinfo?.sub2_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Sub3_marks:</span>
                        {data?.dled_form?.schoolinfo?.sub3_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Sub4_marks:</span>
                        {data?.dled_form?.schoolinfo?.sub4_marks}
                      </h4>
                    </div>

                  </div>


                  
                </>
              )}
              {data?.dpharm_form?.choose_college?.length > 0 && (
                <>
                  <p>
                    <b>School Details</b>
                  </p>
                  <div className="row">
                 

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_board:</span>
                        {data?.dpharm_form?.schoolinfo?.mboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_year:</span>
                        {data?.dpharm_form?.schoolinfo?.myear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_full_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.mfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.mmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_percentage_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.mpercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_institute:</span>
                        {data?.dpharm_form?.schoolinfo?.hinstitute}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_board:</span>
                        {data?.dpharm_form?.schoolinfo?.hboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_year:</span>
                        {data?.dpharm_form?.schoolinfo?.hyear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_full_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.hfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.hmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_percentage_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.hpercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Physics Marks:</span>
                        {data?.dpharm_form?.schoolinfo?.phy_marks}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Chemistry Marks:</span>
                        {data?.dpharm_form?.schoolinfo?.che_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>High_marks_sub:</span>
                        {data?.dpharm_form?.schoolinfo?.high_marks_sub}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>High_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.high_marks}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Pass_CUET:</span>
                        {data?.dpharm_form?.schoolinfo?.pass_cuet}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>CUET_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.cuet_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>CUET_year:</span>
                        {data?.dpharm_form?.schoolinfo?.cuet_year}
                      </h4>
                    </div>
                   
                    
                  </div>

                  


                  
                </>
              )}
            </>
          )}
          {
            index == 5 && (
              <>
              
              <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.profileImg}>Profile Photo <i className="fa fa-external-link"></i> </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.signatureImg}>Signature Photo <i className="fa fa-external-link"></i> </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.admit10thImg}>10th Admin Card <i className="fa fa-external-link"></i> </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.result10thImg}>10th Result  <i className="fa fa-external-link"></i> </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.result12thImg}>12th Result <i className="fa fa-external-link"></i> </a>
                    </div>
{
  data?.documentinfo?.resultugImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.resultugImg}>Under Graduation Result <i className="fa fa-external-link"></i> </a>
</div>
}

{
  data?.documentinfo?.resultpgImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.resultpgImg}>Post Graduation Result <i className="fa fa-external-link"></i> </a>
</div>
}

<div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.resultAadharImg}>Aadhar Card <i className="fa fa-external-link"></i> </a>
                    </div>


                    {
  data?.documentinfo?.resultCastImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.resultCastImg}>Cast Certificate <i className="fa fa-external-link"></i> </a>
</div>
}
{
  data?.documentinfo?.otherImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.otherImg}>Other Documents <i className="fa fa-external-link"></i> </a>
</div>
}
{
  data?.documentinfo?.disableImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.disableImg}>Disable Certificate <i className="fa fa-external-link"></i> </a>
</div>
}

{
  data?.documentinfo?.fitnessImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.fitnessImg}>Fitness Certificate <i className="fa fa-external-link"></i> </a>
</div>
}

{
  data?.documentinfo?.residenceImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.residenceImg}>Residence Certificate <i className="fa fa-external-link"></i> </a>
</div>
}

                    </div>
              </>
            )
          }
          {
            index == 6 && (
              <>
              <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Payment Id:</span>
                        {data?.paymentId}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Payment Amount:</span>
                        {data?.paymentAmount}
                      </h4>
                    </div>
                   
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Payment Status:</span>
                        {data?.paymentStatus ? "PAID" : "UNPAID"}
                      </h4>
                    </div>
                    </div>
              </>
            )
          }
        </div>
      </animated.div>
    </>
  );
}

export default Accordion;
