import React, { useEffect, useState } from 'react'
import './Navbar.css'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import DehazeIcon from '@mui/icons-material/Dehaze';
import CloseIcon from '@mui/icons-material/Close';
import { apilink, path } from '../../data/fdata';
import Cookies from 'js-cookie';
import axios from 'axios';
const Navbar = () => {
  const [navLink,setNavLink]=useState(false)
  const [loginstatus,setLoginstatus]=useState(false)
  const token = Cookies.get('_edushark_user_access_token');

  const [uname,setUName]=useState("")
  const [uemail,setUEmail]=useState("")



  const navbar_link=[
    {
      title:"Home",
      link:"/"
    },
    
    {
      title:"Courses",
      link:"/courses"
    },
    {
      title:"Colleges",
      link:"/colleges"
    },
    {
      title:"Books",
      link:"/purchase-books"
    },
    {
      title:"Classes",
      link:"https://class.edushark.in"
    },
    {
      title:"Gallery",
      link:"/gallery"
    }
  ]



  useEffect(async() => {
    if(token) {
      
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_user_access_token');
           localStorage.removeItem('_edushark_user_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            setLoginstatus(true)
// setUEmail(res.data.userInfo.email)
// setUName(res.data.userInfo.name)


  
          }
         
    }else{
      setLoginstatus(false)
    }
  }, []);


  // const clickHandle=async()=>{

  //   if(loginstatus)
  //   {
  //     const res=await axios.get(`${apilink}/api/class/login/${uname}/${uemail}`)
  //     // console.log(res.data.url)
  
  //     if(res.data.success){
  //       window.open(res.data.url, "_blank")
  //     }else{
  //       window.open("https://class.edushark.in", "_blank")
  //     }
  //   }else{
  //     window.open("https://class.edushark.in", "_blank") 
  //   }

    
    

  // }
  
  return (
    <>
      <nav>
      <div className="inn_nav">
      <NavLink to="/">
      <img src={`${path}/image/logo.png`} alt="" className='logo' />
      </NavLink>
     
     
      
      <div className='bars' onClick={()=>setNavLink(prev=>!prev)}>
      {navLink ? <CloseIcon/>  : <DehazeIcon/>} 
     
      </div>
      

      <div className="big_nav">
      <ul>
        
      
        
        {
          navbar_link?.map((val,ind)=>{
            return <li>{val.title=="Classes" ? <a href={val.link} target='_blank' >{val.title}</a> :<NavLink to={val.link} >{val.title}</NavLink>}</li>
          })
        }
        {
           loginstatus ? <li><NavLink to="/student/dashboard" className="register_btn" > My Account</NavLink></li> : <li><NavLink to="/login" className="register_btn" >Login</NavLink></li>
        }
        
       
           
        </ul>
      </div>

      
      {
        navLink && <>
        <div className="sml_nav">
      <ul>
        
      {
          navbar_link?.map((val,ind)=>{
            return <li onClick={()=>setNavLink(prev=>!prev)}>{val.title=="Classes" ? <a href={val.link} target='_blank'>{val.title}</a> :<NavLink to={val.link} >{val.title}</NavLink>}</li>
          })
        }
      
        
      {
           loginstatus ? <li><NavLink to="/student/dashboard" className="register_btn" >My Account</NavLink></li> : <li><NavLink to="/login" onClick={()=>setNavLink(prev=>!prev)} className="register_btn" >Login</NavLink></li>
        }
      
           
        </ul>
      </div>
        
        </>
      }

      

     
      
      </div>
     
     
    </nav>
    </>
  )
}

export default Navbar


