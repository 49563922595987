import React from 'react'
import './Success.css'
const ApplicationSuccess = () => {
  return (
    <>
     <div className="success_page">
    <div className="container-fluid ">
        <div className="row">
            <div className="col-lg-6 col-md-8 col-12 mx-auto">
                <div className="card py-5 px-3 text-center">
                    <h5 className='text-primary m-0'>Thank You For Your Application . We will validate your details.</h5>
                </div>
            </div>

        </div>


        </div>
    </div>
    </>
  )
}

export default ApplicationSuccess