import React, { useEffect, useState } from 'react'
import './Auth.css'
import { apilink, path } from '../../data/fdata'
import { CircularProgress } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useAlert } from "react-alert";
import axios from "axios";
import Cookies from 'js-cookie'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const Login = () => {
  const token = Cookies.get('_edushark_user_access_token');
  const alert = useAlert();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordVis, setPasswordVis] = useState("password");


  const his = useHistory();

  useEffect(async() => {
    if(token) {
     
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(res.data.success)
          {
            alert.success("You are already logged in.")
            his.push("/student/dashboard")
          }
         
         
    }
  }, []);

  const onSub = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data={email,password}

    const res=await axios.post(`${apilink}/api/user/login`,data)
    // console.log(res.data)
    if(res.data.success)
    {
      alert.success("Login successfully done!");
      localStorage.setItem('_edushark_user_access_login', true);
      
      Cookies.set('_edushark_user_access_token', res.data.access_token, {
        expires: 1,
      });

      setTimeout(() => {
        window.location.href="/student/dashboard"
      }, 1500);


    }else{
      alert.error(res.data.msg)
    }

    setLoading(false);
  };
  return (
    <>
    
    <div className="auth">

      <div className="container">
        <div className="row"> 
          <div className="col-xl-8 col-lg-10 col-md-12 col-sm-10 col-12 mx-auto">
            <div className="card p-2">
              <div className="row">
                <div className="col-md-6 col-12">
                  <img src={`${path}/image/auth.jpg`} alt="" className='auth_img' />
                </div>
                <div className="col-md-6 col-12">
                  <div className="py-3 px-1 auth_box">
                  <h2 className='text-center text-col'>Login Now</h2>
                  
                    <form className='mt-5' onSubmit={onSub}>
                    <input type="email" className='mb-4' placeholder='Enter Email' value={email} onChange={(e)=>setEmail(e.target.value.toLowerCase())} required />
                    
                    {/* <input type="password" placeholder='Enter Password'  value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required /> */}

<div className="d-flex mb-3">
                        <input
                          type={passwordVis}
                          placeholder="Enter Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        {
                          passwordVis=="password" ?  <p title="show password" className="input_btn m-0 " onClick={()=>setPasswordVis("text")}><VisibilityOffIcon fontSize="small" /></p>:  <p title="hide password" className="input_btn m-0" onClick={()=>setPasswordVis("password")}><VisibilityIcon fontSize="small" /></p>
                        }
                       
                        
                        </div>

                    <div className="flex_box mt-2">
                      <small onClick={()=>his.push("/forget-password")} className='m-0 text-custom cur re_p '>Forget password?</small>
                      <small onClick={()=>his.push("/register")} className='m-0 text-custom cur re_p '>Don't have Account?</small>

                    </div>
                    <div className="text-center mt-3">
                    <button  type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading}>Submit</button>
                    </div>
                    {loading && (
                    <div className="text-center p-2">
                      <CircularProgress  size={40} />
                    </div>
                  )}
                    </form>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}

export default Login