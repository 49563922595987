import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { apilink } from '../../../../data/fdata'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { saveAs } from 'file-saver';

const MyOrderCompo = ({id,bookname , book_id ,  ship_order_id , order , token ,sessionToken, alert , setPageloading }) => {

const [orderStatus,setOrderStatus]=useState({})
const [orderStatusTrack,setOrderStatusTrack]=useState([])
const his = useHistory();


const getTrackingDet=async(tid)=>{
  if(tid)
  {
    // console.log(tid)
    // trackOrderByawb
    const res=await axios.post(`${apilink}/api/shiprocket/trackOrderByawb`,{
      token,
      awb_id:tid
    })
    // console.log(res.data.result.tracking_data.shipment_track_activities)
    if(res.data.success)
    {
      setOrderStatusTrack(res.data.result.tracking_data.shipment_track_activities)
    }
  }
  
}

    const getShipOrderDetails=async()=>{
        const res=await axios.post(`${apilink}/api/shiprocket/getOrderByship_order_id`,{
            token,
            ship_order_id
                })
                // console.log(res.data.result.data.awb_data?.awb )
                if(res.data.success)
                {
                    setOrderStatus(res.data.result.data)
                    // getTrackingDet(3254491809)
                    getTrackingDet(res.data.result.data.awb_data?.awb)
                    // console.log()
                }else{
                    alert.error(res.data.msg)
                }
    }
    useEffect(()=>{
        if(ship_order_id){
            getShipOrderDetails()
        }

    },[ship_order_id])

    const generateOrderpdf=async(vid)=>{
      setPageloading(true)

      const res=await axios.post(`${apilink}/api/pdf/order-pdf`,{
        oid:vid,
        
      })
      // console.log(res.data)
      if(res.data.success)
      {
       alert.success(res.data.msg)
       const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${vid}`,{ responseType: 'blob' })
           if(ress)
           {
               const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });

                 saveAs(pdfBlob, `order_pdf_${vid}.pdf`);
                 const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${vid}`)
                 if(!re.data.success)
                 {
                   his.push("something-wrong")
                 }
           }

      }else{
       alert.error(res.data.msg)
      }
      setPageloading(false)
    }

    const completeOrderPayment=async(vid , vamount)=>{
      setPageloading(true)
        const da={
          MUID: "MUID" + Date.now(),
        transactionId: 'T' + Date.now(),
        oid:vid,
        payment_with_shipping:vamount
        }
        

        const res=await axios.post(`${apilink}/api/orderbook/completeOrderPayment`,da,{
          headers: {
            Authorization: sessionToken,
          },
        })
      
        if(res.data.success)
      {
        //  console.log(res.data.url)
       setTimeout(() => {
        setPageloading(false)
       }, 1500);
      
        setTimeout(() => {
          window.location.href=res.data.url
         }, 2000);
      
      }else{
        setTimeout(() => {
          setPageloading(false)
         }, 1500);
        
        
        alert.error(res.data.msg)
      
      }
// update 
    }


  return (
    <>
    
    <tr>
                <td><p className='m-0 cur text-custom fn_12' data-toggle="modal"
                            data-target={`#myorderModal${id}`}>{id}</p></td>
               
                <td > <p className='m-0 cur text-custom ' data-toggle="modal"
                            data-target={`#myModal${id}`}>{bookname}</p> </td>
                            <td>
                            ₹ {Number(order.payment_with_shipping).toFixed(2)}
                            </td>
                            <td>
                            {order.payment_status  ? <><p className='m-0 text-success'>PAID</p></> : <><p className='m-0 text-danger'>UNPAID</p></>}
                            </td>
                            <td>
                              {
                                order.payment_status ?(
                                  <>
                                   {orderStatus?.awb_data?.awb ? (
                        <>
                        {/* orderStatus?.awb_data?.awb */}
                        <p title="Click here to track your order" data-toggle="modal"          data-target={`#mytrackModal${id}`} className='cur text-custom'>{orderStatus?.awb_data?.awb} <i className='fa fa-hand-pointer-o'></i> </p>
                        
                        </>
                      ) : <><small>Waiting...</small></> }
                                  </>
                                ):(
                                  <>
                                  <span className='text-danger'>FAILED</span>
                                  </>
                                )
                              }
                     
                    </td>
                <td>

                  {
                    order.payment_status ?(
                      <>
                       {
                    orderStatus.status ? (
                        <>
                        {
                    orderStatus.status == "CANCELED" ? <span className='badge badge-danger'>{orderStatus.status}</span> : <span className='badge badge-success'>{orderStatus.status}</span>
                    
                    } 
                    {
                        orderStatus.status == "NEW" ? "": <p className='m-0 fn_12'> <small>On <b>{new Date(orderStatus.updated_at).toDateString()}</b></small> </p>
                    }
                        </>
                    ):(
                        <>
                        <small>Loading...</small>
                        </>
                    )
                    }
                      </>
                    ):(
                      <>
                      
                      <span className='text-danger'>FAILED</span>
                      </>
                    )
                  }
                  
                  
                  
                  
                    
                    
                    </td>
                    <td>
                    {
                order.payment_status ?  <span onClick={()=>generateOrderpdf(order._id )} className='badge badge-light py-1 px-2 cur'> <i className='fa fa-download mr-1'></i> Receipt</span> :  <span onClick={()=>completeOrderPayment(order._id,order.payment_with_shipping)} className='badge badge-light py-1 px-2 cur'> Pay Now</span> 
               }
                    </td>
                    <td>
                      {new Date(order.createdAt).toLocaleDateString('en-GB')}
                    </td>
                   
               
              </tr>

              <div class="modal fade" id={`myModal${id}`}>
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Book Details</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              
            <h5><span className='text-custom'>Name: &nbsp;</span>{book_id.bookname} </h5>
              <h5><span className='text-custom'>Author: &nbsp;</span>{book_id.bookauthor} </h5>
              <h5><span className='text-custom'>Category: &nbsp;</span>{book_id.bookcategory} </h5>
              <h5><span className='text-custom'>Semester or Year: &nbsp;</span>{book_id.booksem} </h5>
              <h5><span className='text-custom'>Publisher: &nbsp;</span>{book_id.bookpublisher} </h5>
              <h5><span className='text-custom'>Price: &nbsp;</span>{book_id.bookprice} </h5>
              <h5><span className='text-custom'>Number of Books: &nbsp;</span>{book_id.booknumber} </h5>
              <h5><span className='text-custom'>Number of Pages: &nbsp;</span>{book_id.bookpages} </h5>



              
            
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id={`myorderModal${id}`}>
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Order Details</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              
            <h5><span className='text-custom'>Ordered Book: &nbsp;</span>{bookname} </h5>
            <h5><span className='text-custom'>Book Category: &nbsp;</span>{order?.book_id?.bookcategory} </h5>

            <h5><span className='text-custom'>Book Price: &nbsp;</span>₹ {Number(order.book_price).toFixed(2)} </h5>
            
            <h5><span className='text-custom'>Qty: &nbsp;</span>{order.book_qty} </h5>
            <h5><span className='text-custom'>Total Book Price: &nbsp;</span>₹ {Number(order.total_book_price).toFixed(2)} </h5>

            <h5><span className='text-custom'>Delivery Price: &nbsp;</span>₹{ Number(order.delivery_price).toFixed(2)} </h5>
            <h5><span className='text-custom'>Total Amount: &nbsp;</span>₹ {Number(order.payment_with_shipping).toFixed(2)} </h5>

<hr />
<h5>Address Details</h5>

<h5><span className='text-custom'>House_address: &nbsp;</span>{order?.shiprocket?.billing_address} </h5>
<h5><span className='text-custom'>Street_address: &nbsp;</span>{order?.shiprocket?.billing_address_2} </h5>
<h5><span className='text-custom'>City: &nbsp;</span>{order?.shiprocket?.billing_city} </h5>
<h5><span className='text-custom'>Pincode: &nbsp;</span>{order?.shiprocket?.billing_pincode} </h5>
<h5><span className='text-custom'>State: &nbsp;</span>{order?.shiprocket?.billing_state} </h5> 
<h5><span className='text-custom'>Customer Name: &nbsp;</span>{order?.shiprocket?.billing_customer_name} </h5>           
<h5><span className='text-custom'>Email: &nbsp;</span>{order?.shiprocket?.billing_email} </h5>           
<h5><span className='text-custom'>Phone: &nbsp;</span>{order?.shiprocket?.billing_phone} </h5>           




              
            
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id={`mytrackModal${id}`}>
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
             <div>
             <h4 class="modal-title fn_18 text-custom">#{id} </h4>
              <p className='m-0'>Order Delivery Details</p>
             </div>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              
           
            <div class="order-track">
              {
                orderStatusTrack?.map((vl,ind)=>{
                  return (
                    <>
                    <div class="order-track-step" key={ind}>
      <div class="order-track-status">
        <span class="order-track-status-dot"></span>
        <span class="order-track-status-line"></span>
      </div>
      <div class="order-track-text">
        <p class="order-track-text-stat">{vl.activity}</p>
        <p class="m-0 order-track-text-sub ">{vl.location}</p>

        <p class=" m-0 order-track-text-sub fn_12 text-custom">{new Date(vl.date).toDateString()}</p>
      </div>
    </div>
                    </>
                  )
                })
              }
    
    
  </div>

              
            
            </div>
          </div>
        </div>
      </div>
    
    </>
  )
}

export default MyOrderCompo