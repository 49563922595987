import React, { useEffect, useState } from 'react'
import './Gallery.css'

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import axios from 'axios'
import { apilink } from '../../data/fdata'

const GalleryPage = () => {
  const [galleryList,setGalleryList] = useState([])
  const [pageLoading,setPageLoading]=useState(false)

  // const images = [
  //   "https://images.unsplash.com/photo-1562774053-701939374585",
  //   "https://images.unsplash.com/photo-1693842898884-772866bde0f5",
  //   "https://picsum.photos/200/300?image=1050", 
  //   "https://images.unsplash.com/photo-1562774053-701939374585",   
  //   "https://picsum.photos/300/300?image=206",
  //   "https://images.unsplash.com/photo-1562774053-701939374585",
  //   "https://cdn.pixabay.com/photo/2012/03/01/00/55/flowers-19830_1280.jpg",
  //   "https://images.unsplash.com/photo-1562774053-701939374585",
  //   "https://cdn.pixabay.com/photo/2023/06/10/06/36/pink-8053329_1280.jpg",
  //   "https://cdn.pixabay.com/photo/2013/07/21/13/00/rose-165819_1280.jpg",
  //   // "https://plus.unsplash.com/premium_photo-1688045560212-b218aa2f110a",
    
  // ];

  const getAllgallery=async()=>{
    setPageLoading(true)
    const res=await axios.get(`${apilink}/api/gallery/getAllgallery`)
    // console.log(res.data)

    if(res.data.success){
        setGalleryList(res.data.result)

    }

   setTimeout(() => {
    setPageLoading(false)
   }, 3000);

}



  useEffect(()=>{
    getAllgallery()
  },[])
  

  return (
    <>
    
    <div className="section_page">
    <div className="container-fluid    ">
          <div className="row mt-5">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 mx-auto">
              <h1 className='text-col title_font text-center'>Our Gallery</h1>
              {
                galleryList.length > 0 ?(
                  <>
                   <div className='mt-5'>
             <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry>
                    {galleryList?.map((val, i) => (




                        <div className='img_text' key={i}>

<div id={`demo${val._id}`} class="carousel slide" data-ride="carousel">


<div class="carousel-inner">
  {
    val.gal_image.map((v,i)=>{
      return (
        <div class={i==0 ?" carousel-item active" : "carousel-item "}>
 <img
                            key={i}
                            src={v}
                            style={{width: "100%", display: "block"}}
                            alt=""
                        />
  </div>
      )
    })
  }
  
</div>


<span class="carousel-control-prev cur" href={`#demo${val._id}`} data-slide="prev">
  <span class="carousel-control-prev-icon">
    <i className='fa fa-arrow'></i>
  </span>
</span>
<span class="carousel-control-next cur" href={`#demo${val._id}`} data-slide="next">
  <span class="carousel-control-next-icon"></span>
</span>
</div>
                          {/* <img
                            key={i}
                            src={val.gal_image[0]}
                            style={{width: "100%", display: "block"}}
                            alt=""
                        /> */}
                        <div className="img_text_page">
                         {val.gal_title}
                        </div>

                        </div>

                    ))}
                </Masonry>
            </ResponsiveMasonry>
             </div>
                  
                  </>
                ):(
                  <>
                  <div className="p-3 text-center">
                    <h5>No Items Found</h5>
                  </div>
                  
                  
                  </>
                )
              }
            
              
              </div>
              </div>
              </div>
              </div>

              {
  pageLoading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
    </>
  )
}

export default GalleryPage