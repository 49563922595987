import React, { useEffect, useState } from "react";
import "../../Student.css";
import SideBar from "../../component/sidebar/SideBar";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from 'js-cookie';
import { apilink } from "../../../../data/fdata";
import axios from "axios";
import { useAlert } from "react-alert";

const AccountDet = () => {
    const [userData, setUserData] = useState([]);
  const [pageloading, setPageloading] = useState(false);
  const token = Cookies.get('_edushark_user_access_token');
  const his = useHistory();
 const alert= useAlert()

 useEffect(async() => {
    if(token) {
      setPageloading(true)
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_user_access_token');
           localStorage.removeItem('_edushark_user_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            
              setUserData(res.data.userInfo)
            
  
          }
          setTimeout(() => {
            setPageloading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);

 

  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="card rounded py-3  px-2 ">
              <h3 className=""><u>Account Details</u></h3>
             <h5>Name: <span className="text-custom">{userData?.name}</span> </h5>
             <h5>Email: <span className="text-custom">{userData?.email}</span> </h5>
             <h5>Phone: <span className="text-custom">{userData?.phone}</span> </h5>

             {
              userData?.isApproved &&  <div className="text-right">
              <NavLink to={`/student/student-basic-details`} className="btn btn-primary">View Application</NavLink>
              </div>
            }
              
             </div>
          </div>
        </div>
      </div>
      {
  pageloading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
    
    </>
    
  )
}

export default AccountDet