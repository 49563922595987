import React from "react";
import "./Policy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="section_page">
        <div className="container   mt-5">
          <h1>Privacy Policy</h1>
          <br />
          <p className="fn_14">Last Updated: 20/11/2023</p>

          <p>
            Welcome to <a href="https://edushark.in" target="_blank">Edushark.in</a> ("us,"
            "we," or "our"). We respect your privacy and are committed to
            protecting your personal information. This Privacy Policy is
            designed to help you understand how we collect, use, and safeguard
            your information.
          </p>

          <h5 className="mb-3">
            <b>1. Information We Collect</b>
          </h5>
         <div className="pl-3">
         <b>1.1 Personal Information</b>
          <p className="mt-2">
            {" "}
            We may collect personal information from you when you visit our
            website, register for our services, make a purchase, or engage with
            us in any way. This may include your name, email address, phone
            number, and other relevant details.
          </p>
          <b>1.2 Payment Information </b>
          <p className="mt-2">
            When you make a purchase on <a href="https://edushark.in" target="_blank"> Edushark.in </a>, your payment is processed
            through a secure payment gateway. We do not store your credit/debit
            card details. The payment gateway is responsible for processing your
            payment securely.
          </p>

          <b>1.3 Student Admission Information</b>
          <p className="mt-2">
            If you use our services to apply for admission to a college, we may
            collect and process information relevant to the admission process,
            such as educational qualifications, exam scores, and other necessary
            details.
          </p>

          <b>1.4 Address and Delivery Information</b>
          <p className="mt-2">
            When you purchase books from <a href="https://edushark.in" target="_blank"> Edushark.in </a>, we collect your address to
            facilitate the delivery through courier services. This may include
            your locality, city, state, and PIN code.
          </p>
         </div>
         <h5 className="mb-3">
            <b>2. How We Use Your Information</b>
          </h5>
          <div className="pl-3">
            <p className="mt-2">We use the information collected for the following purposes:</p>
            <ul className="pl-3">
                <li>To process your orders and deliver purchased books.</li>
                <li>To facilitate the student admission process as per your requests.</li>
                <li>To communicate with you, providing updates, newsletters, and other relevant information.</li>
                <li>To improve and enhance our website and services.</li>
            </ul>
          </div>
          <h5><b>3. Cookies and Tracking Technologies</b></h5>
          <div className="pl-3">
          
        <p className="mt-2">We use cookies and similar technologies to enhance your experience on <a href="https://edushark.in" target="_blank">Edushark.in </a>. By using our website, you consent to the use of cookies in accordance with our Cookie Policy. </p>
          </div>
       <h5><b>4. Data Security</b></h5>
          <div className="pl-3">
            <p className="mt-2">We implement security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. Your payment information is securely handled by our payment gateway partner.</p>
           
          </div>
<h5><b>5. Third-Party Links</b></h5>
          <div className="pl-3">
            <p className="mt-2">Our website may contain links to third-party websites. We have no control over the content or privacy practices of these sites and are not responsible for their content or privacy policies.</p>
           
          </div>

<h5><b>6. Your Choices</b></h5>
          <div className="pl-3">
            <p className="mt-2">You have the right to access, correct, or delete your personal information. You can manage your communication preferences and cookie settings through your browser.</p>
           
          </div>

<h5><b>7. Changes to this Privacy Policy</b></h5>
          <div className="pl-3">
            <p className="mt-2">We reserve the right to update or change our Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on our website.</p>
           
          </div>

          <h5><b>8. Contact Us</b></h5>

          <div className="pl-3">
            <p className="mt-2">If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@edushark.in">support@edushark.in</a> or at the following address:</p>
            <p>Locality - Guripaara , <br />
Po - Azimganj ,<br />
PIN - 742122 ,<br />
State - West Bengal ,<br />
Country - India .</p>
           
          </div>

          

        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
