export const validateInput = (name, value) => {
  switch (name) {
    case "aadhar_no":
      if (!/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/.test(value)) {
        return "Invalid aadhar number and length must be 12";
      }
      break;
    case "income":
      if (parseInt(value) < 60000) {
        return "Annual income should be >= 60000";
      }
      break;

    case "applicant_whatsappno":
      if (!/^[6-9]{1}[0-9]{9}$/.test(value)) {
        return "Invalid phone number and length must be 10";
      }
      break;
    case "guardian_phone":
      if (!/^[6-9]{1}[0-9]{9}$/.test(value)) {
        return "Invalid phone number and length must be 10";
      }
      break;

    case "ppin":
      if (!/^[1-9]{1}[0-9]{5}$/.test(value)) {
        return "Invalid Pincode and length must be 6";
      }
      break;
    case "cpin":
      if (!/^[1-9]{1}[0-9]{5}$/.test(value)) {
        return "Invalid Pincode and length must be 6";
      }
      break;
    case "myear":
      if (!/^[1-9]{1}[0-9]{3}$/.test(value)) {
        return "Invalid Year";
      }
      break;
    case "hyear":
      if (!/^[1-9]{1}[0-9]{3}$/.test(value)) {
        return "Invalid Year";
      }
      break;
    case "uyear":
      if (!/^[1-9]{1}[0-9]{3}$/.test(value)) {
        return "Invalid Year";
      }
      break;
      case "pyear":
      if (!/^[1-9]{1}[0-9]{3}$/.test(value)) {
        return "Invalid Year";
      }
      break;
      case "cuet_year":
      if (!/^[1-9]{1}[0-9]{3}$/.test(value)) {
        return "Invalid Year";
      }
      break;
    case "mfull_marks":
      if (!/^[1-9]\d{2,}$/.test(value)) {
        return "Full marks should be min 3 digits.";
      } else if (parseInt(value) < 500) {
        return "Full marks should be >= 500 ";
      } else if (parseInt(value) % 100) {
        return "Invalid Full Marks";
      }
      break;
    case "hfull_marks":
      if (!/^[1-9]\d{2,}$/.test(value)) {
        return "Full marks should be min 3 digits.";
      } else if (parseInt(value) < 400) {
        return "Full marks should be >= 400 ";
      } else if (parseInt(value) % 100) {
        return "Invalid Full Marks";
      }
      break;
    case "ufull_marks":
      if (!/^[1-9]\d{2,}$/.test(value)) {
        return "Full marks should be min 3 digits.";
      } else if (parseInt(value) < 700) {
        return "Full marks should be >= 700 ";
      } else if (parseInt(value) % 100) {
        return "Invalid Full Marks";
      }

      break;
      case "pfull_marks":
      if (!/^[1-9]\d{2,}$/.test(value)) {
        return "Full marks should be min 3 digits.";
      } 
      else if (parseInt(value) < 700) {
        return "Full marks should be >= 700 ";
      } else if (parseInt(value) % 100) {
        return "Invalid Full Marks";
      }
      break;
    case "mmarks_obtained":
      if (!/^[1-9]\d{2,}$/.test(value)) {
        return "Full marks should be min 3 digits.";
      }
      break;

    case "hmarks_obtained":
      if (!/^[1-9]\d{2,}$/.test(value)) {
        return "Full marks should be min 3 digits.";
      }
      break;
    case "umarks_obtained":
      if (!/^[1-9]\d{2,}$/.test(value)) {
        return "Invalid marks";
      }
      break;
      case "pmarks_obtained":
        if (!/^[1-9]\d{2,}$/.test(value)) {
          return "Invalid marks";
        }
        break;

    default:
      break;
  }

  // If no error is found, return null (no error message)
  return null;
};
