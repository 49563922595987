import React, { useEffect, useState } from "react";
import "./Purches.css";
import { apilink, path, removeSpecialCharacters } from "../../data/fdata";
import axios from "axios";
import { useAlert } from "react-alert";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";
import ReactPaginate from 'react-paginate';
import { CircularProgress } from "@mui/material";

const PaidBooks = () => {
  const [book_List, setBook_List] = useState([]);
  const [book_List_all, setBook_List_all] = useState([]);
  const [cat_filter, setCat_filter] = useState("");
  const [sem_filter, setSem_filter] = useState("");

  const [searchText,setSearchText]=useState("")

  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);


  let url = "https://edushark.in";
  let description = "Edushark";

  const [pageLoading, setPageLoading] = useState(false);

  const token = Cookies.get("_edushark_user_access_token");

  const alert = useAlert();
  const his = useHistory();



 
     const [pageNo, setPageNo] = useState(0);

  const perpage = 18;
  const pagevisit = pageNo * perpage;

  const dataall = book_List.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(book_List.length / perpage);

  

  const pageChange = ({ selected }) => {
    setPageLoading(true)
    setPageNo(selected);
    setTimeout(() => {
      setPageLoading(false)
    }, 3000);
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    
    
  }, [pageNo]);



  const getallbooks = async () => {
    setPageLoading(true);

    const res = await axios.get(`${apilink}/api/purchesbook/getallbooks`);
    // console.log(res.data)
    if (res.data.success) {
      let ar = res.data.result.filter((v) => v.book_isActive == true);
      setBook_List(ar);
      setBook_List_all(ar);
    } else {
      alert.error(res.data.msg);
    }
    setTimeout(() => {
      setPageLoading(false);
    }, 1500);
  };

  useEffect(() => {
    getallbooks();
  }, []);

  useEffect(() => {
    setPageLoading(true)
    if (cat_filter) {
      // setBook_List(book_List_all.filter((v)=>v.booksem == sem_filter && v.bookcategory==cat_filter))

      if (sem_filter) {
        setBook_List(
          book_List_all.filter(
            (v) => v.bookcategory == cat_filter && v.booksem == sem_filter
          )
        );
      } else {
        setBook_List(book_List_all.filter((v) => v.bookcategory == cat_filter));
      }
    } else {
      if (sem_filter) {
        setBook_List(book_List_all.filter((v) => v.booksem == sem_filter));
      }
    }

    setTimeout(() => {
      setPageLoading(false)
    }, 1500);
  }, [cat_filter, sem_filter]);

  const onSearch=async(e)=>{
    e.preventDefault()
    setLoading(true)

    const res=await axios.get(`${apilink}/api/purchesbook/searchBook/${searchText}`)
    // console.log(res.data)

    if(res.data.success)
    {
     
      setTimeout(() => {
        setPopup(false)
        setSearchText("")
        setBook_List(res.data.result);
        setBook_List_all(res.data.result);
        setLoading(false)
      }, 1500);
    }else{
    
      setTimeout(() => {
        setPopup(false)
        setSearchText("")
        alert.error(res.data.msg)
        setLoading(false)
      }, 1500);
    }

   



    

  }

  return (
    <>


{
    popup &&  <div className="model_box">
    <div className="inner_model_sobig">
      <div className="cross" onClick={()=>{
        setPopup(false)
        setSearchText("")

      }}>&times;</div>
      <div>
       <div className="text-center">
        <h5>Search Your Book</h5>
       
       </div>
       <form className="my-4 searchform" onSubmit={onSearch}>
      
       <div class="input-group mb-3">
    <input type="text" class="form-control" value={searchText} onChange={(e)=>setSearchText(e.target.value)} placeholder="Search Books by name , author , publisher" required />
    <div class="input-group-append">
      <button  className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading} type="submit">Search</button>  
     </div>
  </div>  
        </form>
        <div className="text-center">
        {loading && (
                    <div className="text-center p-2">
                      <CircularProgress  size={40} />
                    </div>
                  )}
        </div>
      </div>
    </div>
  </div>
   }

      <div className="section_page">
        <div className="container   ">
          <div className="row mt-5">
            <div className="col-lg-6 col-md-4 mb-3">
              
              <button
                onClick={() => {
                  window.open("https://class.edushark.in", "_blank")
                }}
                className="sml_btn bg-primary mr-1"
              >
                Find Classes
              </button>
              
            </div>
            <div className="col-lg-6 col-md-8 ">
              <div className="display_flex">
                <select
                  class="form-control  mr-1"
                  value={cat_filter}
                  onChange={(e) => setCat_filter(e.target.value)}
                >
                  <option selected hidden value="">
                    --Course--
                  </option>
                  <option value="BED">B.ed</option>
                  <option value="DELED">D.el.ed</option>
                  <option value="DPHARM">D.pharm</option>
                </select>
                <select
                  class="form-control  ml-1"
                  value={sem_filter}
                  onChange={(e) => setSem_filter(e.target.value)}
                >
                  <option selected hidden value="">
                    --Semester--
                  </option>
                  <option value="1st Semester">1st Semester</option>
                  <option value="2nd Semester">2nd Semester</option>
                  <option value="3rd Semester">3rd Semester</option>
                  <option value="4th Semester">4th Semester</option>
                </select>
                <button className="sml_btn bg-primary ml-1" onClick={()=>{
                  setCat_filter("")
                  setSem_filter("")
                  getallbooks()
                }}> <i className=" fa fa-refresh "></i></button>
                <button className="sml_btn bg-primary  ml-1" onClick={()=>{
                  setPopup(true)
                }}> <i className=" fa fa-search "></i></button>
              </div>
            </div>
          </div>

          {dataall?.length > 0 ? (
            <>
              <div className="row mt-5">
                {dataall?.map((val, ind) => {
                  return (
                    <>
                      <div className="col-lg-4 col-md-6 col-12 mb-3" key={ind}>
                        <div className="card book_card p-2">
                          <div className="book_card_abs cur">
                            <div className="dropdown">
                              <i className="fa fa-share-alt"></i>
                              <div class="dropdown-content">
                                <a
                                  href={`https://www.facebook.com/sharer/sharer.php?u=${`${path}/purchase-books/${val._id}/${removeSpecialCharacters(val.bookname)?.trim().toLowerCase().replace(/\s/g, "-")}`}`}
                                  target="_blank"
                                  className="text_fb"
                                >
                                  Share with Facebook
                                </a>

                                <a
                                  href={`https://twitter.com/intent/tweet?url=${`${path}/purchase-books/${val._id}/${removeSpecialCharacters(val.bookname)?.trim().toLowerCase().replace(/\s/g, "-")}`}&text=${encodeURI(
                                    description
                                  )}`}
                                  className="text_tw"
                                  target="_blank"
                                >
                                  Share with Twitter
                                </a>
                                <a
                                  href={`https://api.whatsapp.com/send/?text=${`${path}/purchase-books/${val._id}/${removeSpecialCharacters(val.bookname)?.trim().toLowerCase().replace(/\s/g, "-")}`}`}
                                  className="text_wh"
                                  target="_blank"
                                >
                                  Share with Whatsapp
                                </a>
                              </div>
                            </div>
                          </div>
                          {val.bookimage?.length && (
                            <img
                              src={val.bookimage[0]}
                              alt=""
                              className="book_img cur"
                              onClick={()=>his.push(`/purchase-books/${val._id}/${removeSpecialCharacters(val.bookname)?.trim().toLowerCase().replace(/\s/g, "-")}`)}
                            />
                          )}
                          <div className="p-2">
                            <p className="m-0">
                              <b>
                                {val.bookcategory} / {val.booksem}
                              </b>
                            </p>
                            
                            <h5 className="m-0 text-custom word_r">
                              {val.bookname}
                            </h5>
                            <p className="m-0 mt-2">
                              <span className="">Language:</span> {val.booklang}{" "}
                            </p>
                            <p className="m-0 mt-2">
                              <span className="">Publisher:</span> {val.bookpublisher}{" "}
                            </p>
                            <div className="dd_flex mt-2 ">
                              <p className="m-0">
                                <span className="">Price:</span> {val.bookprice}{" "}
                              </p>
                              <div className="">
                                <button
                                  className="sml_btn py-2 px-4 mr-1"
                                  data-toggle="modal"
                                  data-target={`#myModal${val._id}`}
                                >
                                  view
                                </button>

                                <div
                                  class="modal fade"
                                  id={`myModal${val._id}`}
                                >
                                  <div class="modal-dialog modal-lg modal-dialog-centered">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h4 class="modal-title">
                                          Book Details
                                        </h4>
                                        <button
                                          type="button"
                                          class="close"
                                          data-dismiss="modal"
                                        >
                                          &times;
                                        </button>
                                      </div>

                                      <div class="modal-body">
                                        <h5>
                                          <span className="text-custom">
                                            Name: &nbsp;
                                          </span>
                                          {val.bookname}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Author: &nbsp;
                                          </span>
                                          {val.bookauthor}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Category: &nbsp;
                                          </span>
                                          {val.bookcategory}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Semester or Year: &nbsp;
                                          </span>
                                          {val.booksem}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Publisher: &nbsp;
                                          </span>
                                          {val.bookpublisher}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Price: &nbsp;
                                          </span>
                                          {val.bookprice}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Number of Books: &nbsp;
                                          </span>
                                          {val.booknumber}{" "}
                                        </h5>
                                        <h5>
                                          <span className="text-custom">
                                            Number of Pages: &nbsp;
                                          </span>
                                          {val.bookpages}{" "}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <button
                                  className="sml-btn py-2 px-4 ml-1"
                                  onClick={() =>
                                    his.push(`/purchase-books/${val._id}/buynow`)
                                  }
                                >
                                  Buy Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="py-5 text-center">
                <h2>Coming Soon...</h2>
              </div>
            </>
          )}

<div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>
        </div>
      </div>

      {pageLoading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PaidBooks;
