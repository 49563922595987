import React, { useEffect, useState } from 'react'
import './Auth.css'
import { NavLink, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { useAlert } from 'react-alert';
import parse from 'html-react-parser';

const VerifyOtp = () => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const [popup, setPopup] = useState(false);
  const [vloading, setVLoading] = useState(false);
  const [aemail, setAEmail] = useState("");
  const [show, setShow] = useState(false);

  const [email_To, setEmail_To] = useState("");



    const [msg, setMsg] = useState("");
    const [msgStatus, setMsgStatus] = useState("");


   const [otp,setOtp]= useState("")

     const { uid } = useParams();
  const his = useHistory();
 const alert= useAlert()


   const onOtpSubmit=async(e)=>{
    e.preventDefault()
    setLoading(true);
    const data={
        userid:uid,otp
    }

    const res=await axios.post(`${apilink}/api/user/activateEmailByOtp`,data)
    // console.log(res.data)
    if (res.data.success) {
       
        setTimeout(() => {
            setStatus(false)
          setLoading(false);
          alert.success(res.data.msg);
        }, 1500);

        setTimeout(() => {
            his.push("/login")
          }, 2500);
        
      } else {
        if(res.data.msg=="CODE_ERROR")
        {
            setTimeout(() => {            
                setLoading(false);
                               
                setStatus(true)
              }, 2000);


        }else{
            setTimeout(() => {            
                setLoading(false);
                alert.error(res.data.msg);
              }, 2000);
        }
        
        
      }

   }



   const verifyAccount=async(e)=>{
    e.preventDefault();
    setVLoading(true)


    const res=await axios.post(`${apilink}/api/user/verifyOldAccount`,{email:aemail})
    // console.log(res.data)

    if(res.data.success)
    {
      setTimeout(() => {
        setVLoading(false)
        setAEmail("")
        setPopup(false)
        alert.success(res.data.msg)
      }, 2000);

    }else{
      setTimeout(() => {
        setVLoading(false)
        setAEmail("")
        setPopup(false)
        alert.error(res.data.msg)
      }, 2000);

    }


    

  }

  const verifyUid=async()=>{
    const res=await axios.get(`${apilink}/api/user/verifyUid/${uid}`)
    // console.log(res.data)
    if(!res.data.success)
    {
      his.push("/something-wrong")
    }else{
      setEmail_To(res.data.mail_to)
      if(res.data.msg=="ACTIVE")
      {
        setShow(true)
      }else{
        setShow(false)
      }
    }
  }

  useEffect(()=>{
if(uid)
{

    verifyUid()

}

  },[uid])

  return (
   <>
   {
    popup && (
        <>

<div className="model_box">
        <div className="inner_model_big">
         
          <div className="cross" onClick={()=>{
            setPopup(false)
            setAEmail("")
           
          }}>
            &times;
          </div>
          <div className="auth_box p-3">
                <h5 className="text-center">Verify your old account ?</h5>
                <br />
                {
            msgStatus > 0 && <div class="alertw alert alert-danger alert-dismissible my-3">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
           {msg}
          </div>
          }
                <form onSubmit={verifyAccount}>

                <input
                          type="email"
                          className="mb-3"
                          placeholder="Enter Old Account Email"
                          name="email"
                          value={aemail}
                          onChange={(e) => setAEmail(e.target.value.toLowerCase())}
                          required
                        />

                <div className="text-center mt-3">
                          <button
                            type="submit"
                            className={
                              vloading
                                ? "dis btn btn-primary"
                                : "btn btn-primary"
                            }
                            disabled={vloading}
                          >
                            Submit
                          </button>
                        </div>
                        {vloading && (
                          <div className="text-center p-2">
                            <CircularProgress size={40} />
                          </div>
                        )}
                </form>
              
              </div>

          </div>
          </div>
        </>
    )
   }
   <div className="otpbox">
        <div className="container mt-2">
            <div className="row">
                <div className="col-lg-6 col-md-8 col-12 mx-auto">
                    <img src="https://edushark.in/image/logo.png" alt="" className='optimg' />
                  {
                    show ?(
                      <>
                      <div className="text-center">
                        <h5>Your account has already been successfully verified.</h5>
                        <NavLink to="/" className="sm_btn">Go Back</NavLink>
                      </div>
                      </>
                    ):(
                      <>
                       <div className="text-center">
                    <h4>Verify Your Email Address</h4>
                    <p>In order to start your Edushark account, you need to confirm OTP</p>
                    </div>
                    <form className='otpform mt-4' onSubmit={onOtpSubmit}>
                    <div class="form-group">
                      <label >Find the OTP in your Email <span className='text-danger'>({email_To})</span> Inbox</label>
                    <input
                      type="text"
                      placeholder="Enter OTP"
                      class="form-control formh"
                      name="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                    />

                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading}
                  
                    >
                      Verify Account
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress size={45} />
                    </div>
                  )}
                  {
                    status &&  <><p className='mt-3 ' >Code has expired. <span className='cur' onClick={()=>{
                        setPopup(true)
                        setStatus(false)
                        setOtp("")
                    }}><u>Resend Verification Code</u></span> </p></>
                  }
                    </form>
                   
                      
                      </>
                    )
                  }
                </div>
            </div>
        </div>
   </div>
   
   </>
  )
}

export default VerifyOtp