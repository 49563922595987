import React from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';
import { path } from '../../data/fdata';
const Footer = () => {
  return (
    <>
      <footer>
      <div class="fot_content">
        <div class="top">
          <div class="logo-details">
            <span class="logo_name fb_700">EDUSHARK</span>
          </div>
          <div class="media-icons">
            <a
              href="https://m.facebook.com/people/Edushark/61553284709129"
              target="_blank"
            >
              <i class="fa fa-facebook"></i
            ></a>
            <a href="/" target="_blank"><i class="fa fa-twitter"></i></a>
            <a href="/" target="_blank"><i class="fa fa-instagram"></i></a>
            <a href="https://www.linkedin.com/company/iedushark" target="_blank"
              ><i class="fa fa-linkedin"></i
            ></a>
            <a href="https://www.youtube.com/@Iedushark" target="_blank"><i class="fa fa-youtube"></i></a>
          </div>
        </div>
        <div class="link-boxes">
          <ul class="box">
            <li class="link_name">Company</li>
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/contact">Contact us</NavLink></li>
            <li><NavLink to="/aboutus">About us</NavLink></li>
            <li><NavLink to="/gallery">Gallery</NavLink></li>
            <li><NavLink to="/shipping-policy">Shipping Policy</NavLink></li>
            <li><NavLink to="/return-policy">Return Policy</NavLink></li>
            <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
            <li><NavLink to="/terms-and-conditions">Terms & Conditions</NavLink></li>

            

          </ul>
          <ul class="box">
            <li class="link_name">Education</li>

            <li>
              <NavLink to="/courses">Courses</NavLink>
              
            </li>
            <li>
              <NavLink to="/colleges">Associate Colleges</NavLink>
              
            </li>
            <li>
              <NavLink to="/purches">Purches</NavLink>
              
            </li>
            
          </ul>

          <ul class="box">
            <li class="link_name">Students</li>
            <li>
            <NavLink to="/register?type=Student">Join as a Student</NavLink>
            </li>
            <li>
            <NavLink to="/register?type=Guest">Join as a Guest</NavLink>
            </li>
           

          </ul>
         
         
          <ul class="box ">
            <li>
              Email: <a href="mailto:support@edushark.in">support@edushark.in</a>
            </li>
            <li>Phone: <a href="tel:+919749491137">9749491137</a></li>
            <li>Address: Guripara,Azimganj, 742122, Murshidabad, West Bengal, India</li>
          </ul>
        </div>

        

        
      </div>
      <hr />
      <div className='text-center'>
        <p className='m-0 p-3'>All payments are secure with <img src={`${path}/image/download.png`} alt="" className='fot_img' /></p>
        
      </div>
      <p className='m-0 p-3 bg-blue text-center'>Copyright © {new Date().getFullYear()} Edushark. All Rights Reserved.</p>
    </footer>
    </>
  );
};

export default Footer;
