import React, { useEffect, useState } from "react";
import "./Auth.css";
import { apilink, path } from "../../data/fdata";
import { CircularProgress } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useAlert } from "react-alert";
import axios from "axios";
import validator from 'validator';

import GppBadIcon  from '@mui/icons-material/GppBad';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Cookies from "js-cookie";

const Register = () => {
  const alert = useAlert();
  const token = Cookies.get('_edushark_user_access_token');
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [eotp, setEOtp] = useState("");
  const [gotp, setGOtp] = useState("");
  const [gotpmsg, setGOtpmsg] = useState("");
  const [passwordVis, setPasswordVis] = useState("password");

  const [verify, setVerify] = useState(false);
  const [otpLoad, setOptLoad] = useState(false);

  const [msgg, setMsgg] = useState('');

  const [count, setCount] = useState(0);
  const [aemail, setAEmail] = useState("");

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);



  const [role, setRole] = useState("Student");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [oloading, setOLoading] = useState(false);
  const [vloading, setVLoading] = useState(false);
 

  const urltoken = useLocation().search;
  
  const type= new URLSearchParams(urltoken).get('type');

  const [popup, setPopup] = useState(false);
  const [infoPopup, setInfoPopup] = useState(false);


  const his = useHistory();

  useEffect(async() => {
    if(token) {
     
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(res.data.success)
          {
            alert.success("You are already logged in.")
            his.push("/student/dashboard")
          }
         
         
    }
  }, []);

  const onSub = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (verify) 
    {
      if(  validator.isStrongPassword(password, {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      }))
      {
        const data={
          name,
          email,
          phone , 
          role,
          password
        }
        const res=await axios.post(`${apilink}/api/user/register`,data)
        // console.log(res.data)
        if(res.data.success)
        {
          alert.success(res.data.msg)
          setName("")
          setEmail("")
          setPhone("")
          setRole("")
          setPassword("")
          setVerify(false);
          setPasswordVis("password")
          

          setTimeout(() => {
            his.push(`/verify-account/${res.data.userID}`)
          }, 2000);
  
  
        }else{
          alert.error(res.data.msg)
        }

      }else{
alert.error("Use strong password: 1 number, 1 uppercase, 1 lowercase, 1 special character")
      }
      

    }else{
      alert.error("please verify phone number");
    }

    setLoading(false);
  };

  useEffect(()=>{
    if(type)
    {
      setRole(type)
    }
  },[type])

  useEffect(() => {
    // Function to handle the countdown logic
    const interval = setInterval(() => {
      // Decrease seconds if greater than 0
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      // When seconds reach 0, decrease minutes if greater than 0
      if (seconds === 0) {
        if (minutes === 0) {
          // Stop the countdown when both minutes and seconds are 0
          clearInterval(interval);
        } else {
          // Reset seconds to 59 and decrease minutes by 1
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000); // Run this effect every 1000ms (1 second)

    return () => {
      // Cleanup: stop the interval when the component unmounts
      clearInterval(interval);
    };
  }, [seconds]); // Re-run this effect whenever 'seconds' changes

  // Function to resend OTP
 

  const handelverify=async()=>{

    
    if(phone.length ==10)
    {
      setPopup(true)
      setOptLoad(true)
      
      const res=await axios.post(`${apilink}/auth/opt-verify`,{
        phonenumber:phone
      })
      // console.log(phone)
  
      if(res.data.success)
      {
        setMinutes(1);
        setSeconds(59);
        
        setGOtp(res.data.otp)
        setGOtpmsg(res.data.msg)
  
      }else{
       
        setPopup(false)
          alert.error(res.data.msg)
       
        
      }
      setOptLoad(false)

    }else{
      alert.error("Enter Phone No. & number should be 10 digit")

    }
    

  }

  const oldverifyotp=(e)=>{
    e.preventDefault()
    setOLoading(true)
    if (eotp.trim().length == 0) {
      setTimeout(() => {
        setMsgg('Enter OTP');
        setOLoading(false)
      }, 2000);
    } else if (eotp != gotp) {
      setTimeout(() => {
        setMsgg('Wrong OTP');

        setOLoading(false)
      }, 2000);
    } else {
      
      setTimeout(() => {
        setMsgg('');
        setVerify(true);
        setPopup(false);
        setOLoading(false)
      }, 2000);
    }

  }

  const verifyotp=async(e)=>{
    e.preventDefault()
    setOLoading(true)
    if (eotp.trim().length == 0) {
      setTimeout(() => {
        setMsgg('Enter OTP');
        setOLoading(false)
      }, 2000);
    }  else {
      const res=await axios.post(`${apilink}/auth/opt-verify-api`,{
        phonenumber:phone,
        ver_id:gotp,
        otp_code:eotp.trim()
      })
      // console.log(res.data)
      if(res.data.success)
        {
           setTimeout(() => {
        setMsgg(res.data.msg);
        setVerify(true);
        setPopup(false);
        setOLoading(false)
      }, 2000);

        }else{

           setTimeout(() => {
        setMsgg(res.data.msg);
       
        setOLoading(false)
      }, 2000);

        }
     
     
    }

  }

  const verifyAccount=async(e)=>{
    e.preventDefault();
    setVLoading(true)


    const res=await axios.post(`${apilink}/api/user/verifyOldAccount`,{email:aemail})
    // console.log(res.data)

    if(res.data.success)
    {
      setTimeout(() => {
        setVLoading(false)
        setAEmail("")
        setInfoPopup(false)
        alert.success(res.data.msg)
        his.push(`/verify-account/${res.data.userID}`)
      }, 2000);
     

    }else{
      setTimeout(() => {
        setVLoading(false)
        setAEmail("")
        setInfoPopup(false)
        alert.error(res.data.msg)
      }, 2000);

    }


    

  }
  const resendOTP = () => {
    setMinutes(1);
    setSeconds(59);
    handelverify()
    setEOtp('')
    setMsgg('');
  };
  return (
    <>
      <div className="auth">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 col-sm-10 col-12 mx-auto">
              <div className="card p-2">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <img
                      src={`${path}/image/auth_o.jpg`}
                      alt=""
                      className="auth_img"
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="py-2 px-1 auth_box">
                      <h2 className="text-center text-col">Register Now</h2>

                      <form className="mt-4" onSubmit={onSub}>
                        <input
                          type="text"
                          className="mb-3"
                          placeholder="Enter Name"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value.toUpperCase())}
                          required
                        />

<div className="d-flex mb-3">
                        <input
                          type="number"
                         name="phone"
                          placeholder="Enter Phone No"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                       {
                        verify ?  <p title="Your phone number is verified" className="input_btn_green m-0" ><VerifiedUserIcon fontSize="small"  /></p> :  <p title="Please verify your phone number" className="input_btn m-0 " onClick={handelverify}>
                          {/* <GppBadIcon  fontSize="small"  /> */}
                          Verify
                          </p>
                       }
                        </div>
                        <p className="fn_12 re_p  m-0 mb-3">Click on right side verify button</p>
                        <input
                          type="email"
                          className="mb-3"
                          placeholder="Enter Email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value.toLowerCase())}
                          required
                        />
                        

                        <div className="d-flex mb-3">
                        <select
                          
                          onChange={(e) => setRole(e.target.value)}
                          value={role}
                          required
                        >
                          <option value="" hidden selected>
                            --Select Role--
                          </option>

                          <option value="Guest">As a Guest User</option>
                          <option value="Student">As a Student User </option>
                        </select>
                        <p title="Information about role" onClick={()=>{
                          setInfoPopup(true)
                           setCount(0)}} className="input_btn m-0"><InfoIcon fontSize="small" /></p>
                          </div>                        

                       
                        <div className="d-flex mb-3">
                        <input
                          type={passwordVis}
                          placeholder="Create Password"
                          value={password}
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        {
                          passwordVis=="password" ?  <p title="show password" className="input_btn m-0 " onClick={()=>setPasswordVis("text")}><VisibilityOffIcon fontSize="small" /></p>:  <p title="hide password" className="input_btn m-0" onClick={()=>setPasswordVis("password")}><VisibilityIcon fontSize="small" /></p>
                        }
                       
                        
                        </div>

                        <div className="flex_box mt-2">
                          <small
                            onClick={() => his.push("/login")}
                            className="m-0 text-custom cur re_p  "
                          >
                            Already have an Account?
                          </small>
                          <small
                           onClick={()=>{
                            setInfoPopup(true)
                            setCount(1)
                           }}
                            className="m-0 text-custom cur re_p  "
                          >
                            Verify Account?
                          </small>
                        </div>
                        <div className="text-center mt-3">
                          <button
                            type="submit"
                            className={
                              loading
                                ? "dis btn btn-primary"
                                : "btn btn-primary"
                            }
                            disabled={loading}
                          >
                            Submit
                          </button>
                        </div>
                        {loading && (
                          <div className="text-center p-2">
                            <CircularProgress size={40} />
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        popup && (
          <>
<div className="model_box">
{
 !otpLoad ? (
    <>
<div className="inner_model_big">
         { (seconds > 0 || minutes > 0) && <p className="text-success fn_14 text-center">{gotpmsg && gotpmsg}</p>} 
          {
            !(seconds > 0 || minutes > 0) &&  <div className="cross" onClick={()=>{
              setPopup(false)
              setGOtpmsg("")
              setMinutes(1);
          setSeconds(59);
            }}>
              &times;
            </div>
          }
          
          <form onSubmit={verifyotp}>
          <input
                          type="text"
                          className="otpinput"
                          placeholder="Enter Otp"
                          value={eotp}
                          onChange={(e) => setEOtp(e.target.value)}
                          required
                        />

<div className="countdown-text">
          {/* Display countdown timer if seconds or minutes are greater than 0 */}
          
          {seconds > 0 || minutes > 0 ? (
            <p>
              Time Remaining:{" "}
              <span style={{ fontWeight: 600 }}>
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </span>
            </p>
          ) : (
            // Display if countdown timer reaches 0
            <p>Didn't receive code?</p>
          )}

          {/* Button to resend OTP */}
          <button
            disabled={seconds > 0 || minutes > 0}
            style={{
              color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
            }}
            
            onClick={resendOTP}
          >
            Resend OTP
          </button>
        </div>

      <div className="text-center mt-3">
                          <button
                            type="submit"
                            className={
                              oloading
                                ? "dis btn btn-primary"
                                : "btn btn-primary"
                            }
                            disabled={oloading}
                          >
                            Verify
                          </button>
                        </div>
                        {oloading && (
                          <div className="text-center p-2">
                            <CircularProgress size={40} />
                          </div>
                        )}


          </form>
          {
            msgg.length > 0 && <div class="alertw alert alert-danger alert-dismissible my-3">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
           {msgg}
          </div>
          }
        </div>
    </>
  ):(
    <>
    <div className="inner_model_big text-center p-3">
      <CircularProgress size={35} />
      <p>Loading...</p>

    </div>
    
    </>
  )
}
        
      </div>

          </>
        )
      }

{
        infoPopup && (
          <>
<div className="model_box">
        <div className="inner_model_big">
         
          <div className="cross" onClick={()=>{
            setInfoPopup(false)
            setAEmail("")
           
          }}>
            &times;
          </div>
          {
            count ==0 && (
              <>
              <p className="fn_18 ">
           <i className="fa fa-info-circle text-custom"></i> &nbsp; As a Student User you can Enroll our courses (BED , DELED , DPHARM) , purches live courses and books.
          </p>
          <p className="fn_18 ">
           <i className="fa fa-info-circle text-custom"></i> &nbsp; As a Guest User you can only purches live courses and books. If you attempt to enroll in our courses (BED , DELED , DPHARM) as a Guest User, your role will automatically change to Student.
          </p>
              </>
            )
          }

   {
            count ==1 && (
              <>
              <div className="auth_box p-3">
                <h5 className="text-center">Verify your old account ?</h5>
                <br />

                <form onSubmit={verifyAccount}>

                <input
                          type="email"
                          className="mb-3"
                          placeholder="Enter Old Account Email"
                          name="email"
                          value={aemail}
                          onChange={(e) => setAEmail(e.target.value.toLowerCase())}
                          required
                        />

                <div className="text-center mt-3">
                          <button
                            type="submit"
                            className={
                              vloading
                                ? "dis btn btn-primary"
                                : "btn btn-primary"
                            }
                            disabled={vloading}
                          >
                            Submit
                          </button>
                        </div>
                        {vloading && (
                          <div className="text-center p-2">
                            <CircularProgress size={40} />
                          </div>
                        )}
                </form>
              
              </div>

              
              
              </>
            )
          }
          
        </div>
      </div>

          </>
        )
      }
    </>
  );
};

export default Register;
