import React, { useEffect, useState } from 'react'
import "../../Student.css";
import SideBar from "../../component/sidebar/SideBar";
import { useHistory , NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from 'js-cookie';
import { apilink } from "../../../../data/fdata";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import { useAlert } from 'react-alert';
import { saveAs } from 'file-saver';


const MyPayment = () => {
    const [pageloading, setPageloading] = useState(false);
    const token = Cookies.get('_edushark_user_access_token');
    const his = useHistory();
    const alert=useAlert()
    const [myPayment, setMyPayment] = useState([]);

    const [userData,setUserData]=useState({})

    // const [myData, setMyData] = useState([1,2]);
     const [pageNo, setPageNo] = useState(0);

  const perpage = 5;
  const pagevisit = pageNo * perpage;

  const dataall = myPayment.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myPayment.length / perpage);

  

  const pageChange = ({ selected }) => {
    setPageNo(selected);
  };


    useEffect(async() => {
        if(token) {
          setPageloading(true)
            const res=await axios.get(`${apilink}/api/user/authVerify`,{
                headers: {
                  Authorization: token,
                },
              })
            //   console.log(res.data)
              if(!res.data.success)
              {
                Cookies.remove('_edushark_user_access_token');
               localStorage.removeItem('_edushark_user_access_login');
              console.clear();
              window.location.href = '/login';
              }else{
               
              setUserData(res.data.userInfo)
                  
                
      
              }
              setTimeout(() => {
                setPageloading(false)
              }, 1500);
             
        }else{
          his.push("/login")
        }
      }, []);



      const getMyPaymentFees=async()=>{
        const res=await axios.get(`${apilink}/api/paymentfees/getMyPaymentFees`,{
          headers: {
            Authorization: token,
          },
        })
        
      
        if(res.data.success)
        {
          setMyPayment(res.data.result)
        }else{
          alert.error(res.data.msg)
        }
      }
      
      const generatepdf=async(pid,table)=>{
        setPageloading(true)

        const res=await axios.post(`${apilink}/api/pdf/generate-pdf`,{
          pid,
          table
        })
        // console.log(res.data)
        if(res.data.success)
        {
         alert.success(res.data.msg)
         const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${pid}`,{ responseType: 'blob' })
             if(ress)
             {
                 const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });
 
                   saveAs(pdfBlob, `invoice_pdf_${pid}.pdf`);
                   const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${pid}`)
                   if(!re.data.success)
                   {
                     his.push("something-wrong")
                   }
             }
 
        }else{
         alert.error(res.data.msg)
        }
        setPageloading(false)
      }

      useEffect(()=>{
       
        getMyPaymentFees()
      },[])

      const completeFeesPayment=async(feeid , feesamount)=>{
        setPageloading(true)
        const da={
          MUID: "MUID" + Date.now(),
        transactionId: 'T' + Date.now(),
        feeid,
        feesamount
        }
        const res=await axios.post(`${apilink}/api/paymentfees/completeFeesPayment`,da,{
          headers: {
            Authorization: token,
          },
        })
      
        if(res.data.success)
      {
        //  console.log(res.data.url)
       setTimeout(() => {
        setPageloading(false)
       }, 1500);
      
        setTimeout(() => {
          window.location.href=res.data.url
         }, 2000);
      
      }else{
        setTimeout(() => {
          setPageloading(false)
         }, 1500);
        
        
        alert.error(res.data.msg)
      
      }
      
      }

  return (
    <>

<div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="container-fluid mt-4">
          <div className="row">
            {
                userData?.isApproved ?(
                    <>
<div className="col-12">
                <div className="card p-2">
                  <div className="dd_flex">
                  <h5 className='text-custom'>My Payments</h5>
                 
                  </div>

                  {
                  dataall.length > 0 ?(
                    <>
                     <div class="table-responsive scroll_bar">
          <table class="table table-borderless dash scroll_bar_content">
            <thead>
              <tr>
                <th className="w_20">Id</th>
                
                <th className="w_20">Type</th>
                <th className="w_60">FeesType</th>
                <th className="w_20">FeesAmount</th>
                <th className="w_20">PayStatus</th>       
                <th className="sm_w">Status</th>
                <th className="sm_w">Operation</th>
                <th className="sm_w">Date</th>



               
              </tr>
            </thead>
            <tbody className=''>
              {
                dataall?.map((val,ind)=>{
                  return(
                    <>
                    <tr>
                <td><small>{val._id}</small></td>
                
                <td>{val.pay_type}</td>
                <td>{val.feestype}</td>

                <td>
                
                ₹ {Number(val.feesamount).toFixed(2)}</td>

                <td>{val.payment_status? "PAID" :"UNPAID"}</td>

                <td> 
                  {
                    val.payment_status ? (
                      <>
                       {val.paymentfee_status=="PENDING" &&  <span className='badge badge-warning'>Pending</span>}
                  {val.paymentfee_status=="APPROVED" &&  <span className='badge badge-success'>Approved</span>}
                  {val.paymentfee_status=="REJECTED" &&  <span className='badge badge-danger'>Rejected</span>}
                      
                      </>
                    ):(
                      <>
                      <p className='m-0 text-danger'>Failed</p>
                      
                      </>
                    )
                  }

               


                </td>
                <td>
               {
                val.payment_status ?  <span onClick={()=>generatepdf(val._id  , val.pay_type)} className='badge badge-light py-1 px-2 cur'> <i className='fa fa-download mr-1'></i> Receipt</span> :  <span onClick={()=>completeFeesPayment(val._id,val.feesamount)} className='badge badge-light py-1 px-2 cur'> Pay Now</span> 
               }

                </td>
                <td>
                      {new Date(val.createdAt).toLocaleDateString('en-GB')}
                    </td>
                
              </tr>
                    </>
                  )
                })
              }
             
            </tbody>
          </table>
        </div>
                    
                    </>
                  ):(
                    <>
                    <div className="py-4 text-center">
                      <h5>No Item Found</h5>
                    </div>
                    </>
                  )
                 }

        <div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>

                  </div>
                </div>
                    
                    </>
                ):(
                    <>
                    <div className="col-12">
 <div className="my-3 text-center">
                <div className="card p-3 rounded">
                <NavLink to="/courses"><h3>Find Our Degree Courses</h3></NavLink>
                </div>
              </div>
              </div>
                    
                    </>
                )
            }
               
          
              </div>

            
          </div>
          </div>
        </div>
      </div>
    {
  pageloading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
    </>

  )
}

export default MyPayment