import React, { useEffect, useState } from 'react'
import SideBar from '../sidebar/SideBar'
import { ToWords } from 'to-words';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from "js-cookie";
import { apilink , disableList, stateList, subjects} from '../../../../data/fdata';
import { validateInput } from '../../../../data/ErrorHandel';
import axios from 'axios';

const BedUpdateForm = ({sessionData, datalist , name , email ,phone}) => {
  const alert = useAlert();
  const token = Cookies.get('_edushark_user_access_token');
  const toWords = new ToWords();
// console.log(datalist)
  const history= useHistory()
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [invalidmarks, setInvalidmarks] = useState(false);
  const [preview, setPreview] = useState(false);
  const [errors, setErrors] = useState({});

  const [session, setSession] = useState('');

  const [have_bss_id, setHave_bss_id] = useState("");
  const [bss_id, setBss_id] = useState("");
  const [applicant_name, setApplicant_name] = useState("");
  const [father_name, setFather_name] = useState("");
  const [mother_name, setMother_name] = useState("");
  const [guardian_name, setGuardian_name] = useState("");
  const [relation_with_guardian, setRelation_with_guardian] = useState("");
  const [gender, setGender] = useState("");
  const [category, setCategory] = useState("");
  const [bloodGp, setBloodGp] = useState("");
  const [aadhar_no, setAadhar_no] = useState("");
  const [applicant_dob, setApplicant_dob] = useState("");
  const [languages, setLanguages] = useState("");
  const [nationality, setNationality] = useState("INDIAN");
  const [religion, setReligion] = useState("");
  const [marital_status, setMarital_status] = useState("");
  const [bpl, setBpl] = useState("");
  const [specially_abled, setSpecially_abled] = useState("");
  const [percentage_disable, setPercentage_disable] = useState("");
  const [type_diable, setType_diable] = useState("");
  const [income, setIncome] = useState("");

  const [applicant_phone, setApplicant_phone] = useState("");
  const [applicant_whatsappno, setApplicant_whatsappno] = useState("");
  const [applicant_email, setApplicant_email] = useState("");
  const [guardian_phone, setGuardian_phone] = useState("");
  
  const [paddress, setPAddress] = useState("");
  const [pblock, setPBlock] = useState("");
  const [pblockname, setPBlockname] = useState("");
  const [pstate, setPState] = useState("");
  const [pdistrictList, setPDistrictList] = useState([]);
  const [pcountry, setPCountry] = useState("INDIA");
  const [pdistrict, setPDistrict] = useState("");
  const [pps, setPPs] = useState("");
  const [ppo, setPPo] = useState("");
  const [ppin, setPPin] = useState("");
  const [same_permanent, setSame_permanent] = useState(false);
  const [caddress, setCAddress] = useState("");
  const [cblock, setCBlock] = useState("");
  const [cblockname, setCBlockname] = useState("");
  const [cstate, setCState] = useState("");
  const [cdistrictList, setCDistrictList] = useState([]);
  const [ccountry, setCCountry] = useState("INDIA");
  const [cdistrict, setCDistrict] = useState("");
  const [cps, setCPs] = useState("");
  const [cpo, setCPo] = useState("");
  const [cpin, setCPin] = useState("");

  const [lastUniversity, setLastUniversity] = useState("");
  const [regNo_lastu, setRegNo_lastu] = useState("");
  const [mboard, setMBoard] = useState("");
  const [myear, setMYear] = useState("");
  const [mfull_marks, setMFull_marks] = useState("");
  const [mmarks, setMmarks] = useState("");
  const [mpercentage_marks, setMPercentage_marks] = useState("");
  const [hboard, setHBoard] = useState("");
  const [hyear, setHYear] = useState("");
  const [hfull_marks, setHFull_marks] = useState("");
  const [hmarks, setHmarks] = useState("");
  const [hpercentage_marks, setHPercentage_marks] = useState("");

  const [status_ug, setStatus_ug] = useState("");
  const [type_ug, setType_ug] = useState("");
  const [university, setUniversity] = useState("");
  const [uyear, setUYear] = useState("");
  const [ufull_marks, setUFull_marks] = useState("");
  const [umarks, setUmarks] = useState("");
  const [upercentage_marks, setUPercentage_marks] = useState("");
  const [have_master, setHave_master] = useState("");
  const [status_pg, setStatus_pg] = useState("");
  const [type_pg, setType_pg] = useState("");
  const [puniversity, setPUniversity] = useState("");
  const [pyear, setPYear] = useState("");
  const [pfull_marks, setPFull_marks] = useState("");
  const [pmarks, setPmarks] = useState("");
  const [ppercentage_marks, setPPercentage_marks] = useState("");

  const [profileImg, setProfileImg] = useState("");
  const [signatureImg, setSignatureImg] = useState("");
  const [admit10thImg, setAdmit10thImg] = useState("");
  const [result10thImg, setResult10thImg] = useState("");
  const [result12thImg, setResult12thImg] = useState("");
  const [resultugImg, setResultugImg] = useState("");
  const [resultpgImg, setResultpgImg] = useState("");
  const [resultAadharImg, setResultAadharImg] = useState("");
  const [resultCastImg, setResultCastImg] = useState("");
  const [otherImg, setOtherImg] = useState("");
  const [disableImg, setDisableImg]= useState("");
  const [agreementCopy,setAgreementCopy]= useState("");

  const [choose_college, setChoose_college] = useState("");
  const [college_Course_Price, setCollege_Course_Price] = useState("");
  const [choose_subject, setChoose_subject] = useState("");

  const [count, setCount] = useState(1);
  const [apply_id, setApply_id] = useState("");



  const onSubmitApplication = async (e) =>{
    e.preventDefault();
    setPageLoading(true)
    
    const data = {

      apply_id,    
      have_bss_id,
      bss_id,
      
personalinfo:{
  applicant_name,
      father_name,
      mother_name,
      guardian_name,
      relation_with_guardian,
      gender,
      category,
      bloodGp,
      aadhar_no,
      applicant_dob,
      languages,
      nationality,
      religion,
      marital_status,
      bpl,
      specially_abled,
      percentage_disable,
      type_diable,
      income,
},
contactinfo:{
  applicant_phone,
  applicant_whatsappno,
  applicant_email,
  guardian_phone
},
addressinfo:{
  paddress,
  pblock,
  pblockname,
  pstate,
  pcountry,
  pdistrict,
  pps,
  ppo,
  ppin,
  same_permanent,
  caddress,
  cblock,
  cblockname,
  cstate,
  ccountry,
  cdistrict,
  cps,
  cpo,
  cpin
},
bed_form:{
  schoolinfo:{
    lastUniversity,
    regNo_lastu,
    mboard,
    myear,
    mfull_marks,
    mmarks,
    mpercentage_marks,
    hboard,
    hyear,
    hfull_marks,
    hmarks,
    hpercentage_marks,
  },
  collegeinfo:{
    status_ug,
    type_ug,
    university,
    uyear,
    ufull_marks,
    umarks,
    upercentage_marks,
    have_master,
    status_pg,
    type_pg,
    puniversity,
    pyear,
    pfull_marks,
    pmarks,
    ppercentage_marks,
  },
  choose_subject,
  choose_college,
 
}, 
documentinfo:{
  profileImg,
  signatureImg,
  admit10thImg,
  result10thImg,
  result12thImg,
  resultugImg,
  resultpgImg,
  resultAadharImg,
  resultCastImg,
  otherImg,
  disableImg,
},   

      agreementCopy:  `
      <div class="">
          <p class="m-0 mb-3">
           I am <span class="text-primary">${applicant_name}</span>, son
           or daughter of <span class="text-primary">${father_name}</span>, I want
            to take admission in Bachelor of education under Babasaheb
            Ambedkar University (Erstwhile The West Bengal University of
            Teachers' Training, Education Planning and Administration).
          </p>
          
          <p class="m-0 mb-3 ">
            
           
            I have chosen
            <span class="text-primary">${choose_college}</span> as my
            college and
            <span class="text-primary"> ${choose_subject}</span> for
            Bachelor of Education(B.Ed) in
            <span class="text-primary">${session}</span> academic year.
          
         
            I Understand that Total course fee for Bachelor of Education is
            <b class="text-primary"> Rs- ${college_Course_Price} (${ college_Course_Price && toWords.convert(Number(college_Course_Price)) }) </b> . This
            Course Fee Includes only Tuition Fee of a Bachelor of Education
            Student. This Course Fee is Excluding GST and Transaction Charge
            or Payment Gateway fee
            </p>
          <p class="m-0 mb-3">
      
            I also understand that This Course Fee is divided into five
            parts for the Convenience of the students.This 5 parts are
            namely - <span class="text-primary">(1) Admission Fee (2) 1st Semester Fee (3) 2nd Semester
            Fee (4) 3rd Semester Fee (5) 4th Semester Fee.</span> I will pay the
            fee for each Semester before the Semester Examination.
          </p>
          <p class="">
          
          <span class="text-primary"> I am Also Aware of That I have to pay</span> - one time Registration
            Fee,Four times Examination Fee (excluding supplementary
            examination fee),Fee for Dress, Fee for Identity card. <span class="text-primary"> Others
            Fees that I may have to pay </span> -Excursional Tour Charge (Optional),
            Observation Fee (Optional), hostel fee (optional), Library
            Charge (optional)
          </p>
      
          <p>
          
            I am <span class="text-primary">${applicant_name}</span> Son
            or Daughter of
            <span class="text-primary">${father_name}</span> residing at
            <span class="text-primary">${paddress},${ppo},${pps},${pdistrict},${pstate},${ppin}</span> do hereby
            solemnly affirm and declare as follows:
          </p>
          <ul class="pl-5">
            <li>
              I accept the fee structure mentioned above and undertake to
              pay all fees on time. Team Edushark and Baba Saheb Ambedkar
              Education University may take necessary action against me if I
              fail to pay the fee on time for any reason.
            </li>
            <li>
              
              I hereby declare that if I discontinue my education then
              Edushark shall not be liable in any way to refund me the fees
              paid by me till the time of discontinuance of my education.
            </li>
            <li>
              I also declare that all the statements made in the application
              form are true and correct to the best of my knowledge and
              belief, if any fraud and false details are found in my
              statement and my uploaded documents, the concerned authority
              reserves the right to cancel my studentship along with my
              application.
            </li>
          </ul>
         
          </div>
      
      
      `
    };
// console.log(data)

   
      
      
       
        try {
          const res=await axios.post(`${apilink}/api/application/reUpdateApplication`,data,{
            headers: {
              Authorization: token,
            },
          })

          setTimeout(() => {
            alert.success(res.data.msg)
          }, 2000);
          setTimeout(() => {
            history.push("/student/student-basic-details")
          }, 3000);
        } catch (error) {
          history.push("/something-wrong")
        }
     

    

    setTimeout(() => {
      setPageLoading(false)
     }, 2000);

  }


  const handelphoto = async (e) => {
    const { files } = e.target;
    setPageLoading(true);

    if (files.length > 0) {
      if (files[0].size > 200000) {
        alert.error(`File should be less then 200kb`);
      } else if (
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setProfileImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg`);
      }
    }
    setPageLoading(false);
  };

  const handelsignature = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 200000) {
        alert.error(`File should be less then 200kb`);
      } else if (
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setSignatureImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg`);
      }
    }
    setPageLoading(false);
  };

  const handel10thadmint = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setAdmit10thImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }
    setPageLoading(false);
  };
  const handel10thresult = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResult10thImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }
    setPageLoading(false);
  };
  const handel12thresult = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResult12thImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }
    setPageLoading(false);
  };

  const handelugresult = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (files[0].type === "application/pdf") {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResultugImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only pdf`);
      }
    }

    setPageLoading(false);
  };

  const handelpgresult = async (e) => {
    const { files } = e.target;
    setPageLoading(true);

    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (files[0].type === "application/pdf") {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResultpgImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only pdf`);
      }
    }

    setPageLoading(false);
  };

  const handelaadhar = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResultAadharImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }
    setPageLoading(false);
  };
  const handelcast = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResultCastImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }
    setPageLoading(false);
  };

  const handelotherdoc = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setOtherImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }

    setPageLoading(false);
  };

  const handelDisability= async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setDisableImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }

    setPageLoading(false);
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const errorMessage = validateInput(name, value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
    // console.log(errors)
  };

  useEffect(()=>{
if(datalist?._id)
{
  setApply_id(datalist._id)
 setHave_bss_id(datalist.have_bss_id)
    setBss_id(datalist.bss_id)
    setSession(datalist.session_year)
    setApplicant_name(datalist.personalinfo.applicant_name)
    setFather_name(datalist.personalinfo.father_name)
    setMother_name(datalist.personalinfo.mother_name)
    setGuardian_name(datalist.personalinfo.guardian_name)
    setRelation_with_guardian(datalist.personalinfo.relation_with_guardian)
    setGender(datalist.personalinfo.gender)
    setCategory(datalist.personalinfo.category)
    setBloodGp(datalist.personalinfo.bloodGp)
    setAadhar_no(datalist.personalinfo.aadhar_no)
    setApplicant_dob(datalist.personalinfo.applicant_dob)
    setLanguages(datalist.personalinfo.languages)
    setNationality(datalist.personalinfo.nationality)
    setReligion(datalist.personalinfo.religion)
    setMarital_status(datalist.personalinfo.marital_status)
    setBpl(datalist.personalinfo.bpl)
    setSpecially_abled(datalist.personalinfo.specially_abled)
    setPercentage_disable(datalist.personalinfo.percentage_disable)
    setType_diable(datalist.personalinfo.type_diable)
    setIncome(datalist.personalinfo.income)

    setApplicant_phone(datalist.contactinfo.applicant_phone)
    setApplicant_whatsappno(datalist.contactinfo.applicant_whatsappno)
    setApplicant_email(datalist.contactinfo.applicant_email)
    setGuardian_phone(datalist.contactinfo.applicant_phone)

    setPAddress(datalist.addressinfo.paddress)
    setPBlock(datalist.addressinfo.pblock)
    setPBlockname(datalist.addressinfo.pblockname)
    setPState(datalist.addressinfo.pstate)   
    setPCountry(datalist.addressinfo.pcountry)
    let ar = stateList.filter((v) => v.state == datalist.addressinfo.pstate);
    ar.length > 0  &&  setPDistrictList(ar[0].districts)
    setPDistrict(datalist.addressinfo.pdistrict)
    setPPs(datalist.addressinfo.pps)
    setPPo(datalist.addressinfo.ppo)
    setPPin(datalist.addressinfo.ppin)

    setCAddress(datalist.addressinfo.caddress)
    setCBlock(datalist.addressinfo.cblock)
    setCBlockname(datalist.addressinfo.cblockname)
    setCState(datalist.addressinfo.cstate)   
    setCCountry(datalist.addressinfo.ccountry)
    let ar1 = stateList.filter((v) => v.state == datalist.addressinfo.cstate);
    ar1.length > 0  &&  setCDistrictList(ar1[0].districts)
    setCDistrict(datalist.addressinfo.cdistrict)
    setCPs(datalist.addressinfo.cps)
    setCPo(datalist.addressinfo.cpo)
    setCPin(datalist.addressinfo.cpin)


    setLastUniversity(datalist.bed_form.schoolinfo.lastUniversity)
    setRegNo_lastu(datalist.bed_form.schoolinfo.regNo_lastu)

    setMBoard(datalist.bed_form.schoolinfo.mboard)
    setMYear(datalist.bed_form.schoolinfo.myear)
    setMFull_marks(datalist.bed_form.schoolinfo.mfull_marks)
    setMmarks(datalist.bed_form.schoolinfo.mmarks)
    setMPercentage_marks(datalist.bed_form.schoolinfo.mpercentage_marks)
    setHBoard(datalist.bed_form.schoolinfo.hboard)
    setHYear(datalist.bed_form.schoolinfo.hyear)
    setHFull_marks(datalist.bed_form.schoolinfo.hfull_marks)
    setHmarks(datalist.bed_form.schoolinfo.hmarks)
    setHPercentage_marks(datalist.bed_form.schoolinfo.hpercentage_marks)
    
    setStatus_ug(datalist.bed_form.collegeinfo.status_ug)
    setType_ug(datalist.bed_form.collegeinfo.type_ug)
    setUniversity(datalist.bed_form.collegeinfo.university)
    setUYear(datalist.bed_form.collegeinfo.uyear)
    setUFull_marks(datalist.bed_form.collegeinfo.ufull_marks)
    setUmarks(datalist.bed_form.collegeinfo.umarks)
    setUPercentage_marks(datalist.bed_form.collegeinfo.upercentage_marks)

    setHave_master(datalist.bed_form.collegeinfo.have_master)
    setStatus_pg(datalist.bed_form.collegeinfo.status_pg)
    setType_pg(datalist.bed_form.collegeinfo.type_pg)
    setPUniversity(datalist.bed_form.collegeinfo.puniversity)
    setPYear(datalist.bed_form.collegeinfo.pyear)
    setPFull_marks(datalist.bed_form.collegeinfo.pfull_marks)
    setPmarks(datalist.bed_form.collegeinfo.pmarks)
    setPPercentage_marks(datalist.bed_form.collegeinfo.ppercentage_marks)

    setChoose_college(datalist.bed_form.choose_college)
    setCollege_Course_Price(datalist.course_fees)
    setChoose_subject(datalist.bed_form.choose_subject)


    setProfileImg(datalist.documentinfo.profileImg)
    setSignatureImg(datalist.documentinfo.signatureImg)
    setAdmit10thImg(datalist.documentinfo.admit10thImg)
    setResult10thImg(datalist.documentinfo.result10thImg)
    setResult12thImg(datalist.documentinfo.result12thImg)
    setResultugImg(datalist.documentinfo.resultugImg)
    setResultpgImg(datalist.documentinfo.resultpgImg)
    setResultAadharImg(datalist.documentinfo.resultAadharImg)
    setResultCastImg(datalist.documentinfo.resultCastImg)
    setOtherImg(datalist.documentinfo.otherImg)
    setDisableImg(datalist.documentinfo.disableImg)


}   
   
   
  },[datalist])

  useEffect(() => {
    if (parseInt(mfull_marks) < parseInt(mmarks)) {
      setInvalidmarks(true);
    } else if (parseInt(hfull_marks) < parseInt(hmarks)) {
      setInvalidmarks(true);
    } else if (parseInt(ufull_marks) < parseInt(umarks)) {
      setInvalidmarks(true);
    } else if (parseInt(pfull_marks) < parseInt(pmarks)) {
      setInvalidmarks(true);
    } else setInvalidmarks(false);
  }, [
    mfull_marks,
    mmarks,
    hfull_marks,
    hmarks,
    ufull_marks,
    umarks,
    pfull_marks,
    pmarks,
  ]);


  const handelMarkCalculations=(full_m, mark , type_Exam)=>{
    if (type_Exam == "10TH") setMPercentage_marks(full_m.length ? (mark.length ? ((mark / full_m) * 100).toFixed(2) : 0.00) : 0.00);
    if (type_Exam == "12TH") setHPercentage_marks(full_m.length ? (mark.length ? ((mark / full_m) * 100).toFixed(2) : 0.00) : 0.00);
    if (type_Exam == "UG") setUPercentage_marks(full_m.length ? (mark.length ? ((mark / full_m) * 100).toFixed(2) : 0.00) : 0.00);
    if (type_Exam == "PG") setPPercentage_marks(full_m.length ? (mark.length ? ((mark / full_m) * 100).toFixed(2) : 0.00) : 0.00);


  }
  



  return (
    <>
    
    
    <div className="whole__page">
    <div className="left_side_navbar">
      <SideBar  />
    </div>
    <div className="main_div ">
      <div className="main_inner_div">
        <div className="form_edit_box">
        <h3 className="text-center pb-3 text-custom">
            Update Your B.ED Application Form 
          </h3>
          <form onSubmit={onSubmitApplication}>
          <div className="card">
              <div className="card-header">
                <p className="m-0"> Personal Details</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Do you have Banglar Shiksha Student ID</p>
                      <select
                        class="form-control"
                        value={have_bss_id}
                        onChange={(e) =>
                          setHave_bss_id(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Have Banglar Shiksha Student ID?
                        </option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>

                  {have_bss_id == "YES" && (
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                      <div class="form-group">
                        <p>Banglar Shiksha Student ID</p>
                        <input
                          type="text"
                          placeholder="Banglar Shiksha Student ID"
                          class="form-control"
                          name="bss_id"
                          required
                          value={bss_id}
                          onChange={(e) =>
                            setBss_id(e.target.value.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Name of the Applicant</p>
                      <p className="sm_label">
                        Name should be written as per Secondary admit card
                      </p>

                      <input
                        type="text"
                        placeholder="Applicant Name"
                        class="form-control"
                        name="applicant_name"
                        required
                        value={applicant_name}
                        onChange={(e) =>
                          setApplicant_name(e.target.value.toUpperCase())
                        }
                      />
                      {/* <small class="fn_12 text-danger">Name should be written as per Secondary admit card</small> */}
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Father's Name</p>
                      <p className="sm_label">
                        Name should be written as per Secondary admit card
                      </p>
                      <input
                        type="text"
                        placeholder="Father's Name"
                        class="form-control"
                        name="father_name"
                        required
                        value={father_name}
                        onChange={(e) =>
                          setFather_name(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Mother's Name</p>
                      <p className="sm_label">
                        Name should be written as per Secondary admit card
                      </p>
                      <input
                        type="text"
                        placeholder="Mother's Name"
                        class="form-control"
                        name="mother_name"
                        required
                        value={mother_name}
                        onChange={(e) =>
                          setMother_name(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Guardian's Name</p>
                      <input
                        type="text"
                        placeholder="Guardian's Name"
                        class="form-control"
                        name="guardian_name"
                        required
                        value={guardian_name}
                        onChange={(e) =>
                          setGuardian_name(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Relation with Guardian</p>
                      <select
                        class="form-control"
                        value={relation_with_guardian}
                        onChange={(e) =>
                          setRelation_with_guardian(
                            e.target.value.toUpperCase()
                          )
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Relation with Guardian
                        </option>
                        <option value="FATHER">FATHER</option>
                        <option value="MOTHER">MOTHER</option>
                        <option value="BROTHER">BROTHER</option>
                        <option value="SISTER">SISTER</option>
                        <option value="HUSBAND">HUSBAND</option>
                        <option value="UNCLE">UNCLE</option>
                        <option value="AUNTY">AUNTY</option>
                        <option value="GRANDFATHER">GRANDFATHER</option>
                        <option value="GRANDMOTHER">GRANDMOTHER</option>
                        <option value="OTHERS">OTHERS</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Gender</p>

                      <select
                        class="form-control"
                        value={gender}
                        onChange={(e) =>
                          setGender(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Gender
                        </option>
                        <option value="MALE">MALE</option>
                        <option value="FEMALE">FEMALE</option>
                        <option value="OTHERS">OTHERS</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p> Category</p>
                      <p className="sm_label">
                        If SC/ST/OBC , Upload Cast Certificate.
                      </p>
                      <select
                        class="form-control"
                        value={category}
                        onChange={(e) =>
                          setCategory(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Category
                        </option>

                        <option value="SC">SC</option>
                        <option value="ST">ST</option>
                        <option value="OBC">OBC</option>
                        <option value="GENERAL/EWS">GENERAL/EWS</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Date of Birth</p>
                      <p className="sm_label">
                        DOB should be written as per Secondary admit card
                      </p>
                      <input
                        type="text"
                        placeholder="Date of Birth"
                        class="form-control"
                        name="dob"
                        required
                        value={applicant_dob}
                        onChange={(e) =>
                          setApplicant_dob(e.target.value.toUpperCase())
                        }
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                      />
                    </div>
                  </div>
                 

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Aadhar card</p>
                      <input
                        type="number"
                        placeholder="Aadhar card"
                        class="form-control"
                        name="aadhar_no"
                        value={aadhar_no}
                        onChange={(e) => {
                          setAadhar_no(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                        required
                      />
                      <small class="fn_12 text-danger">
                        {errors?.aadhar_no && errors.aadhar_no}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p> Blood Group</p>
                      <select
                        class="form-control"
                        value={bloodGp}
                        onChange={(e) =>
                          setBloodGp(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Blood Group
                        </option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O+">O-</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Known Languages (ex: English,Bengali ..)</p>
                      <input
                        type="text"
                        placeholder="Known Languages (ex: English,Bengali ..)"
                        class="form-control"
                        name="languages"
                        required
                        value={languages}
                        onChange={(e) =>
                          setLanguages(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Nationality</p>
                      <input
                        type="text"
                        placeholder="Nationality"
                        class="form-control"
                        name="nationality"
                        required
                        value={nationality}
                        onChange={(e) =>
                          setNationality(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Religion</p>
                      <select
                        class="form-control"
                        name="religion"
                        value={religion}
                        onChange={(e) =>
                          setReligion(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Religion
                        </option>
                        <option value="HINDUISM">HINDUISM</option>
                        <option value="ISLAM">ISLAM</option>
                        <option value="CHRISTIANITY">CHRISTIANITY</option>
                        <option value="SIKHISM">SIKHISM</option>
                        <option value="BUDDHISM">BUDDHISM</option>
                        <option value="JAINISM">JAINISM</option>
                        <option value="JUDAISM">JUDAISM</option>
                        <option value="OTHERS">OTHERS</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Marital Status</p>
                      <select
                        class="form-control"
                        value={marital_status}
                        onChange={(e) =>
                          setMarital_status(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Marital Status
                        </option>
                        <option value="SINGLE">SINGLE</option>
                        <option value="MARRIED">MARRIED</option>
                        <option value="WIDOWED">WIDOWED</option>
                        <option value="DIVORCED">DIVORCED</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Whether BPL</p>
                      <select
                        class="form-control"
                        value={bpl}
                        onChange={(e) => setBpl(e.target.value.toUpperCase())}
                        required
                      >
                        <option value="" selected hidden>
                          Whether BPL
                        </option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Annual Family Income in Rs</p>
                      <input
                        type="text"
                        placeholder="Annual Income in Rs"
                        class="form-control"
                        name="income"
                        required
                        value={income}
                        onChange={(e) => {
                          handleInputChange(e);
                          setIncome(e.target.value);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.income && errors.income}
                      </small>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Whether Student Is Specially Abled</p>
                      <select
                        class="form-control"
                        value={specially_abled}
                        onChange={(e) => setSpecially_abled(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Student Is Specially Abled
                        </option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>

                  {specially_abled == "YES" && (
                    <>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>% of Disability</p>
                          <select
                            class="form-control"
                            value={percentage_disable}
                            onChange={(e) =>
                              setPercentage_disable(e.target.value)
                            }
                            required
                          >
                            <option value="" selected hidden>
                              % of Disability
                            </option>
                            <option value="20%">20%</option>
                            <option value="40%">40%</option>
                            <option value="60%">60%</option>
                            <option value="80%">80%</option>
                            <option value="100%">100%</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p> Type of Disability</p>
                          <select
                            class="form-control"
                            value={type_diable}
                            onChange={(e) => setType_diable(e.target.value)}
                            required
                          >
                            <option value="" selected hidden>
                              Type of Disability
                            </option>
                            {disableList?.map((v, i) => {
                              return (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            

            <div className="card mt-4">
              <div className="card-header">
                <p className="m-0"> Contacts and Address</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>Contacts</b>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Applicant Phone No</p>
                      <input
                        type="number"
                        placeholder="Applicant Phone No"
                        class="form-control"
                        name="applicant_phone"
                        required
                        value={applicant_phone}
                        onChange={(e) =>
                          setApplicant_phone(e.target.value.toUpperCase())
                        }
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Applicant whats app No</p>
                      <input
                        type="tel"
                        placeholder="Applicant whatsapp No"
                        class="form-control"
                        name="applicant_whatsappno"
                        maxLength={10}
                        required
                        value={applicant_whatsappno}
                        onChange={(e) => {
                          setApplicant_whatsappno(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.applicant_whatsappno &&
                          errors.applicant_whatsappno}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Applicant Email id:</p>
                      <input
                        type="email"
                        placeholder="Applicant email"
                        class="form-control"
                        name="applicant_email"
                        required
                        value={applicant_email}
                        onChange={(e) =>
                          setApplicant_email(e.target.value.toUpperCase())
                        }
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Guardian's Number</p>
                      <input
                        type="tel"
                        placeholder="Guardian phone"
                        class="form-control"
                        name="guardian_phone"
                        maxLength={10}
                        required
                        value={guardian_phone}
                        onChange={(e) => {
                          setGuardian_phone(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.guardian_phone && errors.guardian_phone}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>Permanent Address</b>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Address Line</p>
                      <input
                        type="text"
                        placeholder="Permanent Address"
                        class="form-control"
                        name="paddress"
                        required
                        value={paddress}
                        onChange={(e) =>
                          setPAddress(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Municipal Corporation/ Block</p>
                      <select
                        class="form-control"
                        value={pblock}
                        onChange={(e) =>
                          setPBlock(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Municipal Corporation/ Block
                        </option>
                        <option value="MUNICIPAL">MUNICIPAL</option>
                        <option value="MUNICIPAL CORPORATION">
                          MUNICIPAL CORPORATION
                        </option>
                        <option value="BLOCK">BLOCK</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Municipal Corporation/ Block Name</p>
                      <input
                        type="text"
                        placeholder="Municipal Corporation/ Block Name"
                        class="form-control"
                        name="pblockname"
                        required
                        value={pblockname}
                        onChange={(e) =>
                          setPBlockname(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent Country</p>
                      <input
                        type="text"
                        placeholder="Permanent Country"
                        class="form-control"
                        name="pcountry"
                        required
                        value={pcountry}
                        onChange={(e) =>
                          setPCountry(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent State</p>
                      <select
                        class="form-control"
                        name="pstate"
                        value={pstate}
                        onChange={(e) => {
                          setPState(e.target.value);
                          let ar = stateList.filter(
                            (v) => v.state == e.target.value
                          );
                          setPDistrictList(ar[0]?.districts);
                        }}
                        required
                      >
                        <option value="" selected hidden>
                          Permanent State
                        </option>

                        {stateList.map((v) => {
                          return <option value={v.state}>{v.state}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent District</p>
                      <select
                        class="form-control"
                        name="pdistrict"
                        value={pdistrict}
                        onChange={(e) => setPDistrict(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Permanent District
                        </option>

                        {pdistrictList?.map((v) => {
                          return <option value={v}>{v}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent PS</p>
                      <input
                        type="text"
                        placeholder="Permanent P.S."
                        class="form-control"
                        name="pps"
                        required
                        value={pps}
                        onChange={(e) => setPPs(e.target.value.toUpperCase())}
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent PO</p>
                      <input
                        type="text"
                        placeholder="Permanent P.O."
                        class="form-control"
                        name="ppo"
                        required
                        value={ppo}
                        onChange={(e) => setPPo(e.target.value.toUpperCase())}
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent Pin</p>
                      <input
                        type="text"
                        placeholder="Permanent Pin"
                        class="form-control"
                        name="ppin"
                        required
                        value={ppin}
                        onChange={(e) => {
                          setPPin(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.ppin && errors.ppin}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>Present address</b>
                    </p>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          defaultChecked={same_permanent}
                          onChange={() => setSame_permanent((state) => !state)}
                        />
                        Same as Permanent
                      </label>
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Address Line </p>
                      <input
                        type="text"
                        placeholder="Present Address"
                        class="form-control"
                        name="caddress"
                        required
                        value={caddress}
                        onChange={(e) => setCAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Municipal Corporation/ Block</p>
                      <select
                        class="form-control"
                        value={cblock}
                        onChange={(e) => setCBlock(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Municipal Corporation/ Block
                        </option>
                        <option value="MUNICIPAL">MUNICIPAL</option>
                        <option value="MUNICIPAL CORPORATION">
                          MUNICIPAL CORPORATION
                        </option>
                        <option value="BLOCK">BLOCK</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Municipal Corporation/ Block Name</p>
                      <input
                        type="text"
                        placeholder="Municipal Corporation/ Block Name"
                        class="form-control"
                        name="cblockname"
                        required
                        value={cblockname}
                        onChange={(e) =>
                          setCBlockname(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present Country</p>
                      <input
                        type="text"
                        placeholder="Present Country"
                        class="form-control"
                        name="ccountry"
                        required
                        value={ccountry}
                        onChange={(e) =>
                          setCCountry(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present State</p>
                      <select
                        class="form-control"
                        name="cstate"
                        value={cstate}
                        onChange={(e) => {
                          setCState(e.target.value);
                          let ar = stateList.filter(
                            (v) => v.state == e.target.value
                          );
                          setCDistrictList(ar[0]?.districts);
                        }}
                        required
                      >
                        <option value="" selected hidden>
                          Present State
                        </option>

                        {stateList.map((v) => {
                          return <option value={v.state}>{v.state}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present District</p>
                      <select
                        class="form-control"
                        name="cdistrict"
                        value={cdistrict}
                        onChange={(e) => setCDistrict(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Present District
                        </option>

                        {cdistrictList?.map((v) => {
                          return <option value={v}>{v}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present PS</p>
                      <input
                        type="text"
                        placeholder="Present P.S."
                        class="form-control"
                        name="cps"
                        required
                        value={cps}
                        onChange={(e) => setCPs(e.target.value.toUpperCase())}
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present PO</p>
                      <input
                        type="text"
                        placeholder="Present P.O."
                        class="form-control"
                        name="cpo"
                        required
                        value={cpo}
                        onChange={(e) => setCPo(e.target.value.toUpperCase())}
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present Pin-</p>
                      <input
                        type="text"
                        placeholder="Present Pin"
                        class="form-control"
                        name="cpin"
                        required
                        value={cpin}
                        onChange={(e) => {
                          setCPin(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.cpin && errors.cpin}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-4">
              <div className="card-header">
                <p className="m-0"> Academics and Qualifications</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>University Last Attented:</p>
                      <input
                        type="text"
                        placeholder="Last University Name"
                        class="form-control"
                        name="lastUniversity"
                        required
                        value={lastUniversity}
                        onChange={(e) =>
                          setLastUniversity(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Registration No of University Last Attended</p>
                      <input
                        type="text"
                        placeholder="Registration No"
                        class="form-control"
                        name="regNo_lastu"
                        required
                        value={regNo_lastu}
                        onChange={(e) => {
                          setRegNo_lastu(e.target.value.toUpperCase());
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>Secondary or Madhyamik exam</b>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Board</p>
                      <input
                        type="text"
                        placeholder="Board"
                        class="form-control"
                        name="board"
                        required
                        value={mboard}
                        onChange={(e) =>
                          setMBoard(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Year</p>
                      <input
                        type="tel"
                        placeholder="Year"
                        class="form-control"
                        name="myear"
                        maxLength={4}
                        required
                        value={myear}
                        onChange={(e) => {
                          setMYear(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.myear && errors.myear}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Full marks of exam</p>
                      <input
                        type="text"
                        placeholder="Full marks of exam "
                        class="form-control"
                        name="mfull_marks"
                        required
                        value={mfull_marks}
                        onChange={(e) => {
                          setMFull_marks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(e.target.value,mmarks, "10TH");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.mfull_marks && errors.mfull_marks}
                      </small>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Marks obtained</p>
                      <input
                        type="text"
                        placeholder="Marks obtained"
                        class="form-control"
                        name="mmarks_obtained"
                        required
                        value={mmarks}
                        disabled={mfull_marks.length < 3 || mfull_marks < 600}
                        onChange={(e) => {
                          setMmarks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(mfull_marks,e.target.value, "10TH");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.mmarks_obtained && errors.mmarks_obtained}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>% of marks</p>
                      <input
                        type="text"
                        placeholder="% of marks"
                        class="form-control"
                        name="percentage_marks"
                        required
                        value={mpercentage_marks}
                        onChange={(e) => setMPercentage_marks(e.target.value)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>Higher Secondary or Equivalent exam</b>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Board</p>
                      <input
                        type="text"
                        placeholder="Board"
                        class="form-control"
                        name="board"
                        required
                        value={hboard}
                        onChange={(e) =>
                          setHBoard(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Year</p>
                      <input
                        type="tel"
                        placeholder="Year"
                        class="form-control"
                        name="hyear"
                        maxLength={4}
                        required
                        value={hyear}
                        onChange={(e) => {
                          setHYear(e.target.value);
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.hyear && errors.hyear}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Full marks of exam</p>
                      <input
                        type="text"
                        placeholder="Full marks of exam "
                        class="form-control"
                        name="hfull_marks"
                        required
                        value={hfull_marks}
                        onChange={(e) => {
                          setHFull_marks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(e.target.value,hmarks, "12TH");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.hfull_marks && errors.hfull_marks}
                      </small>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Marks obtained</p>
                      <input
                        type="text"
                        placeholder="Marks obtained"
                        class="form-control"
                        name="hmarks_obtained"
                        required
                        value={hmarks}
                        disabled={hfull_marks.length < 3 || hfull_marks < 400}
                        onChange={(e) => {
                          setHmarks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(hfull_marks,e.target.value, "12TH");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.hmarks_obtained && errors.hmarks_obtained}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>% of marks</p>
                      <input
                        type="text"
                        placeholder="% of marks"
                        class="form-control"
                        name="percentage_marks"
                        required
                        value={hpercentage_marks}
                        onChange={(e) => setHPercentage_marks(e.target.value)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>B.A/B.Sc/B.com/B.E/B.tech</b>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p> Status of Under Graduation</p>
                      <select
                        class="form-control"
                        value={status_ug}
                        onChange={(e) => setStatus_ug(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Status of Under Graduation
                        </option>
                        <option value="COMPLETE">COMPLETE</option>
                        <option value="APPEARING">APPEARING</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Type of Graduation</p>
                      <select
                        class="form-control"
                        value={type_ug}
                        onChange={(e) => setType_ug(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Type of Graduation
                        </option>
                        <option value="HONOURS">HONOURS </option>
                        <option value="GENERAL/PASS">GENERAL/PASS</option>
                        <option value="ENGINEERING AND TECHNOLOGY">
                          ENGINEERING AND TECHNOLOGY
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                {status_ug == "APPEARING" && (
                  <>
                    {" "}
                    <p className="m-0 text-danger fn_14">
                      We need your Complete marksheet by 31st July
                    </p>{" "}
                    <p className="m-0 mb-3 text-danger fn_14">
                      Fill Marks upto current Semester
                    </p>{" "}
                  </>
                )}

                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>University</p>
                      <input
                        type="text"
                        placeholder="University"
                        class="form-control"
                        name="University"
                        required
                        value={university}
                        onChange={(e) =>
                          setUniversity(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Year</p>
                      <input
                        type="tel"
                        placeholder="Year"
                        class="form-control"
                        name="uyear"
                        maxLength={4}
                        required
                        value={uyear}
                        onChange={(e) => {
                          setUYear(e.target.value);
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.uyear && errors.uyear}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Full marks of exam</p>
                      <input
                        type="text"
                        placeholder="Full marks of exam "
                        class="form-control"
                        name="ufull_marks"
                        required
                        value={ufull_marks}
                        onChange={(e) => {
                          setUFull_marks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(e.target.value,umarks, "UG");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.ufull_marks && errors.ufull_marks}
                      </small>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Marks obtained</p>
                      <input
                        type="text"
                        placeholder="Marks obtained"
                        class="form-control"
                        name="umarks_obtained"
                        required
                        value={umarks}
                        disabled={ufull_marks.length < 3}
                        onChange={(e) => {
                          setUmarks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(ufull_marks,e.target.value, "UG");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.umarks_obtained && errors.umarks_obtained}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>% of marks</p>
                      <input
                        type="text"
                        placeholder="% of marks"
                        class="form-control"
                        name="percentage_marks"
                        required
                        value={upercentage_marks}
                        onChange={(e) => setUPercentage_marks(e.target.value)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>M.A/M.Sc/M.com/M.E/M.tech</b>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p> Do you have a Master Degree?</p>
                      <select
                        class="form-control"
                        value={have_master}
                        onChange={(e) => setHave_master(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Do you have a Master Degree
                        </option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {have_master == "YES" && (
                    <>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p> Status of Post Graduation</p>
                          <select
                            class="form-control"
                            value={status_pg}
                            onChange={(e) => setStatus_pg(e.target.value)}
                            required
                          >
                            <option value="" selected hidden>
                              Status of Post Graduation
                            </option>
                            <option value="COMPLETE">COMPLETE</option>
                            <option value="APPEARING">APPEARING</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Type of Graduation</p>
                          <select
                            class="form-control"
                            value={type_pg}
                            onChange={(e) => setType_pg(e.target.value)}
                            required
                          >
                            <option value="" selected hidden>
                              Type of Graduation
                            </option>
                            <option value="HONOURS">HONOURS </option>
                            <option value="GENERAL/PASS">GENERAL/PASS</option>
                            <option value="ENGINEERING AND TECHNOLOGY">
                              ENGINEERING AND TECHNOLOGY
                            </option>
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                </div>


                {have_master == "YES" && (
                  <>
                                  {status_pg == "APPEARING" && (
                  <>
                    {" "}
                    <p className="m-0 text-danger fn_14">
                      We need your Complete marksheet by 31st July
                    </p>{" "}
                    <p className="m-0 mb-3 text-danger fn_14">
                      Fill Marks upto current Semester
                    </p>{" "}
                  </>
                )}
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>University</p>
                          <input
                            type="text"
                            placeholder="University"
                            class="form-control"
                            name="University"
                            required
                            value={puniversity}
                            onChange={(e) =>
                              setPUniversity(e.target.value.toUpperCase())
                            }
                          />
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Year</p>
                          <input
                            type="tel"
                            placeholder="Year"
                            class="form-control"
                            name="pyear"
                            maxLength={4}
                            required
                            value={pyear}
                            onChange={(e) => {
                              setPYear(e.target.value);
                              handleInputChange(e);
                            }}
                          />
                          <small class="fn_12 text-danger">
                            {errors?.pyear && errors.pyear}
                          </small>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Full marks of exam</p>
                          <input
                            type="text"
                            placeholder="Full marks of exam "
                            class="form-control"
                            name="pfull_marks"
                            required
                            value={pfull_marks}
                            onChange={(e) => {
                              setPFull_marks(e.target.value);
                              handleInputChange(e);
                              handelMarkCalculations(e.target.value,pmarks, "PG");
                            }}
                          />
                          <small class="fn_12 text-danger">
                            {errors?.pfull_marks && errors.pfull_marks}
                          </small>
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Marks obtained</p>
                          <input
                            type="text"
                            placeholder="Marks obtained"
                            class="form-control"
                            name="pmarks_obtained"
                            required
                            value={pmarks}
                            disabled={pfull_marks.length < 3}
                            onChange={(e) => {
                              setPmarks(e.target.value);
                              handleInputChange(e);
                              handelMarkCalculations(pfull_marks,e.target.value, "PG");
                            }}
                          />
                          <small class="fn_12 text-danger">
                            {errors?.pmarks_obtained && errors.pmarks_obtained}
                          </small>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>% of marks</p>
                          <input
                            type="text"
                            placeholder="% of marks"
                            class="form-control"
                            name="percentage_marks"
                            required
                            value={ppercentage_marks}
                            onChange={(e) =>
                              setPPercentage_marks(e.target.value)
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="card mt-4">
                  <div className="card-header">
                    <p className="m-0"> Upload Documents</p>
                  </div>
                  <div className="card-body">
                    <div className="row">
                     



                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload Profile Photo &nbsp; {profileImg && <a target="_blank" href={profileImg} className='text-success'>View <i class="fa fa-external-link"></i></a>}</p>
                          <p className="sm_label">File size max 200KB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            accept=".png, .jpeg , .jpg "
                            // required
                            onChange={handelphoto}
                          />
                          
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload Signature &nbsp; {signatureImg && <a target="_blank" href={signatureImg} className='text-success'>View <i class="fa fa-external-link"></i></a>}</p>
                          <p className="sm_label">File size max 200KB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            accept=".png, .jpeg , .jpg "
                            // required
                            onChange={handelsignature}
                          />
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload 10th Admit &nbsp; {admit10thImg && <a target="_blank" href={admit10thImg} className='text-success'>View <i class="fa fa-external-link"></i></a>}</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            accept=".png, .jpeg , .jpg ,.pdf "
                            // required
                            onChange={handel10thadmint}
                          />
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload 10th Result &nbsp; {result10thImg && <a target="_blank" href={result10thImg} className='text-success'>View <i class="fa fa-external-link"></i></a>}</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            // required
                            accept=".png, .jpeg , .jpg , .pdf"
                            onChange={handel10thresult}
                          />
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload 12th Result &nbsp; {result12thImg && <a target="_blank" href={result12thImg} className='text-success'>View <i class="fa fa-external-link"></i></a>}</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            // required
                            accept=".png, .jpeg , .jpg, .pdf "
                            onChange={handel12thresult}
                          />
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload all Under Graduate Results &nbsp; {resultugImg && <a target="_blank" href={resultugImg} className='text-success'>View <i class="fa fa-external-link"></i></a>}</p>
                          <p className="sm_label">Upload Pdf only (Max 5MB)</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            // required
                            accept=".pdf"
                            onChange={handelugresult}
                          />
                        </div>
                      </div>
                      {have_master == "YES" && (
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                          <div class="form-group">
                            <p>Upload all Post Graduate Results &nbsp; {resultpgImg &&  <a target="_blank" href={resultpgImg} className='text-success'>View <i class="fa fa-external-link"></i></a> } </p>
                            <p className="sm_label">Upload Pdf only (Max 5MB)</p>
                            <input
                              type="file"
                              class="form-control"
                              name="file"
                              accept=".pdf"
                              onChange={handelpgresult}
                              // required
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload Aadhar Card &nbsp; {resultAadharImg && <a target="_blank" href={resultAadharImg} className='text-success'>View <i class="fa fa-external-link"></i></a>}</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            // required
                            accept=".png, .jpeg , .jpg , .pdf"
                            onChange={handelaadhar}
                          />
                        </div>
                      </div>
                      {(category == "SC" || category == "ST" || category =="OBC") && (
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                          <div class="form-group">
                            <p>Upload Caste Certificate &nbsp; {resultCastImg && <a target="_blank" href={resultCastImg} className='text-success'>View <i class="fa fa-external-link"></i></a>}</p>
                            <p className="sm_label">File size max 5MB</p>
                            <input
                              type="file"
                              class="form-control"
                              name="file"
                              accept=".png, .jpeg , .jpg ,.pdf "
                              onChange={handelcast}
                              required={resultCastImg.length > 0 ? false : true}
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload Other Certificate &nbsp; {otherImg &&  <a target="_blank" href={otherImg} className='text-success'>View <i class="fa fa-external-link"></i></a>}</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            accept=".png, .jpeg , .jpg , .pdf "
                            onChange={handelotherdoc}
                          />
                        </div>
                      </div>
                      {
                        specially_abled=="YES" &&  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload Disability Certificate &nbsp; {disableImg && <a target="_blank" href={disableImg} className='text-success'>View <i class="fa fa-external-link"></i></a>}</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            accept=".png, .jpeg , .jpg , .pdf "
                            onChange={handelDisability}
                            // required
                            required={disableImg.length > 0 ? false : true}
                          />
                        </div>
                      </div>
                      }
                     
                    </div>
                  </div>
                </div>
                {invalidmarks ? (
                  <>
                    <div className="card p-3 mt-3 text-center text-danger">
                      <p className="m-0">Invalid Marks Calculation</p>
                    </div>
                  </>
                ) : (
                  <>
                    {
                      (Object.values(errors).filter(element => element == null).length != Object.values(errors).length) ?(
                        <>
                         <div className="card p-3 mt-3 text-center text-danger">
                      <p className="m-0">Oops, we noticed some mistakes in your information. Please double-check your form before moving on. </p>
                    </div>

                        </>
                      ):(
                        <>
                       
<div className="text-center mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary"
                   
                        
                      >
                        Update Details
                      </button>
                    </div>

                        </>

                      )
                    }
                  </>
                )}
         
            </form>
        
        </div>
      
      </div>
    </div>
  </div>
  {
  pageLoading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
    </>
  )
}

export default BedUpdateForm