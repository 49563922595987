import React, { useEffect, useState } from "react";
import "./Enroll.css";
import { apilink,  checkPropertyLengths,  disableList, stateList, subjects } from "../../data/fdata";
import { useAlert } from "react-alert";
import axios from "axios";
import { validateInput } from "../../data/ErrorHandel";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToWords } from 'to-words';
import parse from "html-react-parser";
import PreviewIcon from '@mui/icons-material/Preview';
import { Tooltip } from "@mui/material";

const BedEnroll = ({ sessionData ,  datalist, coursid, cou_name , name, email , phone }) => {
  const alert = useAlert();
  const token = Cookies.get('_edushark_user_access_token');
  const toWords = new ToWords();
// console.log(datalist)
  const history= useHistory()
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [invalidmarks, setInvalidmarks] = useState(false);
  //  console.log(coursid)
  const [preview, setPreview] = useState(false);


  const [errors, setErrors] = useState({});

  const [session, setSession] = useState(sessionData);
  const [couerse_name, setCouerse_name] = useState(cou_name);

  const [have_bss_id, setHave_bss_id] = useState("");
  const [bss_id, setBss_id] = useState("");
  const [applicant_name, setApplicant_name] = useState("");
  const [father_name, setFather_name] = useState("");
  const [mother_name, setMother_name] = useState("");
  const [guardian_name, setGuardian_name] = useState("");
  const [relation_with_guardian, setRelation_with_guardian] = useState("");
  const [gender, setGender] = useState("");
  const [category, setCategory] = useState("");
  const [bloodGp, setBloodGp] = useState("");
  const [aadhar_no, setAadhar_no] = useState("");
  const [applicant_dob, setApplicant_dob] = useState("");
  const [languages, setLanguages] = useState("");
  const [nationality, setNationality] = useState("INDIAN");
  const [religion, setReligion] = useState("");
  const [marital_status, setMarital_status] = useState("");
  const [bpl, setBpl] = useState("");
  const [specially_abled, setSpecially_abled] = useState("");
  const [percentage_disable, setPercentage_disable] = useState("");
  const [type_diable, setType_diable] = useState("");
  const [income, setIncome] = useState("");

  const [applicant_phone, setApplicant_phone] = useState("");
  const [applicant_whatsappno, setApplicant_whatsappno] = useState("");
  const [applicant_email, setApplicant_email] = useState("");
  const [guardian_phone, setGuardian_phone] = useState("");
  const [paddress, setPAddress] = useState("");
  const [pblock, setPBlock] = useState("");
  const [pblockname, setPBlockname] = useState("");
  const [pstate, setPState] = useState("");
  const [pdistrictList, setPDistrictList] = useState([]);
  const [pcountry, setPCountry] = useState("INDIA");
  const [pdistrict, setPDistrict] = useState("");
  const [pps, setPPs] = useState("");
  const [ppo, setPPo] = useState("");
  const [ppin, setPPin] = useState("");
  const [same_permanent, setSame_permanent] = useState(false);
  const [caddress, setCAddress] = useState("");
  const [cblock, setCBlock] = useState("");
  const [cblockname, setCBlockname] = useState("");
  const [cstate, setCState] = useState("");
  const [cdistrictList, setCDistrictList] = useState([]);
  const [ccountry, setCCountry] = useState("INDIA");
  const [cdistrict, setCDistrict] = useState("");
  const [cps, setCPs] = useState("");
  const [cpo, setCPo] = useState("");
  const [cpin, setCPin] = useState("");

  const [lastUniversity, setLastUniversity] = useState("");
  const [regNo_lastu, setRegNo_lastu] = useState("");
  const [mboard, setMBoard] = useState("");
  const [myear, setMYear] = useState("");
  const [mfull_marks, setMFull_marks] = useState("");
  const [mmarks, setMmarks] = useState("");
  const [mpercentage_marks, setMPercentage_marks] = useState("");
  const [hboard, setHBoard] = useState("");
  const [hyear, setHYear] = useState("");
  const [hfull_marks, setHFull_marks] = useState("");
  const [hmarks, setHmarks] = useState("");
  const [hpercentage_marks, setHPercentage_marks] = useState("");

  const [status_ug, setStatus_ug] = useState("");
  const [type_ug, setType_ug] = useState("");
  const [university, setUniversity] = useState("");
  const [uyear, setUYear] = useState("");
  const [ufull_marks, setUFull_marks] = useState("");
  const [umarks, setUmarks] = useState("");
  const [upercentage_marks, setUPercentage_marks] = useState("");
  const [have_master, setHave_master] = useState("");
  const [status_pg, setStatus_pg] = useState("");
  const [type_pg, setType_pg] = useState("");
  const [puniversity, setPUniversity] = useState("");
  const [pyear, setPYear] = useState("");
  const [pfull_marks, setPFull_marks] = useState("");
  const [pmarks, setPmarks] = useState("");
  const [ppercentage_marks, setPPercentage_marks] = useState("");

  const [choose_college, setChoose_college] = useState("");
  const [choose_collegeList, setChoose_collegeList] = useState([]);
  
  const [choose_collegeId, setChoose_collegeId] = useState("");
  const [college_Course_Price, setCollege_Course_Price] = useState("");
  const [choose_subject, setChoose_subject] = useState("");
  const [reffered_by, setReffered_by] = useState("");

  const [profileImg, setProfileImg] = useState("");
  const [signatureImg, setSignatureImg] = useState("");
  const [admit10thImg, setAdmit10thImg] = useState("");
  const [result10thImg, setResult10thImg] = useState("");
  const [result12thImg, setResult12thImg] = useState("");
  const [resultugImg, setResultugImg] = useState("");
  const [resultpgImg, setResultpgImg] = useState("");
  const [resultAadharImg, setResultAadharImg] = useState("");
  const [resultCastImg, setResultCastImg] = useState("");
  const [otherImg, setOtherImg] = useState("");
  const [disableImg, setDisableImg]= useState("");
  const [agreementCopy,setAgreementCopy]= useState("");


  const [checked, setChecked] = useState(false);
  const [checkedagreement, setCheckedagreement] = useState(false);


  const [count, setCount] = useState(1);

  useEffect(() => {
    // 👇️ scroll to top on page load
    
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    
    
  }, [count]);


  

  const handelphoto = async (e) => {
    const { files } = e.target;
    setPageLoading(true);

    if (files.length > 0) {
      if (files[0].size > 200000) {
        alert.error(`File should be less then 200kb`);
      } else if (
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setProfileImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg`);
      }
    }
    setPageLoading(false);
  };

  const handelsignature = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 200000) {
        alert.error(`File should be less then 200kb`);
      } else if (
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setSignatureImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg`);
      }
    }
    setPageLoading(false);
  };

  const handel10thadmint = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setAdmit10thImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }
    setPageLoading(false);
  };
  const handel10thresult = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResult10thImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }
    setPageLoading(false);
  };
  const handel12thresult = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResult12thImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }
    setPageLoading(false);
  };

  const handelugresult = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (files[0].type === "application/pdf") {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResultugImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only pdf`);
      }
    }

    setPageLoading(false);
  };

  const handelpgresult = async (e) => {
    const { files } = e.target;
    setPageLoading(true);

    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (files[0].type === "application/pdf") {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResultpgImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only pdf`);
      }
    }

    setPageLoading(false);
  };

  const handelaadhar = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResultAadharImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }
    setPageLoading(false);
  };
  const handelcast = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setResultCastImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }
    setPageLoading(false);
  };

  const handelotherdoc = async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setOtherImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }

    setPageLoading(false);
  };

  const handelDisability= async (e) => {
    const { files } = e.target;
    setPageLoading(true);
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5 MB`);
      } else if (
        files[0].type === "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (res.data.success) {
          setDisableImg(res.data.imageUrls[0]);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only jpg, png , jpeg , pdf`);
      }
    }

    setPageLoading(false);
  };

 

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const errorMessage = validateInput(name, value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
    // console.log(errors)
  };

  useEffect(() => {
    if (parseInt(mfull_marks) < parseInt(mmarks)) {
      setInvalidmarks(true);
    } else if (parseInt(hfull_marks) < parseInt(hmarks)) {
      setInvalidmarks(true);
    } else if (parseInt(ufull_marks) < parseInt(umarks)) {
      setInvalidmarks(true);
    } else if (parseInt(pfull_marks) < parseInt(pmarks)) {
      setInvalidmarks(true);
    } else setInvalidmarks(false);
  }, [
    mfull_marks,
    mmarks,
    hfull_marks,
    hmarks,
    ufull_marks,
    umarks,
    pfull_marks,
    pmarks,
  ]);

  useEffect(() => {
    if (same_permanent) {
      setCAddress(paddress);
      setCBlock(pblock);
      setCBlockname(pblockname);

      setCState(pstate);
      setCCountry(pcountry);
      let ar = stateList.filter((v) => v.state == pstate);
      ar.length > 0 && setCDistrictList(ar[0].districts);
      setCDistrict(pdistrict);
      setCPs(pps);
      setCPo(ppo);
      setCPin(ppin);
    } else {
      setCAddress(caddress);
      setCBlock(cblock);
      setCState(cstate);
      setCCountry("INDIA");
      setCDistrict(cdistrict);
      setCPs(cps);
      setCPo(cpo);
      setCPin(cpin);
    }
  }, [
    paddress,
    pblock,
    pstate,
    pcountry,
    pdistrict,
    pps,
    ppo,
    ppin,
    same_permanent,
   
  ]);


  const onSubmitApplication = async (e) => {
    e.preventDefault();
    setPageLoading(true)
    let AppID=`BED${new Date().getFullYear()}${Date.now()}`;
    const data = {

      paymentAmount:datalist[0].feesAmount ,
      MUID: "MUID" + Date.now(),
      paymentId: 'T' + Date.now(),
      AppID,

      courseID:coursid,
      collegeID:choose_collegeId,
      session_year: session,
      course_name: cou_name,
      course_fees:college_Course_Price ,

      have_bss_id,
      bss_id,
      
personalinfo:{
  applicant_name,
      father_name,
      mother_name,
      guardian_name,
      relation_with_guardian,
      gender,
      category,
      bloodGp,
      aadhar_no,
      applicant_dob,
      languages,
      nationality,
      religion,
      marital_status,
      bpl,
      specially_abled,
      percentage_disable,
      type_diable,
      income,
},
contactinfo:{
  applicant_phone,
  applicant_whatsappno,
  applicant_email,
  guardian_phone
},
addressinfo:{
  paddress,
  pblock,
  pblockname,
  pstate,
  pcountry,
  pdistrict,
  pps,
  ppo,
  ppin,
  same_permanent,
  caddress,
  cblock,
  cblockname,
  cstate,
  ccountry,
  cdistrict,
  cps,
  cpo,
  cpin
},
bed_form:{
  schoolinfo:{
    lastUniversity,
    regNo_lastu,
    mboard,
    myear,
    mfull_marks,
    mmarks,
    mpercentage_marks,
    hboard,
    hyear,
    hfull_marks,
    hmarks,
    hpercentage_marks,
  },
  collegeinfo:{
    status_ug,
    type_ug,
    university,
    uyear,
    ufull_marks,
    umarks,
    upercentage_marks,
    have_master,
    status_pg,
    type_pg,
    puniversity,
    pyear,
    pfull_marks,
    pmarks,
    ppercentage_marks,
  },
  choose_subject,
  choose_college,
 
}, 
documentinfo:{
  profileImg,
  signatureImg,
  admit10thImg,
  result10thImg,
  result12thImg,
  resultugImg,
  resultpgImg,
  resultAadharImg,
  resultCastImg,
  otherImg,
  disableImg,
},   
reffered_by,
      checked,
      agreementCopy
    };

    if(count==1)
    {
      // setPreview(true)
      let data={
        have_bss_id,
          bss_id,
          
    personalinfo:{
      applicant_name,
          father_name,
          mother_name,
          guardian_name,
          relation_with_guardian,
          gender,
          category,
          bloodGp,
          aadhar_no,
          applicant_dob,
          languages,
          nationality,
          religion,
          marital_status,
          bpl,
          specially_abled,
          percentage_disable,
          type_diable,
          income,
    },
    contactinfo:{
      applicant_phone,
      applicant_whatsappno,
      applicant_email,
      guardian_phone
    },
    addressinfo:{
      paddress,
      pblock,
      pblockname,
      pstate,
      pcountry,
      pdistrict,
      pps,
      ppo,
      ppin,
      same_permanent,
      caddress,
      cblock,
      cblockname,
      cstate,
      ccountry,
      cdistrict,
      cps,
      cpo,
      cpin
    },
      }
      localStorage.setItem('_EDU_APPLICATION_DATA_', JSON.stringify(data));

          
      setTimeout(() => {
        alert.success("Data saved successfully")
        setCount((prev)=>prev+1)
      }, 2000);
    }else if(count==2)
    {
      setCount((prev)=>prev+1)
    }
    else if(count==3)
    {
      setCount((prev)=>prev+1)
      setAgreementCopy(`
      <div class="">
          <p class="m-0 mb-3">
           I am <span class="text-primary">${applicant_name}</span>, son
           or daughter of <span class="text-primary">${father_name}</span>, I want
            to take admission in Bachelor of education under Babasaheb
            Ambedkar University (Erstwhile The West Bengal University of
            Teachers' Training, Education Planning and Administration).
          </p>
          
          <p class="m-0 mb-3 ">
            
           
            I have chosen
            <span class="text-primary">${choose_college}</span> as my
            college and
            <span class="text-primary"> ${choose_subject}</span> for
            Bachelor of Education(B.Ed) in
            <span class="text-primary">${session}</span> academic year.
          
         
            I Understand that Total course fee for Bachelor of Education is
            <b class="text-primary"> Rs- ${college_Course_Price} (${ college_Course_Price && toWords.convert(Number(college_Course_Price)) }) </b> . This
            Course Fee Includes only Tuition Fee of a Bachelor of Education
            Student. This Course Fee is Excluding GST and Transaction Charge
            or Payment Gateway fee
            </p>
          <p class="m-0 mb-3">
      
            I also understand that This Course Fee is divided into five
            parts for the Convenience of the students.This 5 parts are
            namely - <span class="text-primary">(1) Admission Fee (2) 1st Semester Fee (3) 2nd Semester
            Fee (4) 3rd Semester Fee (5) 4th Semester Fee.</span> I will pay the
            fee for each Semester before the Semester Examination.
          </p>
          <p class="">
          
          <span class="text-primary"> I am Also Aware of That I have to pay</span> - one time Registration
            Fee,Four times Examination Fee (excluding supplementary
            examination fee),Fee for Dress, Fee for Identity card. <span class="text-primary"> Others
            Fees that I may have to pay </span> -Excursional Tour Charge (Optional),
            Observation Fee (Optional), hostel fee (optional), Library
            Charge (optional)
          </p>
      
          <p>
          
            I am <span class="text-primary">${applicant_name}</span> Son
            or Daughter of
            <span class="text-primary">${father_name}</span> residing at
            <span class="text-primary">${paddress},${ppo},${pps},${pdistrict},${pstate},${ppin}</span> do hereby
            solemnly affirm and declare as follows:
          </p>
          <ul class="pl-5">
            <li>
              I accept the fee structure mentioned above and undertake to
              pay all fees on time. Team Edushark and Baba Saheb Ambedkar
              Education University may take necessary action against me if I
              fail to pay the fee on time for any reason.
            </li>
            <li>
          I am ${applicant_name}, if for any reason I do not continue the Bachelor of Education(B.Ed) course 
          then I will have to pay 75% of my total course fee to Edushark. 
          Otherwise Edushark authorities may take legal action against me. 
          Any legal complications shall be subject to Murshidabad jurisdiction.

            </li>
            <li>
              
              I hereby declare that if I discontinue my education then
              Edushark shall not be liable in any way to refund me the fees
              paid by me till the time of discontinuance of my education.
            </li>
            <li>
              I also declare that all the statements made in the application
              form are true and correct to the best of my knowledge and
              belief, if any fraud and false details are found in my
              statement and my uploaded documents, the concerned authority
              reserves the right to cancel my studentship along with my
              application.
            </li>
          </ul>
         
          </div>
      
      
      `)
    }
    else{
      // console.log(data)
      if(checkedagreement)
      {
        const res=await axios.post(`${apilink}/api/application/postapplication`,data,{
          headers: {
            Authorization: token,
          },
        })
        // console.log(res.data.url)
        if(res.data.success)
        {
          // alert.success(res.data.msg)
          // setTimeout(() => {
          //   history.push("/application-success")
          // }, 4000);

          setTimeout(() => {
            window.location.href=res.data.url
           }, 2000);
          
        }else{
          alert.error(res.data.msg)
        }
      }else{
        alert.error("Please Checked Agreement")
      }
    }

    setTimeout(() => {
      setPageLoading(false)
     }, 2000);
    // console.log(data);
  };

  

  const handelSubjectCollege = async (e) => {
    setChoose_college("")
    setChoose_collegeId("")
    setCollege_Course_Price("")
    const res = await axios.post(`${apilink}/api/course/handelSubjectCollege`, {
      text: e.target.value,
      cid: coursid,
    });
    // console.log(res.data.result)
    if (res.data.success) {
      setChoose_collegeList(res.data.result);
     

    } else {
      alert.error(res.data.msg);
    }
  };
// console.log(choose_collegeId)
  useEffect(()=>{
    setApplicant_name(name?.toUpperCase())
    setApplicant_email(email?.toUpperCase())
    setApplicant_phone(phone)

  },[])

  const handelMarkCalculations=(full_m, mark , type_Exam)=>{
    if (type_Exam == "10TH") setMPercentage_marks(full_m.length ? (mark.length ? ((mark / full_m) * 100).toFixed(2) : 0.00) : 0.00);
    if (type_Exam == "12TH") setHPercentage_marks(full_m.length ? (mark.length ? ((mark / full_m) * 100).toFixed(2) : 0.00) : 0.00);
    if (type_Exam == "UG") setUPercentage_marks(full_m.length ? (mark.length ? ((mark / full_m) * 100).toFixed(2) : 0.00) : 0.00);
    if (type_Exam == "PG") setPPercentage_marks(full_m.length ? (mark.length ? ((mark / full_m) * 100).toFixed(2) : 0.00) : 0.00);
  }

// console.log(Object.values(errors).filter(element => element == null).length != Object.values(errors).length)
// console.log(Object.values(errors))

const saveData=()=>{
  if(invalidmarks || Object.values(errors).filter(element => element == null).length != Object.values(errors).length)
    {
      alert.error("Please enter all correct values before saving data.")
    }else{
      let data={
        have_bss_id,
          bss_id,
          
    personalinfo:{
      applicant_name,
          father_name,
          mother_name,
          guardian_name,
          relation_with_guardian,
          gender,
          category,
          bloodGp,
          aadhar_no,
          applicant_dob,
          languages,
          nationality,
          religion,
          marital_status,
          bpl,
          specially_abled,
          percentage_disable,
          type_diable,
          income,
    },
    contactinfo:{
      applicant_phone,
      applicant_whatsappno,
      applicant_email,
      guardian_phone
    },
    addressinfo:{
      paddress,
      pblock,
      pblockname,
      pstate,
      pcountry,
      pdistrict,
      pps,
      ppo,
      ppin,
      same_permanent,
      caddress,
      cblock,
      cblockname,
      cstate,
      ccountry,
      cdistrict,
      cps,
      cpo,
      cpin
    },
      }
      let exclude = ['bss_id', 'percentage_disable', 'type_diable'];
      let allPropertiesValid = checkPropertyLengths(data, '', exclude);
      // console.log(`All properties have valid lengths: ${allPropertiesValid}`);
      if(allPropertiesValid)
        {
          localStorage.setItem('_EDU_APPLICATION_DATA_', JSON.stringify(data));

          alert.success("Data saved successfully")


        }else{
          alert.error("Please enter all correct values before saving data.")
        }
    }
 
}

useEffect(()=>{
  let alldata=JSON.parse(localStorage.getItem("_EDU_APPLICATION_DATA_"))
  if(alldata?.have_bss_id){
   
 setHave_bss_id(alldata.have_bss_id)
    setBss_id(alldata.bss_id)
  
    setApplicant_name(alldata.personalinfo.applicant_name)
    setFather_name(alldata.personalinfo.father_name)
    setMother_name(alldata.personalinfo.mother_name)
    setGuardian_name(alldata.personalinfo.guardian_name)
    setRelation_with_guardian(alldata.personalinfo.relation_with_guardian)
    setGender(alldata.personalinfo.gender)
    setCategory(alldata.personalinfo.category)
    setBloodGp(alldata.personalinfo.bloodGp)
    setAadhar_no(alldata.personalinfo.aadhar_no)
    setApplicant_dob(alldata.personalinfo.applicant_dob)
    setLanguages(alldata.personalinfo.languages)
    setNationality(alldata.personalinfo.nationality)
    setReligion(alldata.personalinfo.religion)
    setMarital_status(alldata.personalinfo.marital_status)
    setBpl(alldata.personalinfo.bpl)
    setSpecially_abled(alldata.personalinfo.specially_abled)
    setPercentage_disable(alldata.personalinfo.percentage_disable)
    setType_diable(alldata.personalinfo.type_diable)
    setIncome(alldata.personalinfo.income)

    setApplicant_phone(alldata.contactinfo.applicant_phone)
    setApplicant_whatsappno(alldata.contactinfo.applicant_whatsappno)
    setApplicant_email(alldata.contactinfo.applicant_email)
    setGuardian_phone(alldata.contactinfo.applicant_phone)

    setPAddress(alldata.addressinfo.paddress)
    setPBlock(alldata.addressinfo.pblock)
    setPBlockname(alldata.addressinfo.pblockname)
    setPState(alldata.addressinfo.pstate)   
    setPCountry(alldata.addressinfo.pcountry)
    let ar = stateList.filter((v) => v.state == alldata.addressinfo.pstate);
    ar.length > 0  &&  setPDistrictList(ar[0].districts)
    setPDistrict(alldata.addressinfo.pdistrict)
    setPPs(alldata.addressinfo.pps)
    setPPo(alldata.addressinfo.ppo)
    setPPin(alldata.addressinfo.ppin)
setSame_permanent(alldata.addressinfo.same_permanent)
    setCAddress(alldata.addressinfo.caddress)
    setCBlock(alldata.addressinfo.cblock)
    setCBlockname(alldata.addressinfo.cblockname)
    setCState(alldata.addressinfo.cstate)   
    setCCountry(alldata.addressinfo.ccountry)
    let ar1 = stateList.filter((v) => v.state == alldata.addressinfo.cstate);
    ar1.length > 0  &&  setCDistrictList(ar1[0].districts)
    setCDistrict(alldata.addressinfo.cdistrict)
    setCPs(alldata.addressinfo.cps)
    setCPo(alldata.addressinfo.cpo)
    setCPin(alldata.addressinfo.cpin)
  }

},[])

  return (
    <>
     {/* <Tooltip title="Application Preview" placement="right" arrow >
     <div className="preview_box"  data-toggle="modal" data-target="#myModal">
      <PreviewIcon  />
    </div>
                  </Tooltip> */}



                  <div class="modal" id="myModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
      
       
        <div class="modal-header">
          <h5 class="modal-title">Application Form Preview</h5>
          <button type="button" class="close" data-dismiss="modal">×</button>
        </div>
        
       
        <div class="modal-body preview_modal">
        <div class="table-responsive">
        <table class="my-2 ">
      <tr>
        <th colspan="4" class="text-custom"><b>Personal Details</b></th>
      </tr>
      <tr>
          <th>Name:</th>
          <td> {applicant_name } </td>
          <th>Gender:</th>
          <td>{gender} </td>
      </tr>
      <tr>
        <th>Have Student Id:</th>
        <td> {have_bss_id}  </td>
        <th>Student Id:</th>
        <td> {bss_id}  </td>
    </tr>
    <tr>
      
      <th>Father's Name:</th>
      <td> {father_name}  </td>
      <th>Mother's Name:</th>
      <td> {mother_name}  </td>
  </tr>
  <tr>
      
    <th>Guardian's Name:</th>
    <td> {guardian_name}  </td>
    <th>Relation With Guardian</th>
  <td> {relation_with_guardian}  </td>
    
</tr>
<tr>
  <th>Category:</th>
    <td> {category}  </td>
    <th>Blood Group:</th>
  <td> {bloodGp}  </td>
</tr>
<tr>
      
  <th>Applicant DOB:</th>
  <td>  {new Date(applicant_dob).toLocaleDateString('en-GB')}  </td>
  <th>Aadhar No::</th>
  <td> {aadhar_no}  </td>
</tr>

    
<tr>
      
  
  <th>Languages:</th>
  <td> {languages}  </td>
  <th> Income:</th>
  <td>₹  {Number(income).toFixed(2)} </td>
</tr>
  
<tr>
  <th> Nationality:</th>
  <td> {nationality } </td>
  <th> Religion:</th>
  <td> {religion}  </td>
</tr>

<tr>
  <th> Marital Status:</th>
  <td> {marital_status}  </td>
  <th> Bpl:</th>
  <td> {bpl}  </td>
</tr>
<tr>
  <th> Specially Abled:</th>
  <td colspan="3"> {specially_abled}  </td>
 
</tr>
<tr>
  <th> Percentage Disable:</th>
  <td> {percentage_disable}  </td>
  <th> Type Disable:</th>
  <td> {type_diable}  </td>
  
</tr>



<tr>
        <th colspan="4" class="text-custom"><b>Contact & Address Details</b></th>
      </tr>
      <tr>
        <th> Applicant Email:</th>
        <td> {applicant_email}  </td>
        <th> Applicant Phone:</th>
        <td> {applicant_phone}  </td>
      </tr>
      <tr>
        <th> Applicant Whatsappno:</th>
        <td> {applicant_whatsappno}  </td>
        <th> Guardian Phone:</th>
        <td> {guardian_phone}  </td>
      </tr>
      <tr>
        <td colspan="4" class="text-warning"><u>Current Address</u></td>
      </tr>
      <tr>
        <th> Address:</th>
        <td colspan="3"> {caddress}  </td>
        
      </tr>
      <tr>
        <th> Block :</th>
        <td> {cblock } </td>
        <th> Block Name:</th>
        <td> {cblockname}  </td>
      </tr>
      <tr>
        <th> State:</th>
        <td> {cstate}  </td>
        <th> District:</th>
        <td> {cdistrict} </td>
      </tr>
      <tr>
        <th> P.S:</th>
        <td> {cps} </td>
        <th> P.O:</th>
        <td> {cpo}  </td>
      </tr>
      <tr>
        <th> Pin:</th>
        <td> {cpin }</td>
        <th> Country:</th>
        <td> {ccountry}  </td>
      </tr>
      <tr>
        <td colspan="4" class="text-warning"><u>Parmanent Address</u></td>
      </tr>
      <tr>
        <th> Address:</th>
        <td colspan="3"> {paddress}  </td>
        
      </tr>
      <tr>
        <th> Block :</th>
        <td> {pblock }   </td>
        <th> Block Name:</th>
        <td> {pblockname}    </td>
      </tr>
      <tr>
        <th> State:</th>
        <td> {pstate }   </td>
        <th> District:</th>
        <td> {pdistrict }  </td>
      </tr>
      <tr>
        <th> P.S:</th>
        <td> {pps }  </td>
        <th> P.O:</th>
        <td> {ppo }   </td>
      </tr>
      <tr>
        <th> Pin:</th>
        <td> {ppin }  </td>
        <th> Country:</th>
        <td> {pcountry}    </td>
      </tr>


    <tr>
          <th colspan="4" class="text-custom"><b>Education Details</b></th>
        </tr>
        
        <tr>
          <th> Last University:</th>
          <td>{lastUniversity }</td>
          <th> RegNo Last University:</th>
          <td>{regNo_lastu }</td>
        </tr>
        <tr>
          <td colspan="4" class="text-warning"><u>MP School Details</u></td>
        </tr>
        <tr>
          <th> MP Board:</th>
          <td>{mboard }</td>
          <th> MP Year:</th>
          <td>{myear }</td>
        </tr>
  
        <tr>
          <th> MP Full Marks:</th>
          <td>{mfull_marks }</td>
          <th> MP Marks:</th>
          <td>{mmarks }</td>
        </tr>
        <tr>
          <th > MP Percentage Marks:</th>
          <td colspan="3">{mpercentage_marks }</td>
          
        </tr>
        
  
        <tr>
          <td colspan="4" class="text-warning"><u>HS School Details</u></td>
        </tr>
  
        <tr>
          <th> HS Board:</th>
          <td>{hboard }</td>
          <th> HS Year:</th>
          <td>{hyear }</td>
        </tr>
        <tr>
          <th> HS Full Marks:</th>
          <td>{hfull_marks }</td>
          <th> HS Marks:</th>
          <td>{hmarks }</td>
        </tr>
  
        <tr>
          <th> HS Percentage Marks:</th>
          <td colspan="3">{hpercentage_marks }</td>
          
        </tr>
  
        <tr>
          <td colspan="4" class="text-warning"><u>College Details</u></td>
        </tr>
        <tr>
          <th> Status Ug:</th>
          <td>{status_ug }</td>
          <th> Type Ug:</th>
          <td>{type_ug }</td>
        </tr>
  
        <tr>
          <th> University:</th>
          <td>{university }</td>
          <th> UG Year:</th>
          <td>{uyear }</td>
        </tr>
        <tr>
          <th> UG Full Marks:</th>
          <td>{ufull_marks }</td>
          <th> UG Marks:</th>
          <td>{umarks }</td>
        </tr>
        <tr>
          <th> UG Percentage Marks:</th>
          <td colspan="3">{upercentage_marks }</td>
          
        </tr>
  
        <tr>
          <th> Have Master:</th>
          <td colspan="3">{have_master }</td>
                  
        </tr>
  
        <tr>
          <th> Status PG:</th>
          <td>{status_pg }</td>
          <th> Type PG:</th>
          <td>{type_pg }</td>
        </tr>
  
        <tr>
          <th> University:</th>
          <td>{puniversity }</td>
          <th> PG Year:</th>
          <td>{pyear }</td>
        </tr>
        <tr>
          <th> PG Full Marks:</th>
          <td>{pfull_marks }</td>
          <th>PG Marks:</th>
          <td>{pmarks }</td>
        </tr>
        <tr>
          <th> PG Percentage Marks:</th>
          <td colspan="3">{ppercentage_marks }</td>
          
        </tr>
    
        <tr>
        <th colspan="4" class="text-custom"><b>Course Details</b></th>
      </tr>
     
      <tr>
        <th> College:</th>
        <td colspan="3">{choose_college }</td>
        
      </tr>
      <tr>
        <th> Course:</th>
        <td colspan="3">{cou_name }</td>
        
      </tr>
      <tr>
        <th> Subject:</th>
        <td>{choose_subject }</td>
        <th> Session:</th>
        <td>{session}</td>
      </tr>

     
     

<tr>
  <th>Profile Photo</th>
  <td className="text-center">
    {
      profileImg && <img src={profileImg} alt="" className="profile_previewimg" />
    }
  </td>
  <th>Signature</th>
  <td className="text-center">
   {
    signatureImg &&  <img src={signatureImg} alt="" className="profile_previewimg" />
   }
  </td>
</tr>



 
 
 
  </table>
  </div>
        </div>
        
       
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>

    
      <div className="enroll">
        <div className="container-fluid">
          {
            (count==1 || count==2) && <h3 className="text-center pb-3 text-custom">
            B.ED Application Form ({session})
          </h3>
          }
          {
            count == 3 && (
              <>
              <h3 className="text-center text-custom">
              Aggreement Copy
          </h3>
              <p className="m-0 text-center mb-4">Aggreement between Edushark and Student</p>
              </>
            )
          }

{
            (count==4) && <h3 className="text-center pb-3 text-custom">
           Application Fees Payment Structure
          </h3>
          }
          
          <form onSubmit={onSubmitApplication}>
            {
              (count == 1 ) && (
                <>
                  <div className="card">
              <div className="card-header">
                <p className="m-0"> Personal Details</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Do you have Banglar Shiksha Student ID</p>
                      <select
                        class="form-control"
                        value={have_bss_id}
                        onChange={(e) =>
                          setHave_bss_id(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Have Banglar Shiksha Student ID?
                        </option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>

                  {have_bss_id == "YES" && (
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                      <div class="form-group">
                        <p>Banglar Shiksha Student ID</p>
                        <input
                          type="text"
                          placeholder="Banglar Shiksha Student ID"
                          class="form-control"
                          name="bss_id"
                          required
                          value={bss_id}
                          onChange={(e) =>
                            setBss_id(e.target.value.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Name of the Applicant</p>
                      <p className="sm_label">
                        Name should be written as per Secondary admit card
                      </p>

                      <input
                        type="text"
                        placeholder="Applicant Name"
                        class="form-control"
                        name="applicant_name"
                        required
                        value={applicant_name}
                        onChange={(e) =>
                          setApplicant_name(e.target.value.toUpperCase())
                        }
                      />
                      {/* <small class="fn_12 text-danger">Name should be written as per Secondary admit card</small> */}
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Father's Name</p>
                      <p className="sm_label">
                        Name should be written as per Secondary admit card
                      </p>
                      <input
                        type="text"
                        placeholder="Father's Name"
                        class="form-control"
                        name="father_name"
                        required
                        value={father_name}
                        onChange={(e) =>
                          setFather_name(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Mother's Name</p>
                      <p className="sm_label">
                        Name should be written as per Secondary admit card
                      </p>
                      <input
                        type="text"
                        placeholder="Mother's Name"
                        class="form-control"
                        name="mother_name"
                        required
                        value={mother_name}
                        onChange={(e) =>
                          setMother_name(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Guardian's Name</p>
                      <input
                        type="text"
                        placeholder="Guardian's Name"
                        class="form-control"
                        name="guardian_name"
                        required
                        value={guardian_name}
                        onChange={(e) =>
                          setGuardian_name(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Relation with Guardian</p>
                      <select
                        class="form-control"
                        value={relation_with_guardian}
                        onChange={(e) =>
                          setRelation_with_guardian(
                            e.target.value.toUpperCase()
                          )
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Relation with Guardian
                        </option>
                        <option value="FATHER">FATHER</option>
                        <option value="MOTHER">MOTHER</option>
                        <option value="BROTHER">BROTHER</option>
                        <option value="SISTER">SISTER</option>
                        <option value="HUSBAND">HUSBAND</option>
                        <option value="UNCLE">UNCLE</option>
                        <option value="AUNTY">AUNTY</option>
                        <option value="GRANDFATHER">GRANDFATHER</option>
                        <option value="GRANDMOTHER">GRANDMOTHER</option>
                        <option value="OTHERS">OTHERS</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Gender</p>

                      <select
                        class="form-control"
                        value={gender}
                        onChange={(e) =>
                          setGender(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Gender
                        </option>
                        <option value="MALE">MALE</option>
                        <option value="FEMALE">FEMALE</option>
                        <option value="OTHERS">OTHERS</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p> Category</p>
                      <p className="sm_label">
                        If SC/ST/OBC , Upload Cast Certificate.
                      </p>
                      <select
                        class="form-control"
                        value={category}
                        onChange={(e) =>
                          setCategory(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Category
                        </option>

                        <option value="SC">SC</option>
                        <option value="ST">ST</option>
                        <option value="OBC">OBC</option>
                        <option value="GENERAL/EWS">GENERAL/EWS</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Date of Birth</p>
                      <p className="sm_label">
                        DOB should be written as per Secondary admit card
                      </p>
                      <input
                        type="text"
                        placeholder="Date of Birth"
                        class="form-control"
                        name="dob"
                        required
                        value={applicant_dob}
                        onChange={(e) =>
                          setApplicant_dob(e.target.value.toUpperCase())
                        }
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                      />
                    </div>
                  </div>
                 

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Aadhar card</p>
                      <input
                        type="number"
                        placeholder="Aadhar card"
                        class="form-control"
                        name="aadhar_no"
                        value={aadhar_no}
                        onChange={(e) => {
                          setAadhar_no(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                        required
                      />
                      <small class="fn_12 text-danger">
                        {errors?.aadhar_no && errors.aadhar_no}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p> Blood Group</p>
                      <select
                        class="form-control"
                        value={bloodGp}
                        onChange={(e) =>
                          setBloodGp(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Blood Group
                        </option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O+">O-</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Known Languages (ex: English,Bengali ..)</p>
                      <input
                        type="text"
                        placeholder="Known Languages (ex: English,Bengali ..)"
                        class="form-control"
                        name="languages"
                        required
                        value={languages}
                        onChange={(e) =>
                          setLanguages(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Nationality</p>
                      <input
                        type="text"
                        placeholder="Nationality"
                        class="form-control"
                        name="nationality"
                        required
                        value={nationality}
                        onChange={(e) =>
                          setNationality(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Religion</p>
                      <select
                        class="form-control"
                        name="religion"
                        value={religion}
                        onChange={(e) =>
                          setReligion(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Religion
                        </option>
                        <option value="HINDUISM">HINDUISM</option>
                        <option value="ISLAM">ISLAM</option>
                        <option value="CHRISTIANITY">CHRISTIANITY</option>
                        <option value="SIKHISM">SIKHISM</option>
                        <option value="BUDDHISM">BUDDHISM</option>
                        <option value="JAINISM">JAINISM</option>
                        <option value="JUDAISM">JUDAISM</option>
                        <option value="OTHERS">OTHERS</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Marital Status</p>
                      <select
                        class="form-control"
                        value={marital_status}
                        onChange={(e) =>
                          setMarital_status(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Marital Status
                        </option>
                        <option value="SINGLE">SINGLE</option>
                        <option value="MARRIED">MARRIED</option>
                        <option value="WIDOWED">WIDOWED</option>
                        <option value="DIVORCED">DIVORCED</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Whether BPL</p>
                      <select
                        class="form-control"
                        value={bpl}
                        onChange={(e) => setBpl(e.target.value.toUpperCase())}
                        required
                      >
                        <option value="" selected hidden>
                          Whether BPL
                        </option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Annual Family Income in Rs</p>
                      <input
                        type="text"
                        placeholder="Annual Income in Rs"
                        class="form-control"
                        name="income"
                        required
                        value={income}
                        onChange={(e) => {
                          handleInputChange(e);
                          setIncome(e.target.value);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.income && errors.income}
                      </small>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Whether Student Is Specially Abled</p>
                      <select
                        class="form-control"
                        value={specially_abled}
                        onChange={(e) => setSpecially_abled(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Student Is Specially Abled
                        </option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>

                  {specially_abled == "YES" && (
                    <>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>% of Disability</p>
                          <select
                            class="form-control"
                            value={percentage_disable}
                            onChange={(e) =>
                              setPercentage_disable(e.target.value)
                            }
                            required
                          >
                            <option value="" selected hidden>
                              % of Disability
                            </option>
                            <option value="20%">20%</option>
                            <option value="40%">40%</option>
                            <option value="60%">60%</option>
                            <option value="80%">80%</option>
                            <option value="100%">100%</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p> Type of Disability</p>
                          <select
                            class="form-control"
                            value={type_diable}
                            onChange={(e) => setType_diable(e.target.value)}
                            required
                          >
                            <option value="" selected hidden>
                              Type of Disability
                            </option>
                            {disableList?.map((v, i) => {
                              return (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            

            <div className="card mt-4">
              <div className="card-header">
                <p className="m-0"> Contacts and Address</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>Contacts</b>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Applicant Phone No</p>
                      <input
                        type="number"
                        placeholder="Applicant Phone No"
                        class="form-control"
                        name="applicant_phone"
                        required
                        value={applicant_phone}
                        onChange={(e) =>
                          setApplicant_phone(e.target.value.toUpperCase())
                        }
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Applicant whats app No</p>
                      <input
                        type="tel"
                        placeholder="Applicant whatsapp No"
                        class="form-control"
                        name="applicant_whatsappno"
                        maxLength={10}
                        required
                        value={applicant_whatsappno}
                        onChange={(e) => {
                          setApplicant_whatsappno(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.applicant_whatsappno &&
                          errors.applicant_whatsappno}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Applicant Email id:</p>
                      <input
                        type="email"
                        placeholder="Applicant email"
                        class="form-control"
                        name="applicant_email"
                        required
                        value={applicant_email}
                        onChange={(e) =>
                          setApplicant_email(e.target.value.toUpperCase())
                        }
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Guardian's Number</p>
                      <input
                        type="tel"
                        placeholder="Guardian phone"
                        class="form-control"
                        name="guardian_phone"
                        maxLength={10}
                        required
                        value={guardian_phone}
                        onChange={(e) => {
                          setGuardian_phone(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.guardian_phone && errors.guardian_phone}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>Permanent Address</b>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Address Line</p>
                      <input
                        type="text"
                        placeholder="Permanent Address"
                        class="form-control"
                        name="paddress"
                        required
                        value={paddress}
                        onChange={(e) =>
                          setPAddress(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Municipal Corporation/ Block</p>
                      <select
                        class="form-control"
                        value={pblock}
                        onChange={(e) =>
                          setPBlock(e.target.value.toUpperCase())
                        }
                        required
                      >
                        <option value="" selected hidden>
                          Municipal Corporation/ Block
                        </option>
                        <option value="MUNICIPAL">MUNICIPAL</option>
                        <option value="MUNICIPAL CORPORATION">
                          MUNICIPAL CORPORATION
                        </option>
                        <option value="BLOCK">BLOCK</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Municipal Corporation/ Block Name</p>
                      <input
                        type="text"
                        placeholder="Municipal Corporation/ Block Name"
                        class="form-control"
                        name="pblockname"
                        required
                        value={pblockname}
                        onChange={(e) =>
                          setPBlockname(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent Country</p>
                      <input
                        type="text"
                        placeholder="Permanent Country"
                        class="form-control"
                        name="pcountry"
                        required
                        value={pcountry}
                        onChange={(e) =>
                          setPCountry(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent State</p>
                      <select
                        class="form-control"
                        name="pstate"
                        value={pstate}
                        onChange={(e) => {
                          setPState(e.target.value);
                          let ar = stateList.filter(
                            (v) => v.state == e.target.value
                          );
                          setPDistrictList(ar[0]?.districts);
                        }}
                        required
                      >
                        <option value="" selected hidden>
                          Permanent State
                        </option>

                        {stateList.map((v) => {
                          return <option value={v.state}>{v.state}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent District</p>
                      <select
                        class="form-control"
                        name="pdistrict"
                        value={pdistrict}
                        onChange={(e) => setPDistrict(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Permanent District
                        </option>

                        {pdistrictList?.map((v) => {
                          return <option value={v}>{v}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent PS</p>
                      <input
                        type="text"
                        placeholder="Permanent P.S."
                        class="form-control"
                        name="pps"
                        required
                        value={pps}
                        onChange={(e) => setPPs(e.target.value.toUpperCase())}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent PO</p>
                      <input
                        type="text"
                        placeholder="Permanent P.O."
                        class="form-control"
                        name="ppo"
                        required
                        value={ppo}
                        onChange={(e) => setPPo(e.target.value.toUpperCase())}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Permanent Pin</p>
                      <input
                        type="text"
                        placeholder="Permanent Pin"
                        class="form-control"
                        name="ppin"
                        required
                        value={ppin}
                        onChange={(e) => {
                          setPPin(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.ppin && errors.ppin}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>Present address</b>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          // defaultChecked={same_permanent}
                          checked={same_permanent}
                          onChange={() => setSame_permanent((state) => !state)}
                        />
                        Same as Permanent
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Address Line </p>
                      <input
                        type="text"
                        placeholder="Present Address"
                        class="form-control"
                        name="caddress"
                        required
                        value={caddress}
                        onChange={(e) => setCAddress(e.target.value)}
                        readOnly={same_permanent}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Municipal Corporation/ Block</p>
                      <select
                        class="form-control"
                        value={cblock}
                        onChange={(e) => setCBlock(e.target.value)}
                        required
                        readOnly={same_permanent}
                      >
                        <option value="" selected hidden>
                          Municipal Corporation/ Block
                        </option>
                        <option value="MUNICIPAL">MUNICIPAL</option>
                        <option value="MUNICIPAL CORPORATION">
                          MUNICIPAL CORPORATION
                        </option>
                        <option value="BLOCK">BLOCK</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Municipal Corporation/ Block Name</p>
                      <input
                        type="text"
                        placeholder="Municipal Corporation/ Block Name"
                        class="form-control"
                        name="cblockname"
                        required
                        value={cblockname}
                        onChange={(e) =>
                          setCBlockname(e.target.value.toUpperCase())
                        }
                        readOnly={same_permanent}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present Country</p>
                      <input
                        type="text"
                        placeholder="Present Country"
                        class="form-control"
                        name="ccountry"
                        required
                        value={ccountry}
                        onChange={(e) =>
                          setCCountry(e.target.value.toUpperCase())
                        }
                        readOnly={same_permanent}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present State</p>
                      <select
                        class="form-control"
                        name="cstate"
                        value={cstate}
                        onChange={(e) => {
                          setCState(e.target.value);
                          let ar = stateList.filter(
                            (v) => v.state == e.target.value
                          );
                          setCDistrictList(ar[0]?.districts);
                        }}
                        required
                        readOnly={same_permanent}
                      >
                        <option value="" selected hidden>
                          Present State
                        </option>

                        {stateList.map((v) => {
                          return <option value={v.state}>{v.state}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present District</p>
                      <select
                        class="form-control"
                        name="cdistrict"
                        value={cdistrict}
                        onChange={(e) => setCDistrict(e.target.value)}
                        required
                        readOnly={same_permanent}
                      >
                        <option value="" selected hidden>
                          Present District
                        </option>

                        {cdistrictList?.map((v) => {
                          return <option value={v}>{v}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present PS</p>
                      <input
                        type="text"
                        placeholder="Present P.S."
                        class="form-control"
                        name="cps"
                        required
                        value={cps}
                        readOnly={same_permanent}
                        onChange={(e) => setCPs(e.target.value.toUpperCase())}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present PO</p>
                      <input
                        type="text"
                        placeholder="Present P.O."
                        class="form-control"
                        name="cpo"
                        required
                        value={cpo}
                        readOnly={same_permanent}
                        onChange={(e) => setCPo(e.target.value.toUpperCase())}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Present Pin-</p>
                      <input
                        type="text"
                        placeholder="Present Pin"
                        class="form-control"
                        name="cpin"
                        required
                        value={cpin}
                        onChange={(e) => {
                          setCPin(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                        readOnly={same_permanent}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.cpin && errors.cpin}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-2">
              <button  className="btn btn-primary">Save & Next</button>
            </div>

            
                </>
              )
            }
            {
              count == 2 &&(
                <>
                <div className="card mt-4">
              <div className="card-header">
                <p className="m-0"> Academics and Qualifications</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>University Last Attented:</p>
                      <input
                        type="text"
                        placeholder="Last University Name"
                        class="form-control"
                        name="lastUniversity"
                        required
                        value={lastUniversity}
                        onChange={(e) =>
                          setLastUniversity(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Registration No of University Last Attended</p>
                      <input
                        type="text"
                        placeholder="Registration No"
                        class="form-control"
                        name="regNo_lastu"
                        required
                        value={regNo_lastu}
                        onChange={(e) => {
                          setRegNo_lastu(e.target.value.toUpperCase());
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>Secondary or Madhyamik exam</b>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Board</p>
                      <input
                        type="text"
                        placeholder="Board"
                        class="form-control"
                        name="board"
                        required
                        value={mboard}
                        onChange={(e) =>
                          setMBoard(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Year</p>
                      <input
                        type="tel"
                        placeholder="Year"
                        class="form-control"
                        name="myear"
                        maxLength={4}
                        required
                        value={myear}
                        onChange={(e) => {
                          setMYear(e.target.value.toUpperCase());
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.myear && errors.myear}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Full marks of exam</p>
                      <input
                        type="text"
                        placeholder="Full marks of exam "
                        class="form-control"
                        name="mfull_marks"
                        required
                        value={mfull_marks}
                        onChange={(e) => {
                          setMFull_marks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(e.target.value,mmarks, "10TH");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.mfull_marks && errors.mfull_marks}
                      </small>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Marks obtained</p>
                      <input
                        type="text"
                        placeholder="Marks obtained"
                        class="form-control"
                        name="mmarks_obtained"
                        required
                        value={mmarks}
                        disabled={mfull_marks.length < 3 || mfull_marks < 600}
                        onChange={(e) => {
                          setMmarks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(mfull_marks,e.target.value, "10TH");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.mmarks_obtained && errors.mmarks_obtained}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>% of marks</p>
                      <input
                        type="text"
                        placeholder="% of marks"
                        class="form-control"
                        name="percentage_marks"
                        required
                        value={mpercentage_marks}
                        onChange={(e) => setMPercentage_marks(e.target.value)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>Higher Secondary or Equivalent exam</b>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Board</p>
                      <input
                        type="text"
                        placeholder="Board"
                        class="form-control"
                        name="board"
                        required
                        value={hboard}
                        onChange={(e) =>
                          setHBoard(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Year</p>
                      <input
                        type="tel"
                        placeholder="Year"
                        class="form-control"
                        name="hyear"
                        maxLength={4}
                        required
                        value={hyear}
                        onChange={(e) => {
                          setHYear(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(e.target.value,mmarks, "10TH");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.hyear && errors.hyear}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Full marks of exam</p>
                      <input
                        type="text"
                        placeholder="Full marks of exam "
                        class="form-control"
                        name="hfull_marks"
                        required
                        value={hfull_marks}
                        onChange={(e) => {
                          setHFull_marks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(e.target.value,hmarks, "12TH");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.hfull_marks && errors.hfull_marks}
                      </small>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Marks obtained</p>
                      <input
                        type="text"
                        placeholder="Marks obtained"
                        class="form-control"
                        name="hmarks_obtained"
                        required
                        value={hmarks}
                        disabled={hfull_marks.length < 3 || hfull_marks < 400}
                        onChange={(e) => {
                          setHmarks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(hfull_marks,e.target.value, "12TH");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.hmarks_obtained && errors.hmarks_obtained}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>% of marks</p>
                      <input
                        type="text"
                        placeholder="% of marks"
                        class="form-control"
                        name="percentage_marks"
                        required
                        value={hpercentage_marks}
                        onChange={(e) => setHPercentage_marks(e.target.value)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>B.A/B.Sc/B.com/B.E/B.tech</b>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p> Status of Under Graduation</p>
                      <select
                        class="form-control"
                        value={status_ug}
                        onChange={(e) => setStatus_ug(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Status of Under Graduation
                        </option>
                        <option value="COMPLETE">COMPLETE</option>
                        <option value="APPEARING">APPEARING</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Type of Graduation</p>
                      <select
                        class="form-control"
                        value={type_ug}
                        onChange={(e) => setType_ug(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Type of Graduation
                        </option>
                        <option value="HONOURS">HONOURS </option>
                        <option value="GENERAL/PASS">GENERAL/PASS</option>
                        <option value="ENGINEERING AND TECHNOLOGY">
                          ENGINEERING AND TECHNOLOGY
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                {status_ug == "APPEARING" && (
                  <>
                    {" "}
                    <p className="m-0 text-danger fn_14">
                      We need your Complete marksheet by 31st July
                    </p>{" "}
                    <p className="m-0 mb-3 text-danger fn_14">
                      Fill Marks upto current Semester
                    </p>{" "}
                  </>
                )}

                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>University</p>
                      <input
                        type="text"
                        placeholder="University"
                        class="form-control"
                        name="University"
                        required
                        value={university}
                        onChange={(e) =>
                          setUniversity(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Year</p>
                      <input
                        type="tel"
                        placeholder="Year"
                        class="form-control"
                        name="uyear"
                        maxLength={4}
                        required
                        value={uyear}
                        onChange={(e) => {
                          setUYear(e.target.value);
                          handleInputChange(e);
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.uyear && errors.uyear}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Full marks of exam</p>
                      <input
                        type="text"
                        placeholder="Full marks of exam "
                        class="form-control"
                        name="ufull_marks"
                        required
                        value={ufull_marks}
                        onChange={(e) => {
                          setUFull_marks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(e.target.value,umarks, "UG");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.ufull_marks && errors.ufull_marks}
                      </small>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>Marks obtained</p>
                      <input
                        type="text"
                        placeholder="Marks obtained"
                        class="form-control"
                        name="umarks_obtained"
                        required
                        value={umarks}
                        disabled={ufull_marks.length < 3}
                        onChange={(e) => {
                          setUmarks(e.target.value);
                          handleInputChange(e);
                          handelMarkCalculations(ufull_marks,e.target.value, "UG");
                        }}
                      />
                      <small class="fn_12 text-danger">
                        {errors?.umarks_obtained && errors.umarks_obtained}
                      </small>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p>% of marks</p>
                      <input
                        type="text"
                        placeholder="% of marks"
                        class="form-control"
                        name="percentage_marks"
                        required
                        value={upercentage_marks}
                        onChange={(e) => setUPercentage_marks(e.target.value)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>M.A/M.Sc/M.com/M.E/M.tech</b>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                    <div class="form-group">
                      <p> Do you have a Master Degree?</p>
                      <select
                        class="form-control"
                        value={have_master}
                        onChange={(e) => setHave_master(e.target.value)}
                        required
                      >
                        <option value="" selected hidden>
                          Do you have a Master Degree
                        </option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {have_master == "YES" && (
                    <>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p> Status of Post Graduation</p>
                          <select
                            class="form-control"
                            value={status_pg}
                            onChange={(e) => setStatus_pg(e.target.value)}
                            required
                          >
                            <option value="" selected hidden>
                              Status of Post Graduation
                            </option>
                            <option value="COMPLETE">COMPLETE</option>
                            <option value="APPEARING">APPEARING</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Type of Graduation</p>
                          <select
                            class="form-control"
                            value={type_pg}
                            onChange={(e) => setType_pg(e.target.value)}
                            required
                          >
                            <option value="" selected hidden>
                              Type of Graduation
                            </option>
                            <option value="HONOURS">HONOURS </option>
                            <option value="GENERAL/PASS">GENERAL/PASS</option>
                            <option value="ENGINEERING AND TECHNOLOGY">
                              ENGINEERING AND TECHNOLOGY
                            </option>
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                </div>


                {have_master == "YES" && (
                  <>
                                  {status_pg == "APPEARING" && (
                  <>
                    {" "}
                    <p className="m-0 text-danger fn_14">
                      We need your Complete marksheet by 31st July
                    </p>{" "}
                    <p className="m-0 mb-3 text-danger fn_14">
                      Fill Marks upto current Semester
                    </p>{" "}
                  </>
                )}
                    <div className="row">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>University</p>
                          <input
                            type="text"
                            placeholder="University"
                            class="form-control"
                            name="University"
                            required
                            value={puniversity}
                            onChange={(e) =>
                              setPUniversity(e.target.value.toUpperCase())
                            }
                          />
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Year</p>
                          <input
                            type="tel"
                            placeholder="Year"
                            class="form-control"
                            name="pyear"
                            maxLength={4}
                            required
                            value={pyear}
                            onChange={(e) => {
                              setPYear(e.target.value);
                              handleInputChange(e);
                            }}
                          />
                          <small class="fn_12 text-danger">
                            {errors?.pyear && errors.pyear}
                          </small>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Full marks of exam</p>
                          <input
                            type="text"
                            placeholder="Full marks of exam "
                            class="form-control"
                            name="pfull_marks"
                            required
                            value={pfull_marks}
                            onChange={(e) => {
                              setPFull_marks(e.target.value);
                              handleInputChange(e);
                              handelMarkCalculations(e.target.value,pmarks, "PG");
                            }}
                          />
                          <small class="fn_12 text-danger">
                            {errors?.pfull_marks && errors.pfull_marks}
                          </small>
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Marks obtained</p>
                          <input
                            type="text"
                            placeholder="Marks obtained"
                            class="form-control"
                            name="pmarks_obtained"
                            required
                            value={pmarks}
                            disabled={pfull_marks.length < 3}
                            onChange={(e) => {
                              setPmarks(e.target.value);
                              handleInputChange(e);
                              handelMarkCalculations(pfull_marks,e.target.value, "PG");
                            }}
                          />
                          <small class="fn_12 text-danger">
                            {errors?.pmarks_obtained && errors.pmarks_obtained}
                          </small>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>% of marks</p>
                          <input
                            type="text"
                            placeholder="% of marks"
                            class="form-control"
                            name="percentage_marks"
                            required
                            value={ppercentage_marks}
                            onChange={(e) =>
                              setPPercentage_marks(e.target.value)
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="card mt-4">
                  <div className="card-header">
                    <p className="m-0">
                      {" "}
                      Preference for School Subject and College for Bachelor in
                      Education
                    </p>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <div class="form-group">
                          <p>Preferred Subject For B.Ed</p>
                          <p className="sm_label">
                            *Please note that preferred subject can be change as
                            per subject availability and other circumstances
                          </p>

                          <select
                            class="form-control"
                            value={choose_subject}
                            onChange={(e) => {
                              setChoose_subject(e.target.value);
                              handelSubjectCollege(e);
                            }}
                            required
                          >
                            <option value="" selected hidden>
                              Preference for School Subject for B.Ed
                            </option>
                            {subjects?.map((val) => {
                              return <option value={val}>{val}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <div class="form-group">
                          <p>Preferred College For B.Ed</p>
                          <p className="sm_label">
                            *Please note that preferred College can be change as
                            per subject availability and other circumstances
                          </p>
                          <select
                            class="form-control"
                            value={choose_college}
                        
                            onChange={(e) => {
                             
                              setChoose_college(e.target.value)                            
    let ar=choose_collegeList.filter((v)=>v.collageName  == e.target.value);
    setChoose_collegeId(ar[0].collegeId)
    setCollege_Course_Price(ar[0].totalprice)
                            }}
                            required
                          >
                            <option value="" selected hidden>
                              Preference for College for B.Ed
                            </option>
                            {choose_collegeList?.map((v, ind) => {
                              return (
                                <>
                                  <option key={ind} value={v.collageName}>
                                    {v.collageName}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Referred by</p>
                          <p className="sm_label">
                            Write The name of the Person by whom you are coming
                            to be admitted in B.Ed. Otherwise you can write
                            "SELF"
                          </p>
                          <input
                            type="text"
                            placeholder="Reffered By"
                            class="form-control"
                            name="reffered_by"
                           
                            value={reffered_by}
                            onChange={(e) =>
                              setReffered_by(e.target.value.toUpperCase())
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mt-4">
                  <div className="card-header">
                    <p className="m-0"> Upload Documents</p>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload Profile Photo</p>
                          <p className="sm_label">File size max 200KB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            accept=".png, .jpeg , .jpg "
                            required
                            onChange={handelphoto}
                          />
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload Signature</p>
                          <p className="sm_label">File size max 200KB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            accept=".png, .jpeg , .jpg "
                            required
                            onChange={handelsignature}
                          />
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload 10th Admit</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            accept=".png, .jpeg , .jpg ,.pdf "
                            required
                            onChange={handel10thadmint}
                          />
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload 10th Result</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            required
                            accept=".png, .jpeg , .jpg , .pdf"
                            onChange={handel10thresult}
                          />
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload 12th Result</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            required
                            accept=".png, .jpeg , .jpg, .pdf "
                            onChange={handel12thresult}
                          />
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload all Under Graduate Results</p>
                          <p className="sm_label">Upload Pdf only (Max 5MB)</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            required
                            accept=".pdf"
                            onChange={handelugresult}
                          />
                        </div>
                      </div>
                      {have_master == "YES" && (
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                          <div class="form-group">
                            <p>Upload all Post Graduate Results </p>
                            <p className="sm_label">Upload Pdf only (Max 5MB)</p>
                            <input
                              type="file"
                              class="form-control"
                              name="file"
                              accept=".pdf"
                              required
                              onChange={handelpgresult}
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload Aadhar Card</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            required
                            accept=".png, .jpeg , .jpg , .pdf"
                            onChange={handelaadhar}
                          />
                        </div>
                      </div>
                      {(category == "SC" || category == "ST" || category =="OBC") && (
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                          <div class="form-group">
                            <p>Upload Caste Certificate</p>
                            <p className="sm_label">File size max 5MB</p>
                            <input
                              type="file"
                              class="form-control"
                              name="file"
                              accept=".png, .jpeg , .jpg ,.pdf "
                              onChange={handelcast}
                              required
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload Other Certificate</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            accept=".png, .jpeg , .jpg , .pdf "
                            onChange={handelotherdoc}
                          />
                        </div>
                      </div>
                      {
                        specially_abled=="YES" &&  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div class="form-group">
                          <p>Upload Disability Certificate</p>
                          <p className="sm_label">File size max 5MB</p>
                          <input
                            type="file"
                            class="form-control"
                            name="file"
                            accept=".png, .jpeg , .jpg , .pdf "
                            onChange={handelDisability}
                            required
                          />
                        </div>
                      </div>
                      }
                     
                    </div>
                  </div>
                </div>

                <div className="card mt-4">
                  <div className="card-header">
                    <p className="m-0"> Course Fee and Agreement</p>
                  </div>
                  <div className="card-body">
                  <h5>Please Read Our terms and Condition Carefully</h5>
              <ul className="ml-5">
                <li>Edushark reserves the right to change my college and my subject
depending on subject and seat availability and other circumstances
after taking my approval if necessary. </li> <li>
If Edushark successfully approves my application I will be liable to
pay my admission fee within 21 days, Otherwise Edushark may
reject my application.</li>
<li>Edushark is not liable to refund the Application fee if the application
is rejected for reasons other than subject and seat availability.</li>
<li>Edushark reserves the right to share my information with colleges
and universities. Edushark may use my phone number,WhatsApp
number and mail Id to contact me and send me messages.</li>
<li>Edushark does not take any responsibility for their students to pass
the examination of the university or the respective board nor
guarantee any job or placement.</li>
              </ul>

                    <div class="form-check mt-2">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          defaultChecked={checked}
                          onChange={() => setChecked((state) => !state)}
                          required
                        />
                        Our Terms and Conditions
                      </label>
                    </div>
                  </div>
                </div>

{invalidmarks ? (
                  <>
                    <div className="card p-3 mt-3 text-center text-danger">
                      <p className="m-0">Invalid Marks Calculation</p>
                    </div>
                  </>
                ) : (
                  <>
                    {
                      (Object.values(errors).filter(element => element == null).length != Object.values(errors).length) ?(
                        <>
                         <div className="card p-3 mt-3 text-center text-danger">
                      <p className="m-0">Oops, we noticed some mistakes in your information. Please double-check your form before moving on. </p>
                    </div>

                        </>
                      ):(
                        <>
<div className="text-center mt-3">
                 <button
                        type="button"
                        onClick={()=>{
                          setCount(1)
                        }}
                        className="btn btn-dark m-1"
                   
                        
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        
                        className="btn btn-success m-1"
                        data-toggle="modal" data-target="#myModal"
                        
                      >
                        Preview
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary m-1"
                   
                        
                      >
                        Save & Next
                      </button>
                    </div>

                        </>

                      )
                    }
                  </>
                )}
                </>
              )
            }
            {
              count==3 && (
                <>
                <div className="card">
              <div className="card-header ">
                <p className="m-0"> Copy of course fee structure and agreement for Bachelor of Education Student.</p>
              </div>
              <div className="card-body ">
              <div className="p-3">
              <p className="m-0 mb-3">
               I am <span className="text-primary">{applicant_name}</span>, son
               or daughter  of <span className="text-primary">{father_name}</span>, I want
                to take admission in Bachelor of education under Babasaheb
                Ambedkar University (Erstwhile The West Bengal University of
                Teachers' Training, Education Planning and Administration).{" "}
              </p>
              
              <p className="m-0 mb-3 ">
                {" "}
                <span className="ml-1"><input
                          type="checkbox"
                          class="form-check-input pl-2 "
                         
                          required
                        /></span>
                I have chosen
                <span className="text-primary">{choose_college}</span> as my
                college and{" "}
                <span className="text-primary"> {choose_subject}</span> for
                Bachelor of Education(B.Ed) in{" "}
                <span className="text-primary">{session}</span> academic year.
              
             
                I Understand that Total course fee for Bachelor of Education is{" "}
                <b className="text-primary"> Rs- {college_Course_Price} ({ college_Course_Price && toWords.convert(Number(college_Course_Price)) }) </b> . This
                Course Fee Includes only Tuition Fee of a Bachelor of Education
                Student. This Course Fee is Excluding GST and Transaction Charge
                or Payment Gateway fee
                </p>
              <p className="m-0 mb-3">
              <span className="ml-1"><input
                          type="checkbox"
                          class="form-check-input pl-2 "
                         
                          required
                        /></span>
                I also understand that This Course Fee is divided into five
                parts for the Convenience of the students.This 5 parts are
                namely - <span className="text-primary">(1) Admission Fee (2) 1st Semester Fee (3) 2nd Semester
                Fee (4) 3rd Semester Fee (5) 4th Semester Fee.</span> I will pay the
                fee for each Semester before the Semester Examination.{" "}
              </p>
              <p className="">
              <span className="ml-1"><input
                          type="checkbox"
                          class="form-check-input pl-2 "
                         
                          required
                        /></span>
              <span className="text-primary"> I am Also Aware of That I have to pay</span> - one time Registration
                Fee,Four times Examination Fee (excluding supplementary
                examination fee),Fee for Dress, Fee for Identity card. <span className="text-primary"> Others
                Fees that I may have to pay </span> -Excursional Tour Charge (Optional),
                Observation Fee (Optional), hostel fee (optional), Library
                Charge (optional)
              </p>

              <p>
              <span className="ml-1"><input
                          type="checkbox"
                          class="form-check-input pl-2 "
                         
                          required
                        /></span>
                I am <span className="text-primary">{applicant_name}</span> Son
                or Daughter of{" "}
                <span className="text-primary">{father_name}</span> residing at{" "}
                <span className="text-primary">{paddress},{ppo},{pps},{pdistrict},{pstate},{ppin}</span> do hereby
                solemnly affirm and declare as follows:{" "}
              </p>
              <ul className="ml-5">
                <li>
                  I accept the fee structure mentioned above and undertake to
                  pay all fees on time. Team Edushark and Baba Saheb Ambedkar
                  Education University may take necessary action against me if I
                  fail to pay the fee on time for any reason.
                </li>
                <li>
                  {" "}
                  I hereby declare that if I discontinue my education then
                  Edushark shall not be liable in any way to refund me the fees
                  paid by me till the time of discontinuance of my education.
                </li>
                <li>
                  I also declare that all the statements made in the application
                  form are true and correct to the best of my knowledge and
                  belief, if any fraud and false details are found in my
                  statement and my uploaded documents, the concerned authority
                  reserves the right to cancel my studentship along with my
                  application.
                </li>
              </ul>
              <div class="form-check mt-2">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                         
                          required
                        />
                        Our fee structure and agreement 
                      </label>
                    </div>
              </div>
               
              </div>
            </div>
            <div className="text-center mt-3">
            <button
              type="submit"
              className={
                pageLoading
                  ? "dis btn btn-primary"
                  : "btn btn-primary"
              }
         
              disabled={pageLoading}
            >
          Save & Next
            </button>
          </div>
                </>
              )
            }
            {
              count == 4 && (
                <>

<div className="card">
                  <div className="card-header">
                    <p className="m-0">
                      {" "}
                      Payment Details
                    </p>
                  </div>
                  <div className="card-body ">
                    {
                      parse(datalist[0].feesTypeText)
                    }
                  {/* <h5 >Application fee in University or Board ₹ 600.00</h5>
                  <p className="fn_14 ">(This amount will be deposited in University or Board for application form submission)</p>
               <h5>Application fee in Edushark ₹ 600.00</h5> */}
               <div class="form-check mt-2">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          defaultChecked={checkedagreement}
                          onChange={() => setCheckedagreement((state) => !state)}
                          required
                        />
                        I understand this Application Fee Structure
                      </label>
                    </div>
              </div>
                </div>
                <div className="text-center mt-3">
                <button
                    type="submit"
                    className={
                      pageLoading ? "dis btn btn-primary" : "btn btn-primary"
                    }
                    disabled={pageLoading}
                  >
                    Proceed & Pay
                  </button>
                 
                </div>
                 
                </>
              )
            }
          </form>
        </div>
      </div>
      
      {pageLoading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}


      
{
  preview && <div className="model_box">
  <div className="inner_model">
    <div className="cross" onClick={()=>setPreview(false)}>
      &times;
    </div>
    <h5>Before <span className="text-primary">Save & Next</span> , Just check your details once . You Can't make any changes after clicking Next</h5>
  </div>
</div>
}
    </>
  );
};

export default BedEnroll;
